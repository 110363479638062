import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBJumbotron,  MDBAlert,  MDBTable, MDBTableBody, MDBTableHead,  MDBBtn, MDBIcon, MDBBadge, MDBCard} from "mdbreact";
import Axios from 'axios'
import { Layout, Menu, Breadcrumb, Icon, Select, PageHeader, Card, Avatar,  Row, Col, Popover, Button , Divider, Tooltip    } from 'antd';
import { StarOutlined, StarFilled, StarTwoTone, CaretDownOutlined, UserOutlined } from '@ant-design/icons';
import Modalteachingschedule from './Modalteachingschedule';
import Modalcolor from './Modalcolor';
import { QuestionCircleTwoTone } from '@ant-design/icons';
//import { useNetworkStatus } from 'react-adaptive-hooks/network';

//import Datetime from 'react-datetime';
const { Meta } = Card;
//const { effectiveConnectionType } = useNetworkStatus();

export default class Timetable extends Component {
    
    constructor(props) {
        super(props);
        this.state = {

          thschedule:[],
          modal: false,
          term:'',
          type:'1',
          idemp:'',
          empcouncilcheck:[],
          empcouncilcheck2:[],
          teachingschedule:[],
          dd3:'',
          teacher:'',

        };
        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSearch = this.onSearch.bind(this);

        this.getthschedule = this.getthschedule.bind(this);
        this.updateSearchDepartment = this.updateSearchDepartment.bind(this);
        this.getteachingschedule = this.getteachingschedule.bind(this);
        this.time = this.time.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
       

      }


      componentDidMount() {

        // const token = JSON.parse(localStorage.getItem('nameuser'));
        if (localStorage.getItem('name') == null) {
            window.location.href = '/tess'
        } else {
    
        }
      }


    updateSearchDepartment(value) {
        // console.log(event.target.value)
        // const term = event.target.value;
        const term = value;
        this.setState({ term });
 
           setTimeout(() => {
             this.getthschedule()
           }, 500);
      }


      getthschedule() {
        setTimeout(() => {
           //ภายใน
            Axios.get('https://git.kpru.ac.th/FrontEnd_Tabian/load/getthschedule/'+this.state.term)
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ thschedule: res.data})
           }) 
         }, 0);
      }

      getteachingschedule() {
        setTimeout(() => {
           //ภายใน
            Axios.get('https://git.kpru.ac.th/FrontEnd_Tabian/load/teachingschedule/'+this.state.term+'/'+this.state.idemp)
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ teachingschedule: res.data})

             const teacher = res.data[0].poname;
             this.setState({ teacher });
           }) 
         }, 0);
      }


      onChange(value) {
          console.log(`selected ${value}`);
          const idemp = value;
          this.setState({ idemp });

          setTimeout(() => {
             Axios.get('https://git.kpru.ac.th/FrontEnd_Tabian/load/getthcouncilcheck/1/'+this.state.term+'/'+this.state.idemp)
            .then(res => {
                    this.setState({ empcouncilcheck: res.data})
            }) 
          }, 0);

          setTimeout(() => {
             Axios.get('https://git.kpru.ac.th/FrontEnd_Tabian/load/getthcouncilcheck/2/'+this.state.term+'/'+this.state.idemp)
            .then(res => {
                    this.setState({ empcouncilcheck2: res.data})
            }) 
          }, 0);

          setTimeout(() => {
            this.getteachingschedule()
          }, 500);

     }


         onBlur() {
            console.log('blur');
          }
          
           onFocus() {
            console.log('focus');
          }
          
           onSearch(val) {
            console.log('search:', val);
          }

          time() {
            const d = new Date();
            d.setMinutes(17);
            document.getElementById("demo").innerHTML = d;
          }

          refreshPage() {
            window.location.reload(false);
          }


          

    render() {

        const { Option } = Select;
        const dd= new Date();
        const content = (
          <div>
            <p>รายชื่อค้นหามีเฉพาะที่สอนเทอมที่เลือก</p>
          </div>
        );
      //  const teacher =  this.state.teachingschedule[0].map(teachingschedules => { {teachingschedule.poname} }  )   
     // const online = window.navigator.onLine;
      //  const teacher =  this.state.teachingschedule[0].map(teachingschedules => { {teachingschedule.poname} }  )     
      if (navigator.onLine) {
        console.log('online');
      } else {
        console.log('offline');
      }  
      // const { effectiveConnectionType } = useNetworkStatus();
      
      // let media;
      // switch(effectiveConnectionType) {
      //   case 'slow-2g':
      //     media = <img src='...' alt='low resolution' />;
      //     break;
      //   case '2g':
      //     media = <img src='...' alt='medium resolution' />;
      //     break;
      //   case '3g':
      //     media = <img src='...' alt='high resolution' />;
      //     break;
      //   case '4g':
      //     media = <video muted controls>...</video>;
      //     break;
      //   default:
      //     media = <video muted controls>...</video>;
      //     break;
      // }
     
      
        return (
            <MDBContainer fluid>
                {/* <MDBAlert color="primary" >
                <h4><MDBIcon icon="address-book" className="indigo-text pr-3" /> จัดการตารางเรียนตารางสอน</h4>
                </MDBAlert>
                <p>{localStorage.getItem('name')}</p> */}

{/* <PageHeader
    className="site-page-header"
    onBack={() => null}
    title="จัดการตารางสอน"
    subTitle={"โดย "+localStorage.getItem('name')  }
  /> */}
   <Card type="inner"  title={<h4 className="teal-text"><MDBIcon icon="atom" /> จัดการตารางเรียน</h4>} >
   <Row>
      <Col flex="1 1 200px"></Col>
      <Col flex="0 1 200px">
        <Meta
      avatar={<Avatar  src="https://i.gifer.com/g2uA.gif" />}
      title={localStorage.getItem('name')}
      description="ผู้ดูแลระบบ"
      
    />
      </Col>
    </Row>
   
   {/* <p>โดย {localStorage.getItem('name')} [ผู้ดูแลระบบ]</p> */}
  
               
            <br/>
            <MDBRow>

            <MDBCol sm="4">
                <div>
                    <CaretDownOutlined style={{ fontSize: '30px', color: '#0d47a1' }}  />   
                    
                        <Select
                            showSearch
                            style={{ width: 300 }}
                            placeholder="เลือกเทอม"
                            optionFilterProp="children"
                            onChange={this.updateSearchDepartment}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onSearch={this.onSearch}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                         >
                            <Option value="3/2563">3/2563</Option>
                            <Option value="1/2564">1/2564</Option>
                            <Option value="2/2564">2/2564</Option>
                            <Option value="3/2564">3/2564</Option>
                            <Option value="1/2565">1/2565</Option>
                            <Option value="2/2565">2/2565</Option>
                        </Select>
                    </div>
                    {/* <br/> */}
            </MDBCol>

            <MDBCol sm="4">
        { this.state.term === "" ?<> </>:<> 
                    <> 
                    <UserOutlined style={{ fontSize: '30px', color: '#0d47a1' }}  />   

                        <Select
                            showSearch
                            style={{ width: 300 }}
                            placeholder="เลือกอาจารย์"
                            optionFilterProp="children"
                            onChange={this.onChange}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onSearch={this.onSearch}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            {/* <Option value="jack">Jack</Option>
                            <Option value="lucy">Lucy</Option>
                            <Option value="tom">Tom</Option> */}
                        {this.state.thschedule.map((emp,index )  => 
                        <option value={emp.teacher_id1}>{emp.pname,emp.name,emp.poname}</option>
                        )}
                        </Select>

                      
                        <br/>
                    </>
                    </> }

            </MDBCol>
            <MDBCol className="text-right" sm="4">  
                          
                <Popover content={content} title="หมายเหตุ" trigger="hover">
                               {/* <QuestionCircleTwoTone /> */} <MDBIcon far icon="comment-dots" size="lg" />
                </Popover>
                <Divider type="vertical" />
                        <Tooltip title="refresh">
                           <MDBIcon icon="redo-alt" size="lg" onClick={this.refreshPage} />
                        </Tooltip>
            </MDBCol>
       </MDBRow>


                { this.state.empcouncilcheck.length === 0 ? <> </> : 
                <>
                <br/>
                <MDBCard>
                <MDBAlert color="danger">
                    <a href="#!" className="alert-link"> ! อาจารย์มีรายชื่อในการประชุมกรรมการบริหารมหาวิทยาลัย</a> ทุกวันอังคาร ของทุกเดือน
                </MDBAlert>
                </MDBCard>
                </>}


                { this.state.empcouncilcheck2.length === 0 ? <> </> : 
                <>
                <br/>
                <MDBCard>
                <MDBAlert color="warning ">
                    <a href="#!" className="alert-link"> ! อาจารย์มีรายชื่อในการประชุมกรรมการบริหารมหาวิทยาลัย</a> ทุกวันพฤหัสบดี ของทุกเดือน
                </MDBAlert>
                </MDBCard>
                </>}

                {/* <p>{this.state.idemp}</p> */}
                {/* <p>{this.state.empcouncilcheck}</p> */}


          { this.state.term === '' ? <></> :<> 
          <br/>
          {/* <hr/> */}
          <Divider><h5 className="text-center">ตารางสอนภาคปกติกำแพงเพชร</h5> </Divider> 
           <h5 className="text-center">รายวิชา เทอม {this.state.term}</h5> 
           {this.state.teacher === ''?<></>:<>  <h5 className="text-center">อาจารย์{this.state.teacher} </h5> </> }
          {this.state.empcouncil === '' ? <>
          <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            </>: <>
            <MDBTable bordered small responsive>
                <MDBTableHead>
                <tr>
                    <th rowspan="2" className="text-center">ลำดับ</th>
                    <th rowspan="2" className="text-center">หมู่เรียน</th>
                    <th rowspan="2" className="text-center">โปรแกรม</th>
                    <th rowspan="2" className="text-center">รหัสวิชา</th>
                    <th rowspan="2" className="text-center">ชื่อวิชา</th>
                    <th rowspan="2" className="text-center">หน่วยกิต</th>
                    <th rowspan="2" className="text-center">จำนวน</th>
                    <th rowspan="2" className="text-center">กลุ่ม</th>
                    {/* <th rowspan="2" className="text-center">ผู้สอน</th> */}
                    <th  colspan="3" className="text-center">ช่วงที่ 1 </th>
                    <th  colspan="3" className="text-center">ช่วงที่ 2 </th>
                    <th  colspan="3" className="text-center">ช่วงที่ 3 </th>
                    {/* <th>วัน</th>
                    <th>เวลา</th>
                    <th>ห้องเรียน</th> */}
                    <th rowspan="2"  className="text-center">จัดการ</th>
                  </tr>
                  <tr>
                   
                    {/* <th  colspan="3">วัน</th> */}
                    <th className="text-center">วัน</th>
                    <th className="text-center">เวลา</th>
                    <th className="text-center">ห้องเรียน</th>

                    <th className="text-center">วัน</th>
                    <th className="text-center">เวลา</th>
                    <th className="text-center">ห้องเรียน</th>

                    <th className="text-center">วัน</th>
                    <th className="text-center">เวลา</th>
                    <th className="text-center">ห้องเรียน</th>
                    
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                {this.state.teachingschedule.map((teachingschedule,index) => {
                
                   //กศบป
                    const  time2 = Number (teachingschedule.hour1*55)
                    const  ddf = time2-5
                    const  now = new Date("December 25, 1995 "+teachingschedule.times1+" ");
                    const totalsss = now.setMinutes(now.getMinutes()+ddf);
                    const wanwela = new Date(totalsss)
                    const wanwela1 =  wanwela.toLocaleTimeString()

                    //ปกติ
                    const  timenormal = Number (teachingschedule.hour1*60)
                    const  now2 = new Date("December 25, 1995 "+teachingschedule.times1+" ");
                    const totalsss2 = now2.setMinutes(now2.getMinutes()+timenormal);
                    const wanwela2 = new Date(totalsss2)
                    const normal =  wanwela2.toLocaleTimeString()
                   
      
                     return   <tr>
                          <td>{index+1}</td>
                          <td>{teachingschedule.id_major}</td>
                          <td>{teachingschedule.t_mjname}</td>
                          <td>{teachingschedule.SUBJCODE}</td>
                          <td>{teachingschedule.SUBJNAME}</td>
                          <td>{teachingschedule.CRHR}</td>
                          <td>{teachingschedule.numregister}</td>
                          <td> <p><Modalcolor idcolor={teachingschedule.group_id} /></p></td>
                          {/* <td>อาจารย์{teachingschedule.poname}</td> */}

                    {/* ช่วงที่1 */}
                          <td> {teachingschedule.weekly1 === '1' ?<> จันทร์</>:<></> }
                               {teachingschedule.weekly1 === '2' ?<> อังคาร</>:<></> }
                               {teachingschedule.weekly1 === '3' ?<> พุธ</>:<></> }
                               {teachingschedule.weekly1 === '4' ?<> พฤหัสบดี</>:<></> }
                               {teachingschedule.weekly1 === '5' ?<> ศุกร์</>:<></> }
                               {teachingschedule.weekly1 === '6' ?<> เสาร์</>:<></> }
                               {teachingschedule.weekly1 === '7' ?<> อาทิตย์</>:<></> }
                          </td>
                          <td>
                              {teachingschedule.times1 === null || teachingschedule.times1 === ''  ? <></> :<> {teachingschedule.times1} - {teachingschedule.timesend1}
                              {/* { teachingschedule.id_major.substring(2, 3) === '1' ?<>{normal.substring(0, 5)} </> :<>{wanwela1.substring(0, 5)}  </>} */}
                                </> }
                          </td>
                          <td >{teachingschedule.classroom1}</td>

                    {/* ช่วงที่2 */}
                          <td > {teachingschedule.weekly2 === '1' ?<> จันทร์</>:<></> }
                               {teachingschedule.weekly2 === '2' ?<> อังคาร</>:<></> }
                               {teachingschedule.weekly2 === '3' ?<> พุธ</>:<></> }
                               {teachingschedule.weekly2 === '4' ?<> พฤหัสบดี</>:<></> }
                               {teachingschedule.weekly2 === '5' ?<> ศุกร์</>:<></> }
                               {teachingschedule.weekly2 === '6' ?<> เสาร์</>:<></> }
                               {teachingschedule.weekly2 === '7' ?<> อาทิตย์</>:<></> }
                          </td>
                          <td>
                              {teachingschedule.times2 === null || teachingschedule.times2 === '' ? <></> :<> {teachingschedule.times2} -
                              { teachingschedule.id_major.substring(2, 3) === '1' ?<>{normal.substring(0, 5)} </> :<>{wanwela1.substring(0, 5)}  </>}
                                </> }
                          </td>
                          <td>{teachingschedule.classroom2}</td>

                    {/* ช่วงที่3 */}
                          <td > {teachingschedule.weekly3 === '1' ?<> จันทร์</>:<></> }
                               {teachingschedule.weekly3 === '2' ?<> อังคาร</>:<></> }
                               {teachingschedule.weekly3 === '3' ?<> พุธ</>:<></> }
                               {teachingschedule.weekly3 === '4' ?<> พฤหัสบดี</>:<></> }
                               {teachingschedule.weekly3 === '5' ?<> ศุกร์</>:<></> }
                               {teachingschedule.weekly3 === '6' ?<> เสาร์</>:<></> }
                               {teachingschedule.weekly3 === '7' ?<> อาทิตย์</>:<></> }
                          </td>
                          <td>
                              {teachingschedule.times3 === null || teachingschedule.times3 === '' ? <></> :<> {teachingschedule.times3} -
                              { teachingschedule.id_major.substring(2, 3) === '1' ?<>{normal.substring(0, 5)} </> :<>{wanwela1.substring(0, 5)}  </>}
                               </> }
                          </td>
                          <td>{teachingschedule.classroom3}</td>
                          <td><Modalteachingschedule  SUBJCODE={teachingschedule.SUBJCODE} SUBJNAME={teachingschedule.SUBJNAME} 
                               CRHR={teachingschedule.CRHR}  id_major={teachingschedule.id_major}  major={teachingschedule.major}  poname={teachingschedule.poname} 
                               teacher_id={teachingschedule.teacher_id1}  term={this.state.term} getteachingschedule={this.getteachingschedule} group_id={teachingschedule.group_id}
                          /></td>
                          </tr>
                  
                })}   
                </MDBTableBody>
            </MDBTable>
            </> }
            {/* <p>หมายเหตุ : ข้อมูลใช้ประกอบการจัดตารางสอน ไม่ให้ชนกับการประชุม</p> */}
            
        </>}
      
        </Card>
            </MDBContainer>
            
        )
    }
}
