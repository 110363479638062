import React, { Component } from 'react'
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBFormInline, Link, MDBBtn , MDBHamburgerToggler
} from "mdbreact";
import {  MDBContainer, MDBMask, MDBView } from 'mdbreact';
import { Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip } from 'antd';
import { URL } from '../api';
//import logo from '../Pages/img/as.png'
import logo2 from '../Pages/img/as2.png'


export default class Header extends Component {


  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      nameuser: '',
      admin_type: '',
      Username: '',
      collapse: false,
      isWideEnough: false,
    };
    this.onClick = this.onClick.bind(this);
      this.linkto = this.linkto.bind(this);
      this.linkto2 = this.linkto2.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }
  
    

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }



    componentDidMount() {
      //  const token = JSON.parse(localStorage.getItem('nameuser'));
        if (localStorage.getItem('name') !== "") {
          this.setState({ nameuser: localStorage.getItem('name') })
          this.setState({ admin_type: localStorage.getItem('admin_type') })
        } else {
    
            this.setState({ admin_type: 'public' })

        }

         // this.setState({ nameuser: localStorage.getItem('nameuser') })
         // this.setState({ admin_type: localStorage.getItem('admin_type') })
      }


      logout() {
      //   localStorage.removeItem(('admin_type'))
       //  localStorage.removeItem(('nameuser'))
        localStorage.clear();
    
        setTimeout(() => {
          window.location.href = '/'
        //  window.location.href = 'https://e-student.kpru.ac.th/vaccinekpru'
        }, 1000);
       // this.setState({ admin_type: 'public' }
        message.success("ออกจากระบบแล้ว")
      }

      linkto() {
     // console.log('sdsdsdsds')
        window.location.href = 'https://thenextth.com/Addregister'
        
        }
        linkto2() {
          // console.log('sdsdsdsds')
             window.location.href = 'https://thenextth.com/Addinsurance'
             
             }



    render() {

    // console.log(this.state.admin_type) ;

        let logout
        logout = (
          <>
            <MDBNavItem>
              <MDBNavLink to="/main"> {this.state.nameuser}</MDBNavLink>
    
            </MDBNavItem>
           
            <MDBNavItem onClick={this.logout} >
              <MDBNavLink to="/">  ออกจากระบบ </MDBNavLink>
            </MDBNavItem>
          </>
        )
    
        let superadmin
        superadmin = (
          <>
            {/* <MDBNavItem >
                 <MDBNavLink to="/Check"><MDBIcon icon="file-alt" /> หน้าหลัก</MDBNavLink>
            </MDBNavItem> */}
            <MDBNavItem className="white-text hover-underline-animation">
                 <MDBNavLink to="/Addbanner" className="white-text font-weight-bold"><MDBIcon far icon="user-circle" /> จัดการแบนเนอร์</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="white-text hover-underline-animation">
                 <MDBNavLink to="/Addguaranteetype" className="white-text font-weight-bold"><MDBIcon icon="user-circle" /> จัดการแบบประกัน</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="white-text hover-underline-animation">
                 <MDBNavLink to="/selectregister" className="white-text font-weight-bold"><MDBIcon icon="home" /> รายชื่อผู้ลงทะเบียน FA</MDBNavLink>
            </MDBNavItem>
            
            <MDBNavItem className="white-text hover-underline-animation">
                 <MDBNavLink to="/registerproportion" className="white-text font-weight-bold"><MDBIcon icon="chart-pie" /> สัดส่วนผู้สมัคร</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="white-text hover-underline-animation">
                 <MDBNavLink to="/selectposition" className="white-text font-weight-bold"><MDBIcon icon="tools" /> จัดการตำแหน่ง</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="white-text hover-underline-animation">
                 <MDBNavLink to="/selectinsurance" className="white-text font-weight-bold"><MDBIcon icon="diagnoses" /> รายชื่อผู้สนใจประกัน</MDBNavLink>
            </MDBNavItem>
           


             <MDBNavItem onClick={this.logout} className="white-text hover-underline-animation">
              <MDBNavLink to="/" className="white-text font-weight-bold"><MDBIcon icon="sign-out-alt" />  ออกจากระบบ </MDBNavLink>
            </MDBNavItem>
          </>
        )
        

        // let adminfaculty
        // adminfaculty = (
        //   <>
        //     <MDBNavItem >
        //          <MDBNavLink to="/adminfaculty"><MDBIcon icon="home" /> เมนู1</MDBNavLink>
        //     </MDBNavItem>
        //     <MDBNavItem >
        //          <MDBNavLink to="/adminfacultyproportion"><MDBIcon icon="home" /> เมนู2</MDBNavLink>
        //     </MDBNavItem>
        //      <MDBNavItem onClick={this.logout} >
        //       <MDBNavLink to="/">  ออกจากระบบ </MDBNavLink>
        //     </MDBNavItem>
        //   </>
        // )


        // let expert
        // expert = (
        //   <>
        //     <MDBNavItem >
        //          <MDBNavLink to="/expert"><MDBIcon icon="home" /> หน้าหลัก</MDBNavLink>
        //     </MDBNavItem>
        //     {/* <MDBNavItem >
        //          <MDBNavLink to="/expert"><MDBIcon icon="home" /> สัดส่วนบทความvvvvvv</MDBNavLink>
        //     </MDBNavItem> */}
        //      <MDBNavItem onClick={this.logout} >
        //       <MDBNavLink to="/">  ออกจากระบบ </MDBNavLink>
        //     </MDBNavItem>
        //   </>
        // )
    


        let publics
        publics = (
          <>
            <MDBNavItem  className="white-text hover-underline-animation">
              <MDBNavLink to="/" className="white-text font-weight-bold text-center">   หน้าหลัก </MDBNavLink>
            </MDBNavItem>
            {/* <MDBNavItem className="white-text hover-underline-animation" >
              <MDBNavLink to="/" className="white-text font-weight-bold">   อาชีพFA </MDBNavLink>
            </MDBNavItem> */}
            <MDBNavItem className="white-text hover-underline-animation" >
              <MDBNavLink to="/Ceck" className="white-text font-weight-bold text-center">   เช็คแบบประกัน </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="white-text hover-underline-animation" >
              <MDBNavLink to="/Addinsurance2" className="white-text font-weight-bold text-center">   สนใจประกัน </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="white-text hover-underline-animation" >
              <MDBNavLink to="/Addregister" className="white-text font-weight-bold text-center">   ร่วมงานกับเรา </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem  className="white-text hover-underline-animation">
              <MDBNavLink to="/Contect" className="white-text font-weight-bold text-center">   ติดต่อเรา </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="white-text hover-underline-animation" >
              <MDBNavLink to="/login" className="white-text font-weight-bold text-center">  LOGIN </MDBNavLink>
            </MDBNavItem>

             {/* <MDBNavItem onClick={this.logout} >
              <MDBNavLink to="/" className="white-text font-weight-bold">  ออกจากระบบ </MDBNavLink>
            </MDBNavItem> */}
          
          </>
        )

        const name = this.state.nameuser;

        return (
            <>

                <MDBNavbar   dark expand="md" className="heaol shadow-box-example z-depth-2 white-text" >
                    <MDBNavbarBrand>
                    
                        <strong className="white-text"><img
                  src={logo2}
                  width={135} height={45}
                  alt="Third slide"
                /> </strong>
              
                    </MDBNavbarBrand>

                    <MDBNavbarToggler color="#d3531a" onClick={this.toggleCollapse} className="white-text" />
                    <MDBCollapse color="#d3531a" id="navbarCollapse2" onCollapse={(e)=>{console.log(e)}} isOpen={this.state.isOpen} className="white-text" navbar >
                    {/* <MDBHamburgerToggler color="#d3531a" id="hamburger1" onClick={()=> this.toggleSingleCollapse('collapse1')} /> */}
                        <MDBNavbarNav left >

                            {/* <MDBNavItem >
                                <MDBNavLink to="/"><MDBIcon icon="home" /> Home</MDBNavLink>
                            </MDBNavItem> */}
                      {/* { localStorage.getItem('name') == null ? <>
                      {publics}
                     {window.location.href = '/tess'} 
                      </>:<>
                       {this.state.admin_type === '2' ? <>
                                      {superadmin}
                                      </> : null}
                      </>} */}
                                                
                                      {this.state.admin_type === '2' ? <>
                                      {superadmin}
                                      </> : null}

                                      {localStorage.getItem('name_member') == null ? <>
                                      {publics}
                                      </> : null}

                                      {/* {this.state.admin_type === '4' ? <>
                                      {adminfaculty}
                                      </> : null}   */}
                              
                             
                        </MDBNavbarNav>
                     
                        <MDBNavbarNav right>
                            <MDBNavItem onClick={this.linkto}>
                               
                                    <div className="md-form my-0 text-center">
                                        {/* <input className="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search" /> */}
                                        {/* <MDBNavLink to=""><MDBIcon icon="home" /> สมัครอาชีพ FA </MDBNavLink> */}
                                        <a href="tel:0815955492">  <MDBBtn  className="button4  font-weight-bold mmu" color="white"  ><MDBIcon icon="phone-square" size="lg" /> </MDBBtn>  </a>
                                       
                                    </div>
                                    
                             
                            </MDBNavItem>
                            <MDBNavItem onClick={this.linkto2}>
                           
                                    <div className="md-form my-0 text-center">
                                        {/* <input className="form-control mr-sm-2" type="text" placeholder="Search" aria-label="Search" /> */}
                                        {/* <MDBNavLink to=""><MDBIcon icon="home" /> {this.name} </MDBNavLink> */}
                                        {/* <MDBBtn className='button4'  size="sm">Unique</MDBBtn> */}
                                        <a href="https://line.me/ti/p/-vQFhWJFH0" > <MDBBtn className="button4  font-weight-bold mmu" color="white" ><MDBIcon fab icon="line" size="lg" /> </MDBBtn>  </a>
                                    </div>
                                    
                              
                            </MDBNavItem>

                            
                            {/* {this.state.isOpen === true ?<><MDBIcon icon="bars" /></>:<></>} */}
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBNavbar>



                

            </>
        )
    }
}

