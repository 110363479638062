import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem } from 'mdbreact';

export default class Mainme extends Component {
    constructor(props) {
        super(props);
        this.state = {
          emp:[],
        };
      //  this.handleChange = this.handleChange.bind(this);

      }

      componentDidMount() {

       
      }


  render() {
    return (
     
      <MDBContainer>
        <br/>
        <h2 className='text-center'>ระบบจัดการสำหรับผู้ดูแลระบบ</h2>
        <img src="https://img.freepik.com/premium-vector/business-woman-working-computer-desk-office_116089-4.jpg?w=2000" className="rounded mx-auto d-block w-10 " width="50%"   alt="aligment" /><br/>
</MDBContainer>
    )
  }
}
