import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem } from 'mdbreact';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBMask, MDBView, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader } from 'mdbreact';
import { Form, Input, Button, Checkbox, message, Select } from 'antd';

import Axios from 'axios'



import Swal from 'sweetalert2'
import { api } from '../../api';



export default class Registerauthorities extends Component {
    

        constructor(props) {
            super(props);
            this.state = {
                auth_name:'',
            auth_lname:'',
            auth_agency:'',
            auth_tel:'',
            auth_status:'',

                code:'',
                bd:'',
            };
           
            this.handleSubmit = this.handleSubmit.bind(this);
            this.toggle = this.toggle.bind(this);
            this.onClick = this.onClick.bind(this);
            this.onTextChange = this.onTextChange.bind(this);
            this.getstudent = this.getstudent.bind(this);
            this.onChangetype = this.onChangetype.bind(this);
          }

          onClick() {
            this.setState({
              collapse: !this.state.collapse,
            });
          }

          toggle = () => {
            this.setState({
              modal: !this.state.modal
            });
          }

          onTextChange = (e) => {
            this.setState({ [e.target.name]: e.target.value })
         }

         onChangetype(value) {
            this.setState({ auth_status:value})
          }

        
         getstudent() {
            setTimeout(() => {
               //ภายใน
                Axios.GET(`https://arit.kpru.ac.th/ap/api/?nu=tabianStudentCheck&code=${this.state.code}&password=${this.state.password}&Token=S2FtcGhhZW5nIFBoZXQgUmFqYWJoYXQgVW5pdmVyc2l0eQ==`,
                    // Axios.get(https://mis.kpru.ac.th/api/EmployeeInOrg/000,
                     {headers: {
                         
                        // Authorization: 'Bearer ' + localStorage.getItem('myData')
                     }
                     })
               .then(res => {
                 console.log(res);
               //  this.setState({ bankname: res.data, loading: false })
               //  this.setState({ student: res.data})
               }) 
             }, 0);
          }
        

          handleSubmit() {
            // alert('A name was submitted: ' + this.state.value);
           setTimeout(() => {
        
              const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
              datafrm.append("auth_name", this.state.auth_name);
              datafrm.append("auth_lname", this.state.auth_lname);
              datafrm.append("auth_agency", this.state.auth_agency);
              datafrm.append("auth_tel", this.state.auth_tel);
              datafrm.append("auth_status", this.state.auth_status);
        
              
              api.post("insertauthorities.php",datafrm,{
                                    headers: {
                                        'content-type': 'multipart/form-data'
                                    }
                                })
        
                                .then(res => {
                                    console.warn(res);
                                    console.log("response: ", res)
                                    if (res.status=== 200) {
                                    // this.showitem = true;
                                   // console.log(res)
                                   // console.log(res.data.length) 
                                  // message.success("ลงทะเบียนสำเร็จ")
                                  
                                  Swal.fire({
                                    title: 'บันทึกข้อมูลสำเร็จ',
                                    icon: 'success',
                                  // showCancelButton: true,
                                    confirmButtonText: 'ตกลง',
                                  // cancelButtonText: 'No, keep it'
                                  }).then((result) => {
                                    this.toggle();
              
                                  
                                  })
        
                                    }else{
                                    // window.location.href = '/'
                                    }
        
                                    // if (res.data.status = 'success999') {
                                    // message.success("อัพโหลไฟล์สำเร็จ")
                                    // // window.location.href = '/adminfaculty'
                                    // }else{
                                    // message.error("อัพโหลไฟล์ไม่สำเร็จ!")
                                    // }
                                })
        
                    }, 2000);
        
        //window.location.href = '/adminfaculty'
        //this.props.updateat();
        //this.getExpert()
        
          }
      
       




    render() {
      console.log(this.state.code)

        return (
       
                <MDBContainer >
           
           <MDBCarousel
          activeItem={1}
          length={3}
          showControls={true}
          showIndicators={true}
          className="z-depth-1"
        >
          <MDBCarouselInner>
            <MDBCarouselItem itemId="1">
              <MDBView>
                <img
                  className="d-block w-100"
                  src="https://mdbootstrap.com/img/Others/documentation/img%20(136)-mini.webp"
                  alt="First slide"
                />
                <MDBMask overlay="black-light" />
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="2">
              <MDBView>
                <img
                  className="d-block w-100"
                  src="https://mdbootstrap.com/img/Others/documentation/img%20(137)-mini.webp"
                  alt="Second slide"
                />
                <MDBMask overlay="black-strong" />
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="3">
              <MDBView>
                <img
                  className="d-block w-100"
                  src="https://mdbootstrap.com/img/Others/documentation/img%20(141)-mini.webp"
                  alt="Third slide"
                />
                <MDBMask overlay="black-slight" />
              </MDBView>
            </MDBCarouselItem>
          </MDBCarouselInner>
        </MDBCarousel>

                 

<br/>
<h2 className="text-center"> <MDBIcon icon="running" /> ลงทะเบียนเจ้าหน้าที่</h2>
      <MDBRow >
        
        <MDBCol md="12" className="d-flex justify-content-center">
        <MDBCard style={{ width: "32rem", marginTop: "1rem" }}>
    <MDBCardBody>
      <MDBCardTitle className="text-center">สำหรับเจ้าหน้าที่/บุคคลทั่วไป</MDBCardTitle>
        <div className="text-center">
        <MDBIcon icon="user-tie" size="4x" className="text-center"/>
        </div> 
       <br/>
       <form onSubmit={this.handleSubmit}>
                            <div className="mb-2">
                                <label>ชื่อ</label>
                                <input className="form-control" onChange={this.onTextChange} name="auth_name"
                                    value={this.state.auth_name} placeholder="กรุณาระบุชื่อ" required />
                            </div>

                            <div className="mb-2">
                                <label>นามสกุล</label>
                                <input className="form-control" onChange={this.onTextChange} name="auth_lname"
                                    value={this.state.auth_lname} placeholder="กรุณาระบุนามสกุล" required />
                            </div>

                            <div className="mb-2">
                                <label>หน่วยงาน</label>
                                <input className="form-control" onChange={this.onTextChange} name="auth_agency"
                                    value={this.state.auth_agency} placeholder="กรุณาระบุหน่วยงาน" required />
                            </div>

                            <div className="mb-2">
                                <label>เบอร์โทร</label>
                                <input className="form-control" onChange={this.onTextChange} name="auth_tel"
                                    value={this.state.auth_tel} placeholder="กรุณาระบุเบอร์โทร" required />
                            </div>

                            <div className="mb-2">
                                <label>ประเภทบุคลากร</label><br/>
                                <Select
                                    showSearch
                                    style={{ width:"100%" }}
                                    placeholder="เลือกประเภทบุคลากร"
                                    optionFilterProp="children"
                                    onChange={this.onChangetype}
                                    onFocus={this.onFocus}
                                    onBlur={this.onBlur}
                                    onSearch={this.onSearch}
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                <option value="1">ภายใน</option>
                                <option value="2">ภายนอก</option>
                              
                                 </Select>
                            </div><br/>

                            <p className="purple-text text-center">ตรวจสอบข้อมูลว่าถูกต้องครบถ้วน</p>
                            {/* <div className="mt-3 text-center" >
                                <MDBBtn type="submit" color="primary" className="text-center"> <MDBIcon far icon="save" /> บันทึก</MDBBtn>
                            </div> */}


                        </form>
      
      <div className="text-center">
        <MDBBtn color="deep-orange" onClick={this.handleSubmit} >ลงทะเบียน</MDBBtn>
        <MDBBtn color="deep-orange" onClick={this.getstudent} >ลงทะเบียน2</MDBBtn>
        
      </div>
      
    </MDBCardBody>
  </MDBCard>
        </MDBCol>


       
      </MDBRow>

<br/>
      <MDBRow>
        <MDBCol md="4"></MDBCol>
        <MDBCol md="4" className="d-flex justify-content-center">
        <div className="text-center">
          {/* <Modallogin /> */}
        </div>
          </MDBCol>
        <MDBCol md="4"></MDBCol>
     </MDBRow>

                   
                    </MDBContainer>
                   
     
        )
    }
}
