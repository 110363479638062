import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn, MDBIcon, MDBCard, MDBCardBody, MDBJumbotron, MDBRow, MDBCol } from 'mdbreact';

const mapStyles = {
    map: {
    //  position: 'absolute',
    //  width: '100%',
   // maxWidth:200,
      height: '80%'
    }
  };



export default class CurrentLocation extends Component {

  

    constructor(props) {
        super(props);
    
        const { lat, lng } = this.props.initialCenter;
    
        this.state = {
          currentLocation: {
            lat: lat,
            lng: lng
          }
          
          
        };
        this.recenterMap = this.recenterMap.bind(this);
        this.loadMap = this.loadMap.bind(this);
        this.renderChildren = this.renderChildren.bind(this);
    }


    recenterMap() {
        const map = this.map;
        const current = this.state.currentLocation;
        const google = this.props.google;
        const maps = google && google.maps;
    
        if (map) {
          let center = new maps.LatLng(current.lat, current.lng);
          map.panTo(center);
        }
      }

      componentDidMount() {
        if (this.props.centerAroundCurrentLocation) {
          if (navigator && navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(pos => {
              const coords = pos.coords;

              this.setState({
                currentLocation: {
                  lat: coords.latitude,
                  lng: coords.longitude
                }
              });
              localStorage.setItem('latitude', coords.latitude);
              localStorage.setItem('longitude', coords.longitude);
               console.log(coords.latitude)
               console.log(coords.longitude)
              // console.log("klkkk")
            });
          }
        }
        this.loadMap();
      }

      componentDidUpdate(prevProps, prevState) {
        if (prevProps.google !== this.props.google) {
          this.loadMap();
        }
        if (prevState.currentLocation !== this.state.currentLocation) {
          this.recenterMap();
        }
      }


      loadMap() {
        if (this.props && this.props.google) {
          // checks if google is available
          const { google } = this.props;
          const maps = google.maps;
    
          const mapRef = this.refs.map;
    
          // reference to the actual DOM element
          const node = ReactDOM.findDOMNode(mapRef);
    
          let { zoom } = this.props;
          const { lat, lng } = this.state.currentLocation;
          const center = new maps.LatLng(lat, lng);
    
          const mapConfig = Object.assign(
            {},
            {
              center: center,
              zoom: zoom
            }
          );
    
          // maps.Map() is constructor that instantiates the map
          this.map = new maps.Map(node, mapConfig);
        }
      }


      renderChildren() {
        const { children } = this.props;

        if (!children) return;
    
        return React.Children.map(children, c => {
          if (!c) return;
    
          return React.cloneElement(c, {
            map: this.map,
            google: this.props.google,
            mapCenter: this.state.currentLocation
          });
        });
      }
    
  render() {
  //  const style = Object.assign({}, mapStyles.map);+
    const style = Object.assign({}, mapStyles.map);
    return (
        <div >
         
        <div style={mapStyles.map} ref="map">
          {/* By... */}
          
     
        {this.renderChildren()}   </div>
<br/>
      
      </div>
   
     
    )


  }
  
}


CurrentLocation.defaultProps = {

  zoom: 14,
  initialCenter: {
    lat: 0,
    lng: 0
  },
  centerAroundCurrentLocation: false,
  visible: true
};





// CurrentLocation.defaultProps = {
//     zoom: 14,
//     initialCenter: {
//       lat: 0,
//       lng: 0
//     },
//     centerAroundCurrentLocation: false,
//     visible: true
//   };
  
