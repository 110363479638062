import React, { Component } from 'react'
import Axios from 'axios';
import { MDBBtn, MDBIcon, MDBCard, MDBCardBody, MDBAlert } from 'mdbreact';
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip     } from 'antd';

const { Option } = Select;
function onChange(value) {
  console.log(`selected ${value}`);
}

function onBlur() {
  console.log('blur');
}

function onFocus() {
  console.log('focus');
}

function onSearch(val) {
  console.log('search:', val);
}

export default class Successinsurance extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: '',
            name_register: '',
            age_register: '',
            tel_register: '',
            email_register: '',
            lineid_register: '',
            facebook_register: '',
            Educational_register: '',
            position_register: '',
            Income_register: '',
            update: false,
            positionlist:[],
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChange2 = this.handleChange2.bind(this)
        this.handleChange3 = this.handleChange3.bind(this)
      }


    onTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleChange(event) {    this.setState({Educational_register: event});  }
    handleChange2(event) {   this.setState({position_register: event});  }
    handleChange3(event) {    this.setState({Income_register: event});  }

    

  
    render() {


      

        return (
            <div className='container'>
                <MDBCard>
                    <MDBCardBody>
                    <MDBAlert color="success" >
                  <div className="text-center"><MDBIcon className="text-center" icon="check-circle" size="4x"/></div>  
       <h2 className="text-center">เพิ่มข้อมูลฟอร์มสมัครประกันชีวิตสำเร็จ</h2> 
      </MDBAlert>

    
                            <div className="mt-3 text-center">
                                <MDBBtn type="submit" color="blue-grey"
                                 href="https://thenextth.com/Addinsurance2"
                                > <MDBIcon far icon="save" /> กลับหน้าหลัก</MDBBtn>
                            </div>

                    </MDBCardBody>
                </MDBCard>
            </div>
        )
    }
}

