import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem } from 'mdbreact';

export default class Vision extends Component {
    constructor(props) {
        super(props);
        this.state = {
          emp:[],
        };
      //  this.handleChange = this.handleChange.bind(this);

      }

      componentDidMount() {

       
      }


  render() {
    return (
     
      <MDBContainer>
        <br/>
        <h2 className='text-center'>วิสัยทัศน์/ค่านิยม/พันธกิจ</h2>
        <img src="http://kosamphi.kpo.go.th/kosamphigo/kosamphibackend/uploadspic/1452.jpg" className="rounded mx-auto d-block w-10 " width="50%"   alt="aligment" /><br/>
        <h4 className='text-center'>วิสัยทัศน์ (Vision)</h4>
        <p className='text-center'>เป็นโรงพยาบาลที่ให้บริการแบบองค์รวมที่มีคุณภาพโดยชุมชนมีส่วนร่วม</p>
        <h4 className='text-center'>ค่านิยม (Values)</h4>
        <p className='text-center'>ทำงานเป็นทีม บริการด้วยหัวใจ มีความสุขในการทำงาน เป็นองค์กรแห่งการเรียนรู้</p>
        <h4 className='text-center'>พันธกิจ (Mission)</h4>
        <p className='text-center'>1.พัฒนาความเชี่ยวชาญในการดูแลสุขภาพองค์รวม การแพทย์ฉุกเฉินและการส่งต่อ</p>
        <p className='text-center'>2.ให้การบริการสุขภาพอย่างมีคุณภาพและปลอดภัยตามมาตรฐานวิชาชีพ</p>
        <p className='text-center'>3.มุ่งเน้นการเข้าถึงและมีส่วนร่วมกับชุมชนในการป้องกัน ส่งเสริมและฟื้นฟูสุขภาพ</p>
     </MDBContainer>
    )
  }
}
