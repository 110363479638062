import React, { Component } from 'react'
import { Form, Input, Button, Checkbox } from 'antd';
import 'antd/dist/antd.css';
export default class Login extends Component {

    // state = {
    //     Username: "",
    //     Password: "",
    //     loading: true,
    //     login_error: true,
    //     login_error2: true,
    //     login_error3: true,
    //   }

    constructor(props) {
        super(props)
        this.state = {
          Username: "",
          Password: "",
          loading: true,
          login_error: true,
          login_error2: true,
          login_error3: true,
        }
        this.handleChange = this.handleChange.bind(this)
       
      }


    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
        // console.log(event.target)
      }
    
    render() {
        const { getFieldDecorator } = this.props.form;


        const layout = {
            labelCol: { span: 8 },
            wrapperCol: { span: 16 },
          };
          const tailLayout = {
            wrapperCol: { offset: 8, span: 16 },
          };
          
          const Demo = () => {
               const { getFieldDecorator } = this.props.form;
            const onFinish = values => {
              console.log('Success:', values);
            };
          
            const onFinishFailed = errorInfo => {
              console.log('Failed:', errorInfo);
            };
           
            return (
              <Form
                {...layout}
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <Form.Item
                 
                >
                      {getFieldDecorator("Username", {
                  rules: [
                    { required: true, message: "กรุณากรอกข้อมูลชื่อผู้ใช้!" },
                  ],
                })(
                  <Input
                  onChange={this.handleChange}
                  placeholder="Username"
                  name="Username"
                  />
                  )}
                </Form.Item>
          
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[{ required: true, message: 'Please input your password!' }]}
                >
                  <Input.Password 
                    onChange={this.handleChange}
                    placeholder="Password"
                    name="Password"
                  />
                </Form.Item>
          
                <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                  <Checkbox>Remember me</Checkbox>
                </Form.Item>
          
                <Form.Item {...tailLayout}>
                  <Button type="primary" htmlType="submit">
                    Submit1
                  </Button>
                </Form.Item>
              </Form>
             
            );
          };

        return (
            <div>
                <Demo></Demo> {this.state.Password}
            </div>
        )
    }
}
