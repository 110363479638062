import React, { Component } from 'react'
import Axios from 'axios';
import { MDBBtn, MDBIcon, MDBCard, MDBCardBody } from 'mdbreact';
import { MDBInput } from "mdbreact";
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip     } from 'antd';
import Iframe from 'react-iframe'

const { Option } = Select;
function onChange(value) {
  console.log(`selected ${value}`);
}

function onBlur() {
  console.log('blur');
}

function onFocus() {
  console.log('focus');
}

function onSearch(val) {
  console.log('search:', val);
}

export default class Addcomment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: '',
            name_comment: '',
            tel_comment: '',
            email_comment: '',
            content_comment: '',
            filecomment1:null,
        }
       // this.handleChange = this.handleChange.bind(this)
       // this.handleChange2 = this.handleChange2.bind(this)
        //this.handleChange3 = this.handleChange3.bind(this)
        this.handleChangecom = this.handleChangecom.bind(this)
        this.submit = this.submit.bind(this)
      }


    onTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    // handleChange(event) {    this.setState({Educational_register: event});  }
    // handleChange2(event) {   
    //     this.setState({position_register: event}); 
    //     Axios.get('http://thenextth.com/registerfa/selectpositionid.php?id_position='+event)
    //     .then(res => {
    //      // console.log(res.data[0].name_position);
    //     //  this.setState({ bankname: res.data, loading: false })
    //       this.setState({ positionname: res.data[0].name_position})
    //     }) 
    
    // }
    // handleChange3(event) {    this.setState({Income_register: event});  }
    handleChangecom(event) {    this.setState({filecomment1: event.target.files[0]});  }

    submit(event){
        event.preventDefault();

        var datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
        datafrm.append("name_comment", this.state.name_comment);
        datafrm.append("tel_comment", this.state.tel_comment);
        datafrm.append("email_comment", this.state.email_comment);
        datafrm.append("content_comment", this.state.content_comment);
        datafrm.append("avatar", this.state.filecomment1);

        // Axios.post('http://localhost/phpapi/sent.php', datafrm)
        //         .then((res) => {
        //            // this.props.history.push('/Successregister') //เมื่อบันทึกเสร็จจะทำการให้เปลี่ยนไปที่ path Customers
        //            console.log(res)
        //         })
        //         .catch((err) => { alert("ไม่สำเร็จ " + err) })

                Axios
 // .post("https://admission.kpru.ac.th/refund/api/getdata/getuseradmin", { Username: this.form.name, Password: this.form.pass })
   .post("https://thenextth.com/registerfa/sent.php",datafrm)
   .then(res => {
    this.props.history.push('/Successcomment')
     console.log("response: ", res)
     if (res.data.length !== 0) {
      // this.showitem = true;
       console.log(res)
       console.log(res.data.length) 
      // this.toggle()
    }else{
     // window.location.href = '/'
    }
  })

        // if (this.state.update === false) { //เช็คว่าเป็นหน้าสำหรับอัพเดตหรือไม่
        //     Axios.post('http://thenextth.com/registerfa/sent.php', datafrm)
        //         .then((res) => {
        //             this.props.history.push('/Successregister') //เมื่อบันทึกเสร็จจะทำการให้เปลี่ยนไปที่ path Customers
        //         })
        //         .catch((err) => { alert("ไม่สำเร็จ " + err) })

        // }
        // else {
        //     // Axios.post('http://thenextth.com/registerfa/update.php', datafrm)
        //     //     .then((res) => {
        //     //         this.props.history.push('/Customers') //เมื่อบันทึกเสร็จจะทำการให้เปลี่ยนไปที่ path Customers
        //     //     })
        //     //     .catch((err) => { alert("ไม่สำเร็จ " + err) }) // เมื่อเกิด error จะมาทำที่คำสั่งนี้แทน
        // }

    }

    componentDidMount() {
        // let data = this.props.location.data
        // if (data) { // ถ้ามี data เข้ามาแสดงว่ามีการอัพเดต
        //     this.setState({ update: true })
        //     this.setState(data)
        // }

        // setTimeout(() => {
        //     //ภายใน
        //      Axios.get('http://thenextth.com/registerfa/selectposition.php')
        //     .then(res => {
        //       console.log(res);
        //     //  this.setState({ bankname: res.data, loading: false })
        //       this.setState({ positionlist: res.data})
        //     }) 
        //   }, 0);
    }
    render() {


        console.log(this.state.name_comment)
        console.log(this.state.tel_comment)
        console.log(this.state.email_comment)
        console.log(this.state.content_comment)
         console.log(this.state.filecomment1)
        // console.log(this.state.position_register)
        // console.log(this.state.Income_register)

        return (
            <div className='container'>
                <MDBCard>
                    <MDBCardBody>
                    <h5 className="text-center red-text">แจ้งข้อมูลการกระทำผิด</h5>
                        <p className="text-center red-text">เกี่ยวกับจรรยาบรรณ ตัวแทนประกันชีวิต</p>
                        <form onSubmit={this.submit} >
                            <div className="mb-2">
                                <label>ชื่อ - นามสกุล ผู้ร้องเรียน</label>
                                <input className="form-control" onChange={this.onTextChange} name="name_comment"
                                    value={this.state.name_comment} placeholder="ชื่อ - นามสกุล" required />
                            </div>

                            <div className="mb-2">
                                <label>เบอร์โทรศัพท์ ผู้ร้องเรียน</label>
                                <input type="number" className="form-control" onChange={this.onTextChange} name="tel_comment"
                                    value={this.state.tel_comment} placeholder="กรุณาระบุเบอร์โทร" required />
                            </div>

                            <div className="mb-2">
                                <label>E-mail ผู้ร้องเรียน</label>
                                <input className="form-control" onChange={this.onTextChange} name="email_comment"
                                    value={this.state.email_comment} placeholder="กรุณาระบุอีเมลล์" required />
                            </div>

                            <div className="mb-2">
                                <label>แจ้งเรื่องร้องเรียนและรายละเอียด</label>
                                {/* <input type="textarea" className="form-control" onChange={this.onTextChange} name="email_register"
                                    value={this.state.email_register} placeholder="กรุณาระบุอีเมลล์" required /> */}
                                    <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"  onChange={this.onTextChange} name="content_comment"
                                    value={this.state.content_comment} required >  </textarea>
                            </div>

                            <div className="mb-2">
                                <label>แนบไฟล์หลักฐาน (ถ้ามี)</label>
                                <MDBInput  icon="file-upload" group   type="file" validate error="wrong"
                                success="right"  name="file" value={this.state.value} onChange={this.handleChangecom}/>
                            </div>

                         

                            {/* <div className="mt-3 text-center">
                                <MDBBtn  color="red darken-2" onClick={this.submit}> <MDBIcon far icon="save" /> ส่งข้อมูล</MDBBtn>
                            </div> */}
                            <div className="mt-3 text-center">
                                <MDBBtn type="submit" color="red darken-2"> <MDBIcon far icon="save" /> ส่งข้อมูล</MDBBtn>
                            </div>


                        </form>
                    </MDBCardBody>


                </MDBCard>
<br/>
                {/* <MDBCard>
                    <MDBCardBody>
<h1>แนะนำแบบประกัน</h1>
<Iframe url="http://www.anyflip.com/bookcase/okpha"
        width="100%"
        height="450px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/>
                    </MDBCardBody>
                </MDBCard> */}
            </div>
        )
    }
}

