import React, { Component } from 'react'
import { BrowserRouter as Router } from "react-router-dom";
import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBMask,
  MDBRow,
  MDBCol,
  MDBBtn,
  MDBView,
  MDBContainer,
  MDBFormInline,
  MDBAnimation,
  MDBJumbotron,
  MDBTabPane,
  MDBTabContent,
  MDBNav,
  MDBAlert 
} from "mdbreact";
import { MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import "../index.css";
import BackToTop from 'react-back-top';
import Footter from '../Component/Footter';
import { api } from '../api';
import Showfileme from './adminme/Showfileme';
import Showcategory from './adminme/Showcategory';
//import Slider from "react-slick";
import tel from './img/tel.jpg'
import line from './img/line.jpg'
import face from './img/face.jpg'
import telservice from './img/5-697c.png'
import Super from './adminme/Super';



export default class About extends Component {

  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      emp: [],
      news: [],
      banner: [],
      activeItem: "1",
    };
    this.getbanner = this.getbanner.bind(this);
    // this.delete = this.delete.bind(this);
    this.toggle = this.toggle.bind(this);
    this.location1 = this.location1.bind(this);
  }

  handleTogglerClick = () => {
    this.setState({
      collapsed: !this.state.collapsed
    });
  };

  toggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }};

  getbanner() {
    setTimeout(() => {
      //ภายใน
      api.get('selectbanner.php')
        .then(res => {
          console.log(res);
          //  this.setState({ bankname: res.data, loading: false })
          this.setState({ banner: res.data })
        })
    }, 0);
  }

  componentDidMount() {
    this.getbanner();
    // if (this.props.centerAroundCurrentLocation) {
    //   if (navigator && navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(pos => {
    //       const coords = pos.coords;

    //       this.setState({
    //         currentLocation: {
    //           lat: coords.latitude,
    //           lng: coords.longitude
    //         }
    //       });
    //       localStorage.setItem('latitude', coords.latitude);
    //       localStorage.setItem('longitude', coords.longitude);
    //        console.log(coords.latitude)
    //        console.log(coords.longitude)
    //     });
    //   }
    // } 

  }


location1() {
   // this.getbanner();
 
      if (navigator && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(pos => {
          const coords = pos.coords;

          // this.setState({
          //   currentLocation: {
          //     lat: coords.latitude,
          //     lng: coords.longitude
          //   }
          // });
          localStorage.setItem('latitude', coords.latitude);
          localStorage.setItem('longitude', coords.longitude);
           console.log(coords.latitude)
           console.log(coords.longitude)
           console.log("klkkk")
           window.location.href = '/'
        });
      }
    

  }

  render() {
    // const overlay = (
    //     <div
    //       id="sidenav-overlay"
    //     //  style={{ backgroundColor: "transparent" }}
    //       onClick={this.handleTogglerClick}
    //     />
    //   );

    // const config = {
    //   text: "top",
    //     topDistance: 10,
    //     timing:"easeIn"
    // }
    // const settings = {
    //   dots: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1
    // };

    return  (
      <div id="apppage" className='bg' fuild>

      <BackToTop  text={<MDBIcon icon="angle-up" />} icon={<MDBIcon icon="angle-up" />} />
        <MDBContainer className="mt-0"  fuild >
        <MDBCarousel
              activeItem={1}
              length={2}
              showControls={true}
              showIndicators={true}
             className="z-depth-1 "
             fuild
            >
              <MDBCarouselInner  fuild>
                {this.state.banner.map((banner, index) => {
                  return <> <MDBCarouselItem itemId={index+1} fuild>
                   
                      <img
                        className="d-block w-100"
                        src={`https://chiangmaibankforklift.com/bankbackend/uploadsbanner/${banner.banner_file}`}
                        alt="First slide"
                      />
                   
                  </MDBCarouselItem>
                  </>
                })}
              </MDBCarouselInner>
            </MDBCarousel>
          {/* <br/> */}
          <MDBJumbotron className="p-4">
            {/* <h4 className="h4 display-3"></h4> */}
         

            <br />
            <MDBRow>
              <MDBCol sm="4"><a href="tel:0650961963"><img
                        className="d-block w-100"
                        src={tel}
                        alt="First slide"
                      /></a></MDBCol>
              <MDBCol sm="4"><a href="https://lin.ee/G178I3N"><img
                        className="d-block w-100"
                        src={line}
                        alt="First slide"
                      /></a></MDBCol>
              <MDBCol sm="4"><a href="https://www.facebook.com/profile.php?id=100075947426173&mibextid=LQQJ4d"><img
                        className="d-block w-100"
                        src={face}
                        alt="First slide"
                      /></a></MDBCol>
            </MDBRow>
            <br />
            <hr />
          <div className='text-center'>
            <marquee direction="ทิศทางการวิ่ง" width="300"  scrollamount="ความเร็วในการวิ่งของตัวอักษร" scrolldelay="100">บริการรถยก รถสไลด์ หจก.เชียงใหม่แบ้งค์รถยก 209/180 ม.3 ตำบลสัมนาเม็งอำเภอสันทราย จังหวัดเชียงใหม่ 50210</marquee>
            </div>  
            <hr />
            <h4 ><MDBIcon far icon="images" /> ภาพผลงาน</h4>
            <Showcategory /> <br />
            <hr />
            <h4 ><MDBIcon far icon="images" /> ตำแหน่งรถบริการฉุกเฉิน</h4>
          
            <div className='ml-1 mr-1'> 
             <Super /> 
            {localStorage.getItem('latitude') === null ?<>
             <div className='text-center'>
             <h2 className='text-center'>ยังไม่ได้ระบุตำแหน่ง</h2>
              <MDBBtn  color="dark-green" onClick={this.location1}><MDBIcon far icon="edit" /> ระบุตำแหน่งของท่าน</MDBBtn></div> 
            </>:<>
           
            </>}
          
            </div>  
            <hr />
            <h4 ><MDBIcon far icon="images" /> ที่อยู่</h4>
            <MDBAlert color="danger" >
            <h4 className='text-center'>หจก.เชียงใหม่แบ้งค์รถยก</h4>
            <h5 className='text-center'>209/180 ม.3 ตำบลสัมนาเม็ง</h5>
            <h5 className='text-center'>อำเภอสันทราย จังหวัดเชียงใหม่ 50210</h5>
            <h5 className='text-center'>เลขผู้เสียภาษี 0503566004851</h5>
  </MDBAlert>

        
            <MDBRow className="py-5">
              <MDBCol md="12" className="text-center">
          
              </MDBCol>
            </MDBRow>

          </MDBJumbotron>
          {/* <Footter/>     */}
        </MDBContainer>


      </div>
    )
  }
}
