import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';
import { Divider } from 'antd';
import { api } from '../../api';
import Modalbanner from './Modalbanner';
import LoadingOverlay from 'react-loading-overlay';
import Modalcar from './Modalcar';
import Modaldiver from './Modaldiver';
import Modaldiveredit from './Modaldiveredit';
import Modalcaredit from './Modalcaredit';




export default class Addcar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading:false,
          emp:[],
          news:[],
          banner:[],

          car:[],
          diver:[],
        };
       this.getcar = this.getcar.bind(this);
       this.delete = this.delete.bind(this);
       this.getdiver = this.getdiver.bind(this);
       this.deletediver = this.deletediver.bind(this);
      }

      componentDidMount() {
      this.getcar();
      this.getdiver();
      }

      getcar() {
        this.setState({ loading: true})
        setTimeout(() => {
           //ภายใน
           api.get('selectcar.php')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ car: res.data,loading:false})
           }) 
         }, 0);
      }

      getdiver() {
        this.setState({ loading: true})
        setTimeout(() => {
           //ภายใน
           api.get('selectdiver.php')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ diver: res.data,loading:false})
           }) 
         }, 0);
      }

      delete = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
        api.get('deletecar.php?id=' + id)
            .then((res) => {
                this.getcar()
            })
      }

      deletediver = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
        api.get('deletediver.php?id=' + id)
            .then((res) => {
                this.getdiver()
            })
      }


  render() {
  //  console.log(this.state.news)

    const data = {
      columns: [
        {
          label: 'ลำดับ',
          field: 'list',
          sort: 'list',
          width: 150
        },
       
        {
          label: 'ลำดับรถ/หมายเลขรถ',
          field: 'NAME',
          sort: 'NAME',
          width: 200
        },
         {
          label: 'ชื่อผู้ขับ',
          field: 'DATES',
          sort: 'DATES',
          width: 200
        },
        {
          label: 'จัดการ',
          field: 'FACULTY',
          sort: 'FACULTY',
          width: 200
        }

      ],

      rows: [...this.state.car.map((data, i) => (
        {
           list: <>{i+1}</>,
         
           NAME: <>{data.namecar}</>,
           DATES: <>{data.name_member}</>,
           FACULTY: <> 
           <Modalcaredit id_car={data.id_car} getcar={this.getcar} /><Divider type="vertical" /> 
           <MDBBtn size="sm" color="unique" onClick={() => this.delete(data.id_car)} ><MDBIcon far icon="trash-alt" /> ลบ</MDBBtn>
           </>,
          

          //  random: <h5 searchvalue={data.Curriculumopen_TEAM} >tourist <span className="badge badge-danger">narrow</span></h5>,

        }
       ))] 
  
  };

  const persons = <MDBDataTable
  // searching ={true}
   searchLabel="ค้นหา รายการ"
   striped
   bordered
   small 
   entries={20}
  // entriesOptions={["10", "20"]}
   order={["age", "desc"]}
   data={data}
   entriesLabel="จำนวนที่แสดง "
   infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}
   paginationLabel={["ย้อนกลับ", "ถัดไป"]}
   responsive 
   sortRows={['random']}
/>



const datadiver = {
  columns: [
    {
      label: 'ลำดับ',
      field: 'lists',
      sort: 'lists',
      width: 150
    },
   
    {
      label: 'คนขับ',
      field: 'NAMEd',
      sort: 'NAMEd',
      width: 200
    },
    {
      label: 'user',
      field: 'user',
      sort: 'user',
      width: 200
    },
    {
      label: 'password',
      field: 'pass',
      sort: 'pass',
      width: 200
    },
    {
      label: 'จัดการ',
      field: 'FACULTY',
      sort: 'FACULTY',
      width: 200
    }

  ],

  rows: [...this.state.diver.map((diver, i) => (
    {
       lists: <>{i+1}</>,
       NAMEd: <>{diver.name_member}</>,
       user: <>{diver.username}</>,
       pass: <>{diver.password}</>,
       FACULTY: <>
        <Modaldiveredit id_member={diver.id_member} getdiver={this.getdiver} /> <Divider type="vertical" />     <MDBBtn size="sm" color="unique" onClick={() => this.deletediver(diver.id_member)} ><MDBIcon far icon="trash-alt" /> ลบ</MDBBtn>
        </>,
   
    }
   ))] 

};

const ppdiver = <MDBDataTable
// searching ={true}
 searchLabel="ค้นหา รายการ"
 striped
 bordered
 small 
 entries={20}
// entriesOptions={["10", "20"]}
 order={["age", "desc"]}
 data={datadiver}
 entriesLabel="จำนวนที่แสดง "
 infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}
 paginationLabel={["ย้อนกลับ", "ถัดไป"]}
 responsive 
 sortRows={['random']}
/>

    return (
      <LoadingOverlay
      active={this.state.loading}
      spinner
      text='Loading your data...'
      >
      <MDBContainer>
        <br/>
        <h2 className='text-center'>ระบบจัดการรถ/คนขับ</h2>
        <h4 className='text-center'>ผู้ใช้งาน : {localStorage.getItem('name_member')}</h4>

      <p className='text-center'>
        <Modaldiver getdiver={this.getdiver}/>
        </p> 
          {ppdiver}
          <br/>
      <p className='text-center'><Modalcar getcar={this.getcar}/></p> 
    
         {persons}
      </MDBContainer>
      </LoadingOverlay>  
    )
  }
}
