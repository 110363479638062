import React, { Component } from 'react'
import Axios from 'axios'
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip  } from 'antd';

export default class Plan extends Component {


    constructor(props) {
        super(props);
this.state = {
   modal: false,
   editstatus:'',
   name_in:'',
   tel_in:'',

   data:[],
}


// this.handleSubmit = this.handleSubmit.bind(this);
// this.handleChangeedit = this.handleChangeedit.bind(this);
}


    componentDidMount() {
        // const { id_send } = this.props.id_send
        // console.log(id_send);
        this.setState({ name_in:this.props.name_in})
        this.setState({ tel_in:this.props.tel_in})

        const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
        datafrm.append("name_in", this.props.name_in);
        datafrm.append("tel_in", this.props.tel_in);
       
  
    Axios.post("https://thenextth.com/registerfa/selectplanlist.php",datafrm,{
      headers: {
          'content-type': 'multipart/form-data'
      }
     })
  
     .then(res => {
        this.setState({ data: res.data })
    })

    }
    render() {
        return (
            <div>
                {this.state.data.map((data, index) => {
                                    return  <p>- {data.name_plan}</p>
                                })}
            </div>
        )
    }
}
