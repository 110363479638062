import React, { Component } from 'react'
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn, MDBIcon, MDBCard, MDBCardBody } from 'mdbreact';
import Modaleditstatus from './Modaleditstatus';

export default class selectregister extends Component { 
    constructor(props) {
        super(props)
        this.state = {
            data: [],
        }
       // this.handleChange = this.handleChange.bind(this)
       // this.handleChange2 = this.handleChange2.bind(this)
        //this.handleChange3 = this.handleChange3.bind(this)
      }

    onTextChange = (e) => { //เมื่อมีการพิมพ์ค้นหา ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่งค่าที่ต้องการค้นหาไปที่ไฟล์ search.php
        Axios.get('https://thenextth.com/registerfa/search.php?id=' + e.target.value)
            .then((res) => {
                this.setState({ data: res.data })
            })
    }


    delete = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
        Axios.get('https://thenextth.com/registerfa/delete.php?id=' + id)
            .then((res) => {
                this.getdata()
            })
    }

    getdata = () => { //เป็นฟังก์ชั่นไว้เรียกใช้สำหรับดึงข้อมูลมาทั้งหมด
        Axios.get('https://thenextth.com/registerfa/selectregister.php')
            .then((res) => {
                this.setState({ data: res.data })
            })
    }

    componentDidMount() { //เป็นฟังก์ชั่นชอง react โดนเฉพาะ โดยฟังก์ชั่นนี้จะถูกเรียกใช้งานครั้งแรกเสมอเมื่อมีการโหลด component นี้
        this.getdata()
    }

    render() {
        return (
            <div className="container mt-2">
                <MDBCard>
                    <MDBCardBody>

                        <h1>รายชื่อผู้ลงทะเบียน</h1>

                        <div className="text-right">
                            <input onChange={this.onTextChange} placeholder="ค้นหาชื่อ-สกุล" />
                        </div>


                        <MDBTable small style={{ border: "1px" }} border width="100%">
                            <MDBTableHead  >
                                <tr  >
                                    <th>ชื่อ-สกุล</th>
                                    <th>อายุ</th>
                                    <th>เบอร์โทร</th>
                                    <th>อีเมลล์</th>
                                    <th>จัดการ</th>
                                </tr>
                            </MDBTableHead>


                            <MDBTableBody>
                                {this.state.data.map((data) => {
                                    return <tr>
                                        <td>{data.name_register}</td>
                                        <td>{data.age_register}</td>
                                        <td>{data.tel_register}</td>
                                        <td>{data.email_register}</td>
                                        <td>
                                            {/* <Link to={{ pathname: "/AddCustomers/", data: data }} >
                                                <MDBBtn size="sm" color="primary"><MDBIcon far icon="edit" /> จัดการสถานะ</MDBBtn>
                                            </Link> */}
                                            <Modaleditstatus id_send={data.id_register}/>
                                            <MDBBtn size="sm" color="pink" onClick={() => this.delete(data.id_register)} ><MDBIcon far icon="trash-alt" /> ลบ</MDBBtn>
                                        </td>
                                    </tr>
                                })}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCardBody>
                </MDBCard>
            </div>
        )
    }
}
