import React, { Component } from 'react'
import Axios from 'axios';
import { MDBBtn, MDBIcon, MDBCard, MDBCardBody } from 'mdbreact';
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip     } from 'antd';
import Iframe from 'react-iframe'

const { Option } = Select;
function onChange(value) {
  console.log(`selected ${value}`);
}

function onBlur() {
  console.log('blur');
}

function onFocus() {
  console.log('focus');
}

function onSearch(val) {
  console.log('search:', val);
}

export default class Addregister extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: '',
            name_register: '',
            age_register: '',
            tel_register: '',
            email_register: '',
            lineid_register: '',
            facebook_register: '',
            Educational_register: '',
            other_register: '',
            position_register: '',
            Income_register: '',
            update: false,
            positionlist:[],
            positionname:'',
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleChange2 = this.handleChange2.bind(this)
        this.handleChange3 = this.handleChange3.bind(this)
      }


    onTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleChange(event) {    this.setState({Educational_register: event});  }
    handleChange2(event) {   
        this.setState({position_register: event}); 
        Axios.get('https://thenextth.com/registerfa/selectpositionid.php?id_position='+event)
        .then(res => {
         // console.log(res.data[0].name_position);
        //  this.setState({ bankname: res.data, loading: false })
          this.setState({ positionname: res.data[0].name_position})
        }) 
    
    }
    handleChange3(event) {    this.setState({Income_register: event});  }

    submit = (event) => {
        event.preventDefault();

        var datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
        datafrm.append("name_register", this.state.name_register);
        datafrm.append("age_register", this.state.age_register);
        datafrm.append("tel_register", this.state.tel_register);
        datafrm.append("email_register", this.state.email_register);
        datafrm.append("lineid_register", this.state.lineid_register);
        datafrm.append("facebook_register", this.state.facebook_register);
        datafrm.append("Educational_register", this.state.Educational_register);
        datafrm.append("other_register", this.state.other_register);
        datafrm.append("position_register", this.state.position_register);
        datafrm.append("positionname", this.state.positionname);
        datafrm.append("Income_register", this.state.Income_register);

        if (this.state.update === false) { //เช็คว่าเป็นหน้าสำหรับอัพเดตหรือไม่
            Axios.post('https://thenextth.com/registerfa/insertregister.php', datafrm)
                .then((res) => {
                    this.props.history.push('/Successregister') //เมื่อบันทึกเสร็จจะทำการให้เปลี่ยนไปที่ path Customers
                })
                .catch((err) => { alert("ไม่สำเร็จ " + err) })

        }
        else {
            Axios.post('https://thenextth.com/registerfa/update.php', datafrm)
                .then((res) => {
                    this.props.history.push('/Customers') //เมื่อบันทึกเสร็จจะทำการให้เปลี่ยนไปที่ path Customers
                })
                .catch((err) => { alert("ไม่สำเร็จ " + err) }) // เมื่อเกิด error จะมาทำที่คำสั่งนี้แทน
        }

    }

    componentDidMount() {
        // let data = this.props.location.data
        // if (data) { // ถ้ามี data เข้ามาแสดงว่ามีการอัพเดต
        //     this.setState({ update: true })
        //     this.setState(data)
        // }

        setTimeout(() => {
            //ภายใน
             Axios.get('https://thenextth.com/registerfa/selectposition.php')
            .then(res => {
              console.log(res);
            //  this.setState({ bankname: res.data, loading: false })
              this.setState({ positionlist: res.data})
            }) 
          }, 0);
    }
    render() {


        console.log(this.state.other_register)
        // console.log(this.state.age_register)
        // console.log(this.state.tel_register)
        // console.log(this.state.email_register)
        // console.log(this.state.Educational_register)
        // console.log(this.state.position_register)
        // console.log(this.state.Income_register)

        return (
            <div className='container'>
                <MDBCard className='mt-3'>
                    <MDBCardBody>
                        <h3 className="text-center red-text">{this.state.update === true ? <>อัพเดตข้อมูลสมาชิก</> : <>ลงทะเบียนสมัครเข้าร่วมโครงการ AIA FA</>}</h3>
                        <form onSubmit={this.submit}>
                            <div className="mb-2 mt-2">
                                <label>ชื่อ - นามสกุล</label>
                                <input className="form-control" onChange={this.onTextChange} name="name_register"
                                    value={this.state.name_register} placeholder="ชื่อ - นามสกุล" required />
                            </div>

                            <div className="mb-2">
                                <label>อายุ</label>
                                <input className="form-control" onChange={this.onTextChange} name="age_register"
                                    value={this.state.age_register} placeholder="กรุณาระบุอายุ"  required />
                            </div>

                            <div className="mb-2">
                                <label>เบอร์โทร</label>
                                <input type="number" className="form-control" onChange={this.onTextChange} name="tel_register"
                                    value={this.state.tel_register} placeholder="กรุณาระบุเบอร์โทร" required />
                            </div>

                            <div className="mb-2">
                                <label>อีเมลล์</label>
                                <input className="form-control" onChange={this.onTextChange} name="email_register"
                                    value={this.state.email_register} placeholder="กรุณาระบุอีเมลล์" required />
                            </div>

                            <div className="mb-2">
                                <label>Line ID</label>
                                <input className="form-control" onChange={this.onTextChange} name="lineid_register"
                                    value={this.state.lineid_register} placeholder="กรุณาระบุlineid"  />
                            </div>

                            <div className="mb-2">
                                <label>facebook</label>
                                <input className="form-control" onChange={this.onTextChange} name="facebook_register"
                                    value={this.state.facebook_register} placeholder="กรุณาระบุfacebook"  />
                            </div>

                            <div className="mb-2">
                                <label>วุฒิการศึกษา</label><br/>
                                <Select
                                        showSearch
                                      // style={{ width: 500}}
                                        style={{ width: "100%" }}
                                        placeholder="วุฒิการศึกษา"
                                        optionFilterProp="children"
                                        onChange={this.handleChange}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        
                                    >
                                        <Option value="สูงกว่าปริญญาตรี">สูงกว่าปริญญาตรี</Option>
                                        <Option value="ปริญญาตรีหรือเทียบเท่า">ปริญญาตรี หรือ เทียบเท่า</Option>
                                        <Option value="ต่ำกว่าปริญญาตรี">ต่ำกว่าปริญญาตรี โปรดระบุ</Option>
                                    </Select>
                            </div>

                            {this.state.Educational_register === "ต่ำกว่าปริญญาตรี" ?<>
                            <div className="mb-2">
                                <label>โปรดระบุ วุฒิการศึกษา..</label>
                                <input className="form-control" onChange={this.onTextChange} name="other_register"
                                    value={this.state.other_register} placeholder="โปรดระบุ"  />
                            </div>
                            </> :<></> }

                            <div className="mb-2">
                                <label>ตำแหน่งที่สนใจ</label><br/>
                                <Select
                                        showSearch
                                      // style={{ width: 500}}
                                        style={{ width: "100%" }}
                                        placeholder="ตำแหน่งที่สนใจ"
                                        optionFilterProp="children"
                                        onChange={this.handleChange2}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                    >
                                 {this.state.positionlist.map((positionlist,index )  => 
                                    <Option value={positionlist.id_position}>{positionlist.name_position}</Option>
                                    )}

                                </Select>
                            </div>

                            <div className="mb-2">
                                <label>แผนรับรองรายได้ที่ต้องการต่อเดือน (จำนวน 12 เดือน)</label><br/>
                              
                                <Select
                                required
                                        showSearch
                                      // style={{ width: 500}}
                                        style={{ width: "100%" }}
                                        placeholder="แผนรับรองรายได้"
                                        optionFilterProp="children"
                                        onChange={this.handleChange3}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                        
                                    >
                                       
                                        <Option value="10,000 - 15,000">10,000 - 15,000</Option>
                                        <Option value="15,000 - 20,000">15,000 - 20,000</Option>
                                        <Option value="20,000 - 25,000">20,000 - 25,000</Option>
                                        <Option value="25,000 - 30,000">25,000 - 30,000</Option>
                                        <Option value="30,000 ขึ้นไป">30,000 ขึ้นไป</Option>
                                    </Select>

                          
                            </div>



                            <div className="mt-3 text-center">
                                <MDBBtn type="submit" color="red darken-2"> <MDBIcon far icon="save" /> ส่งใบสมัคร</MDBBtn>
                            </div>


                        </form>
                    </MDBCardBody>


                </MDBCard>
<br/>
                {/* <MDBCard>
                    <MDBCardBody>
<h1>แนะนำแบบประกัน</h1>
<Iframe url="http://www.anyflip.com/bookcase/okpha"
        width="100%"
        height="450px"
        id="myId"
        className="myClassname"
        display="initial"
        position="relative"/>
                    </MDBCardBody>
                </MDBCard> */}
            </div>
        )
    }
}

