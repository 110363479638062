import React, { Component } from 'react'
import { MDBCol, MDBContainer, MDBRow, MDBFooter,   MDBMask ,MDBIcon} from "mdbreact";
import "../index.css";
//import logoas from '../Pages/img/as.png'
import logoas2 from '../Pages/img/as2.png'
export default class Footter extends Component {
    render() {
        return (
            <div className='mt-0 ' >
        <footer className="footer--pin ">
       
        <MDBFooter className="heaol font-small  ">
          <MDBContainer fluid className="text-center text-md-center ">
            <MDBRow>
              <MDBCol md="12" className='mt-2'>
              <br/>
              <div >
              {/* <img
               
                  src={logoas2}
                   width="5%"
                  alt="Second slide"
                /> */}
               
                {/* <h5 className="title white-text"  >เรามุ่งมั่นที่จะสร้างทีมงานคนรุ่นใหม่ ที่มีหัวใจของการบริการ ในด้านประกันชีวิต และการวางแผนทางการเงิน พร้อมที่จะให้บริการลูกค้าอย่างมืออาชีพ</h5>
                <h5 className="title white-text">จึงเกิดเป็นสำนักงานที่ทันสมัย และ มีระบบการสร้าง FA มืออาชีพ และมีระบบ operation ที่มีมาตรฐาน ที่ให้บริการลูกค้าได้อย่างเป็นระบบและครบวงจร</h5> */}
                {/* <p>
                0650961963
                </p> */}
<MDBContainer>

  {/* <MDBRow >
       
        <MDBCol md="6">
        <p className="font-weight-bold"> CONTACT</p>
        <a href="tel:0815955492"> <p> <MDBIcon icon="phone-volume" /> 081-595-5492</p></a>
        <a href="tel:0815955492">  <p><MDBIcon icon="phone-square" /> 055-799-243</p></a>
          </MDBCol>
        
        <MDBCol md="6">
        <p className="font-weight-bold"> REACH</p>
        <a href="https://www.facebook.com/icewitsanu" target="_blank"><MDBIcon fab icon="facebook" size="2x" /></a>  <a href="https://line.me/ti/p/-vQFhWJFH0" target="_blank"><MDBIcon fab icon="line" size="2x"  /></a>  <MDBIcon fab icon="youtube" size="2x"  />
          </MDBCol>
      </MDBRow> */}
</MDBContainer>
      

              </div>
              </MDBCol>
            
            </MDBRow>
          </MDBContainer>
          <div className="footer-copyright text-center py-3 ">
            <MDBContainer fluid>
              &copy; {new Date().getFullYear()} Copyright: <a href=""> thenext </a>
            </MDBContainer>
          </div>
        </MDBFooter>
    
        </footer>
            </div>
        )
    }
}
