import React, { Component } from 'react'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBAlert, MDBSelect , MDBInput  } from 'mdbreact';
import { MDBSelectInput, MDBSelectOptions, MDBSelectOption , MDBIcon, MDBFormInline} from "mdbreact";
import Axios from 'axios'
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip, Radio  } from 'antd';
import { api } from '../../api';
import Swal from 'sweetalert2'



export default class Modallogin extends Component {

   


constructor(props) {
        super(props);

        this.state = {
            modal: false,
          
            username:'',
            password:'',

            tel:'',
           
            valuetype:1,
          
             filecomment1:null,
             filecomment2:null,
          }

this.onTextChange = this.onTextChange.bind(this);
this.onChangetype = this.onChangetype.bind(this);
this.toggle = this.toggle.bind(this);
this.onClick = this.onClick.bind(this);
this.getstudent = this.getstudent.bind(this);
this.getauthorities = this.getauthorities.bind(this);

}

toggle = () => {
  this.setState({
    modal: !this.state.modal
  });
 // this.props.selectatf();
}

 componentDidMount() {
    //  const { id_send } = this.props.id_send
    //  console.log(id_send);
     // this.setState({ id_send:this.props.id_send})
 
  }

  onTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
 }

 onChangetype(value) {
    this.setState({ auth_status:value})
  }

  onClick = nr => () => {
    this.setState({
      radio: nr
    });
  };

   onChange = (e) => {
    console.log('radio checked', e.target.value);
    this.setState({
        valuetype:e.target.value
    });
    //setValue(e.target.value);
  };

  getstudent() {
    setTimeout(() => {
       //ภายใน
        Axios.get(`https://arit.kpru.ac.th/ap/api/?nu=tabianStudentCheck&code=${this.state.username}&password=${this.state.password}&Token=S2FtcGhhZW5nIFBoZXQgUmFqYWJoYXQgVW5pdmVyc2l0eQ==`,
            // Axios.get(https://mis.kpru.ac.th/api/EmployeeInOrg/000,
             {headers: {
                // Authorization: 'Bearer ' + localStorage.getItem('myData')
             }
             })
       .then(res => {
         console.log(res);
       //  this.setState({ bankname: res.data, loading: false })
       //  this.setState({ student: res.data})
       }) 
     }, 0);
  }

  getauthorities() {
    setTimeout(() => {
       //ภายใน
       api.get('selectauth.php?tel='+this.state.tel)
       .then(res => {
         console.log(res.data);
      if (res.data.length !== 0) {
        localStorage.setItem('admin_type', 2);
        localStorage.setItem('name', res.data[0].auth_name+' '+res.data[0].auth_lname);

        Swal.fire({
            title: 'ยินดีต้อนรับ',
            icon: 'success',
          // showCancelButton: true,
            confirmButtonText: 'ตกลง',
          // cancelButtonText: 'No, keep it'
          }).then((result) => {
            this.toggle();
            window.location.href = '/kprurun2022/auth'
        
          })
      } else {
        Swal.fire(
            'ไม่สามารถเข้าถึงระบบได้',
            'กรุณาลองอีกครั้ง',
            'question'
          )
      }

                  // localStorage.setItem('type2', res[0].employee_id);
                   //เจ้าหน้าที่
                  // window.location.href = '/vaccinekpru/Stuall'
       }) 
     }, 0);
  }

    render() {

        // console.log(this.state.auth_name)
        // console.log(this.state.auth_lname)
        // console.log(this.state.auth_agency)
        // console.log(this.state.auth_tel)
        // console.log(this.state.valuetype)
        
        return (
            <MDBContainer >
            <MDBBtn color="unique" onClick={this.toggle} className="text-center">เข้าสู่ระบบ</MDBBtn>

            <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
              <MDBModalHeader toggle={this.toggle}><MDBIcon icon="registered" /> เข้าสู่ระบบ</MDBModalHeader>
              <MDBModalBody className="text-left">
              <div>

              <form onSubmit={this.handleSubmit}>
             
<div className='text-center'>
   <Radio.Group onChange={this.onChange} defaultValue={this.state.valuetype}>
      <Radio value={1}>นักศึกษา</Radio>
      <Radio value={2}>เจ้าหน้าที่</Radio>
    </Radio.Group>
    
</div>
<br/>
           
           {this.state.valuetype === 1 ?<>
                           <div className="mb-2">
                                <label>username (รหัสนักศึกษา)</label>
                                <input className="form-control" onChange={this.onTextChange} name="username"
                                    value={this.state.username} placeholder="กรุณาระบุ username" required />
                            </div>

                            <div className="mb-2">
                                <label>password ( ว/ด/ป เกิด)</label>
                                <input className="form-control" type="password" onChange={this.onTextChange} name="password"
                                    value={this.state.password} placeholder="กรุณาระบุ password" required />
                            </div>

                            <div className="mt-3 text-center" >
                                <MDBBtn  color="primary" className="text-center" onClick={this.getstudent}> <MDBIcon far icon="save" /> เข้าสู่ระบบ</MDBBtn>
                            </div>
           
           </>: <>

                            <div className="mb-2">
                                <label>username (เบอร์โทร)</label>
                                <input className="form-control" onChange={this.onTextChange} name="tel"
                                    value={this.state.tel} placeholder="กรุณาระบุ username" required />
                            </div>

                          
                            <div className="mt-3 text-center" >
                                <MDBBtn  color="primary" className="text-center" onClick={this.getauthorities}> <MDBIcon far icon="save" /> เข้าสู่ระบบ</MDBBtn>
                            </div>
           </>}


                        </form>


              </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn>
                {/* <MDBBtn color="primary" onClick={this.handleSubmit} >บันทึก</MDBBtn> */}
              </MDBModalFooter>
            </MDBModal>
          </MDBContainer>
          
        )
    }
}
