import React, { Component } from 'react'
import Axios from 'axios';
import { MDBBtn, MDBIcon, MDBCard, MDBCardBody ,MDBInput} from 'mdbreact';
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip ,Radio, Space    } from 'antd';
import Iframe from 'react-iframe'
import { Tabs, Checkbox } from 'antd';
import {    Divider,  Row, Col } from 'antd';

const { TabPane } = Tabs;

const { Option } = Select;
function onChange(value) {
  console.log(`selected ${value}`);
}

function onBlur() {
  console.log('blur');
}

function onFocus() {
  console.log('focus');
}

function onSearch(val) {
  console.log('search:', val);
}

export default class Addinsurance2 extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: '',
            name_in: '', //ชื่อ-สกุล
            bddate: '', //วันเดือนปีเกิด
            age_in: '', //อายุ
            gender_in: '', //เพศ
            weight : '', //น้ำหนัก
            height : '', //ส่วนสูง
            disease : '',// โรคประจำตัว
            otherdisease : '',  // ระบุโรคประจำตัว
            singlestatus  : '', //สถานะโสด
            othersinglestatus : '', //สถานะอื่นๆ
            tel_in: '', //เบอร์โทร
            referral : '', // ชื่อและเบอร์โทรบุคคลอ้งอิง
            email_in: '', //email
            address: '', //ที่อยู่
            province : '', //idจังหวัด
            provincename : '', //ชื่อจังหวัด
            amper : '', //idอำเภอ
            ampername : '', //ชื่ออำเภอ
            district : '', //idตำบล
            districtname : '', //ชื่อตำบล
            zipcode : '', //รหัสไป
            historycam : '', //เคยมีประกัน
            otherhistorycam : '',// เคยมีประกันระบุ

            covid1:'',
            covid2:'',
            covid3:'',
            hospital:'',
            // [ข้อมูลอาชีพ]
            occupation : '', //อาชีพ
            position : '', //ตำแหน่ง
            work : '', //ลักษณะงานที่ทำ
            company : '', //บริษัทหรือที่ทำงาน
            income : '', //รายได้ต่อปี
            addresspt: '', //ที่อยู่ที่ทๆงาน
            provincept : '', //idจังหวัดอาชีพ
            provinceptname : '', //ชื่อจังหวัดอาชีพ
            amperpt : '', //idอำเภออาชีพ
            amperptname : '', //ชื่ออำเภออาชีพ
            districtpt : '', //idตำบล
            districtptname : '', //ชื่อตำบลอชีพ
            zipcodept : '', //รหัสไป
          


            // [ข้อมูลผู้รับผลประโยชน์]
            namereceive  : '', //ชื่อผู้รับผลประโยชน์
            relationshipreceive : '', //ความสัมพัน์ผู้รับผลประโยชน์
            agereceive : '', // อายุผู้รับผลประโยชน์
            filecid : null, // อายุผู้รับผลประโยชน์
            filecid2: null, // อายุผู้รับผลประโยชน์
            selectedFile:null,
            

            //ข้อมูลตัวเลือกส่วนตัว
            PROVINCE :[],
            AMPHUR :[],
            DISTRICT:[],

            //ข้อมูลตัวเลือกอาชีพ
            AMPHURPT:[],
            DISTRICT:[],



            value: 1,
            update: false,
            positionlist:[],
            planlist:[],
            checked1:'',
            plan:[],
            name_plan:[],
            name_plan2:'',
        }
        
        this.handleChange = this.handleChange.bind(this)
        this.handleChange2 = this.handleChange2.bind(this)
        this.handleChange3 = this.handleChange3.bind(this)
        this.gender = this.gender.bind(this)
        this.disease = this.disease.bind(this)
        this.singlestatus = this.singlestatus.bind(this)
        this.historycam = this.historycam.bind(this)
      
        this.onChangebox = this.onChangebox.bind(this)
        this.onTextChange = this.onTextChange.bind(this)
        this.checkbox1 = this.checkbox1.bind(this)
        this.handleprovince = this.handleprovince.bind(this)
        this.handleamphur = this.handleamphur.bind(this)
        this.handledistrict = this.handledistrict.bind(this)
        this.handleprovincept = this.handleprovincept.bind(this)
        this.handleamphurpt = this.handleamphurpt.bind(this)
        this.handledistrictpt = this.handledistrictpt.bind(this)
        this.onTextChangefile1 = this.onTextChangefile1.bind(this)
        this.onTextChangefile2 = this.onTextChangefile2.bind(this)
        this.onFileChange = this.onFileChange.bind(this)
        this.covid1 = this.covid1.bind(this)
        this.covid2 = this.covid2.bind(this)
        this.covid3 = this.covid3.bind(this)
        

    
      }


    onTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleChange(event) {    this.setState({Educational_register: event});  }
    handleChange2(event) {   this.setState({position_register: event});  }
    handleChange3(event) {    this.setState({gender_in: event});  }

    gender(event) {    this.setState({gender_in: event.target.value});  }
    disease(event) {    this.setState({disease: event.target.value});  }
    singlestatus(event) {    this.setState({singlestatus: event.target.value});  }
    historycam(event) {    this.setState({historycam: event.target.value});  }

    covid1(event) {    this.setState({covid1: event.target.value});  }
    covid2(event) {    this.setState({covid2: event.target.value});  }
    covid3(event) {    this.setState({covid3: event.target.value});  }

    onTextChangefile1 = event => {  
         // console.log(event.target.files[0])
         this.setState({filecid: event.target.files[0]}); 
      //  this.setState({filecid: event.target.value}); 
         }

    onTextChangefile2(event) {  
      //  console.log(event.target.files[0])
        this.setState({filecid2: event.target.files[0]});  
    }

    onFileChange = event => {
        console.log(event.target.files[0])
        // Update the state
     //   this.setState({ selectedFile: event.target.files[0] });
      
      };
    onChangebox(event) {
        // const joined = this.state.myArray.concat(event);
        // this.setState({ myArray: joined })
         this.setState({ plan : event })
      //console.log(event)
      setTimeout(() => {
         Axios.get('http://thenextth.com/registerfa/selectplanid.php?id_plan='+event)
         .then(res => {
         // console.log(res.data[0].name_position);
         //  this.setState({ bankname: res.data, loading: false })
          this.setState({ name_plan: res.data[0].name_plan})
          
          var joined = this.state.name_plan.concat(res.data[0].name_plan);
          this.setState({ name_plan: joined })

         })  
       //  console.log(event[1])
      }, 1000);
        }

        //จังหวัดข้อมูลส่วนตัว
        handleprovince(e,u){
           // console.log(u)
            this.setState({province:u.value, provincename:u.label})
            setTimeout(() => {
                //จังหวัด
                
                 Axios.get('https://thenextth.com/registerfa/selectamphures.php?PROVINCE_ID='+u.value)
                // Axios.get('http://localhost/phpapi/selectamphures.php?PROVINCE_ID='+u.value)
                .then(res => {
              //    console.log(res);
                //  this.setState({ bankname: res.data, loading: false })
                const data = res.data
                const optionsam = data.map(a => ({
                    "value" : a.AMPHUR_ID,
                    "label" : a.AMPHUR_NAME
                  }))
                  this.setState({ AMPHUR: optionsam})
                }) 
              }, 0);
           }

           //อำเภอข้อมูลส่วนตัว
           handleamphur(e,u){
            // console.log(u)
             this.setState({amper:u.value, ampername:u.label})
 
             setTimeout(() => {
                 //จังหวัด
                 
                  Axios.get('https://thenextth.com/registerfa/selectdistricts.php?AMPHUR_ID='+u.value)
                //  Axios.get('http://localhost/phpapi/selectdistricts.php?AMPHUR_ID='+u.value)
                 .then(res => {
                  // console.log(res);
                 //  this.setState({ bankname: res.data, loading: false })
                 const data = res.data
                 const optionsds = data.map(a => ({
                     "value" : a.DISTRICT_CODE,
                     "label" : a.DISTRICT_NAME
                   }))
                   this.setState({ DISTRICT: optionsds})
                 }) 
               }, 0);
            }

             //ตำบลข้อมูลส่วนตัว
             handledistrict(e,u){
            // console.log(u)
             this.setState({district:u.value, districtname:u.label})
 
             setTimeout(() => {
                
                 //จังหวัด
                  Axios.get('https://thenextth.com/registerfa/selectzipcode.php?DISTRICT_CODE='+u.value)
                 // Axios.get('http://localhost/phpapi/selectzipcode.php?DISTRICT_CODE='+u.value)
                 .then(res => {
               //    console.log(res);
                 //  this.setState({ bankname: res.data, loading: false })
                 //const data = res.data[0].zipcode
                //  const optionsds = data.map(a => ({
                //      "value" : a.DISTRICT_ID,
                //      "label" : a.DISTRICT_NAME
                //    }))
                   this.setState({ zipcode: res.data[0].zipcode})
                 }) 
               }, 0);
            }


              //จังหวัดข้อมูลอาชีพ
        handleprovincept(e,u){
            // console.log(u)
             this.setState({provincept:u.value, provinceptname:u.label})
             setTimeout(() => {
                 //จังหวัด
                  Axios.get('https://thenextth.com/registerfa/selectamphures.php?PROVINCE_ID='+u.value)
                 // Axios.get('http://localhost/phpapi/selectamphures.php?PROVINCE_ID='+u.value)
                 .then(res => {
               //    console.log(res);
                 //  this.setState({ bankname: res.data, loading: false })
                 const data = res.data
                 const optionspt = data.map(a => ({
                     "value" : a.AMPHUR_ID,
                     "label" : a.AMPHUR_NAME
                   }))
                   this.setState({ AMPHURPT: optionspt})
                 }) 
               }, 0);
            }

             //อำเภอข้อมูลอาชีพ
           handleamphurpt(e,t){
            // console.log(u)
             this.setState({amperpt:t.value, amperptname:t.label})
 
             setTimeout(() => {
                 //จังหวัด
                 
                 Axios.get('https://thenextth.com/registerfa/selectdistricts.php?AMPHUR_ID='+t.value)
                 // Axios.get('http://localhost/phpapi/selectdistricts.php?AMPHUR_ID='+t.value)
                 .then(res => {
                   console.log(res);
                 //  this.setState({ bankname: res.data, loading: false })
                const data = res.data
                 const optionsds = data.map(a => ({
                     "value" : a.DISTRICT_CODE,
                     "label" : a.DISTRICT_NAME
                   }))
                   this.setState({ DISTRICTPT: optionsds})
                 }) 
               }, 0);
            }

               //ตำบลข้อมูลอาชีพ
               handledistrictpt(e,u){
                // console.log(u)
                 this.setState({districtpt:u.value, districtptname:u.label})
     
                 setTimeout(() => {
                     //จังหวัด
                    
                     Axios.get(' https://thenextth.com/registerfa/selectzipcode.php?DISTRICT_CODE='+u.value)
                     // Axios.get('http://localhost/phpapi/selectzipcode.php?DISTRICT_CODE='+u.value)
                     .then(res => {
                      // console.log(res);
                     //  this.setState({ bankname: res.data, loading: false })
                     //const data = res.data[0].zipcode
                    //  const optionsds = data.map(a => ({
                    //      "value" : a.DISTRICT_ID,
                    //      "label" : a.DISTRICT_NAME
                    //    }))
                       this.setState({ zipcodept: res.data[0].zipcode})
                     }) 
                   }, 0);
                }

           
  
        checkbox1 = (e) => {
            this.setState({ checked1: e.target.checked })
           // console.log(`checked = ${e.target.checked}`);
        }
    

    submit = (event) => {
        event.preventDefault();

        const plann = this.state.plan.length
//     setTimeout(() => {
//      for (let i = 0; i < plann; i++) {

//             var datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
//             datafrm.append("name_in", this.state.name_in);
//             datafrm.append("tel_in", this.state.tel_in);
//             datafrm.append("planid_in", this.state.plan[i]);
          
//              Axios.post('http://thenextth.com/registerfa/insertplan.php', datafrm)
//            //http://localhost/phpapi/selectprovinces.php
//           //Axios.post('http://localhost/phpapi/insertplan.php', datafrm)
//             .then((res) => {
//                // this.props.history.push('/Successregister') //เมื่อบันทึกเสร็จจะทำการให้เปลี่ยนไปที่ path Customers
//             })
//             .catch((err) => { alert("ไม่สำเร็จ " + err) })
//            // const element = array[i];
            
//         }
// }, 1000);
       
        var datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
        datafrm.append("name_in", this.state.name_in);//2
        datafrm.append("bddate", this.state.bddate);//3
        datafrm.append("age_in", this.state.age_in);//4
        datafrm.append("gender_in", this.state.gender_in);//5
        datafrm.append("weight", this.state.weight);//6
        datafrm.append("height", this.state.height);//7
        datafrm.append("disease", this.state.disease);//8
        datafrm.append("otherdisease", this.state.otherdisease);//9
        datafrm.append("singlestatus", this.state.singlestatus);//10
        datafrm.append("othersinglestatus", this.state.othersinglestatus);//11
        datafrm.append("tel_in", this.state.tel_in);//12
        datafrm.append("referral", this.state.referral);//13
        datafrm.append("email_in", this.state.email_in);//14
        datafrm.append("address", this.state.address);//15
        datafrm.append("province", this.state.province);//16
        datafrm.append("provincename", this.state.provincename);//17
        datafrm.append("amper", this.state.amper);//18
        datafrm.append("ampername", this.state.ampername);//19
        datafrm.append("district", this.state.district);//20
        datafrm.append("districtname", this.state.districtname);//21
        datafrm.append("zipcode", this.state.zipcode);//22
        datafrm.append("historycam", this.state.historycam);//23
        datafrm.append("otherhistorycam", this.state.otherhistorycam);//24
        datafrm.append("covid1", this.state.covid1);
        datafrm.append("covid2", this.state.covid2);
        datafrm.append("covid3", this.state.covid3);
        datafrm.append("hospital", this.state.hospital);
        datafrm.append("occupation", this.state.occupation);//25
        datafrm.append("position", this.state.position);//26
        datafrm.append("work", this.state.work);//27
        datafrm.append("company", this.state.company);//28
        datafrm.append("income", this.state.income);//29
        datafrm.append("addresspt", this.state.addresspt);//30
        datafrm.append("provincept", this.state.provincept);//31
        datafrm.append("provinceptname", this.state.provinceptname);//32
        datafrm.append("amperpt", this.state.amperpt);//33
        datafrm.append("amperptname", this.state.amperptname);//34
        datafrm.append("districtpt", this.state.districtpt);//35
        datafrm.append("districtptname", this.state.districtptname);//36
        datafrm.append("zipcodept", this.state.zipcodept);//37
        datafrm.append("namereceive", this.state.namereceive);//38
        datafrm.append("relationshipreceive", this.state.relationshipreceive);//39
        datafrm.append("agereceive", this.state.agereceive);//40

        datafrm.append("avatar", this.state.filecid);//41
        datafrm.append("avatar2", this.state.filecid2);//42
      //  datafrm.append("name_plan", this.state.name_plan);
        

      
        if (this.state.update === false) { //เช็คว่าเป็นหน้าสำหรับอัพเดตหรือไม่
            Axios.post('https://thenextth.com/registerfa/insertinsurance.php', datafrm)
         // Axios.post('http://localhost/phpapi/insertinsurance.php', datafrm)
                .then((res) => {
                    this.props.history.push('/Successinsurance') //เมื่อบันทึกเสร็จจะทำการให้เปลี่ยนไปที่ path Customers
                })
                .catch((err) => { alert("ไม่สำเร็จ " + err) })

        }
        else {
            Axios.post('https://thenextth.com/registerfa/update.php', datafrm)
                .then((res) => {
                    this.props.history.push('/Customers') //เมื่อบันทึกเสร็จจะทำการให้เปลี่ยนไปที่ path Customers
                })
                .catch((err) => { alert("ไม่สำเร็จ " + err) }) // เมื่อเกิด error จะมาทำที่คำสั่งนี้แทน
        }

    

    }

    componentDidMount() {
        // let data = this.props.location.data
        // if (data) { // ถ้ามี data เข้ามาแสดงว่ามีการอัพเดต
        //     this.setState({ update: true })
        //     this.setState(data)
        // }

        setTimeout(() => {
            //ภายใน
             Axios.get('https://thenextth.com/registerfa/selectposition.php')
            .then(res => {
           //   console.log(res);
            //  this.setState({ bankname: res.data, loading: false })
              this.setState({ positionlist: res.data})
            }) 
          }, 0);

          setTimeout(() => {
            //ภายใน
             Axios.get('https://thenextth.com/registerfa/selectplan.php')
            .then(res => {
             // console.log(res);
            //  this.setState({ bankname: res.data, loading: false })
              this.setState({ planlist: res.data})
            }) 
          }, 0);

          setTimeout(() => {
            //จังหวัด
             Axios.get('https://thenextth.com/registerfa/selectprovinces.php')
            .then(res => {
            //  console.log(res);
            //  this.setState({ bankname: res.data, loading: false })
            const data = res.data
            const options = data.map(d => ({
                "value" : d.PROVINCE_ID,
                "label" : d.PROVINCE_NAME
              }))
              this.setState({ PROVINCE: options})
            }) 
          }, 0);
    
    }
    render() {

        // console.log(this.state.hospital)
        //  console.log(this.state.position) 
        //  console.log(this.state.work)
        //  console.log(this.state.company)
        //  console.log(this.state.income)
        //  console.log(this.state.addresspt)
        //  console.log(this.state.provincept)
        //  console.log(this.state.provinceptname)
        //  console.log(this.state.amperpt)
        //  console.log(this.state.amperptname)
        //  console.log(this.state.districtpt)
        //  console.log(this.state.districtptname)
        //  console.log(this.state.zipcodept)
        //  console.log(this.state.namereceive)
        //  console.log(this.state.relationshipreceive)
        //  console.log(this.state.agereceive)

        return (
            <div className='container'>
                <MDBCard>
                    <MDBCardBody>
                        <h5 className="text-center red-text">แบบฟอร์มสมัครประกันชีวิต</h5>
                        <p className="text-center red-text">กรุณากรอกข้อมูลเพื่อให้ทีมงานติดต่อกลับ</p>
                        <MDBBtn color="red darken-2">ข้อมูลส่วนตัว</MDBBtn><br/>
                        <form onSubmit={this.submit}>
                        {/* <form >  */}
                            <div className="mb-2">
                                <label class='control-label'>ชื่อ - นามสกุล</label><label className="red-text">*</label>
                                <input className="form-control" onChange={this.onTextChange} name="name_in"
                                    value={this.state.name_in} placeholder="กรุณาชื่อ - นามสกุล"  required />
                            </div>

                            <div className="mb-2">
                                <label>วัน เดือน ปี พ.ศ. เกิด</label><label className="red-text">*</label>
                                <input className="form-control" onChange={this.onTextChange} name="bddate"
                                    value={this.state.bddate} placeholder="กรุณวัน เดือน ปี พ.ศ. เกิด" required />
                            </div>

                            <div className="mb-2">
                                <label>อายุ</label><label className="red-text">*</label>
                                <input className="form-control" onChange={this.onTextChange} name="age_in"
                                    value={this.state.age_in} placeholder="กรุณาระบุ อายุ"  required />
                            </div> 
                            <div className="mb-2">
                                <label rules={[{ required: true }]}>เพศ</label><label className="red-text">*</label><br/>
                            
                                <Radio.Group onChange={this.gender} required={true} >
                                <Radio value="ชาย">ชาย</Radio>
                                <Radio value="หญิง">หญิง</Radio>
                               
                                </Radio.Group>
                            </div>
                            <div className="mb-2">
                                <label>น้ำหนัก</label><label className="red-text">*</label>
                                <input className="form-control" onChange={this.onTextChange} name="weight"
                                    value={this.state.weight} placeholder="กรุณาระบุ น้ำหนัก"  required />
                            </div> 
                            <div className="mb-2">
                                <label>ส่วนสูง</label><label className="red-text">*</label>
                                <input className="form-control" onChange={this.onTextChange} name="height"
                                    value={this.state.height} placeholder="กรุณาระบุ ส่วนสูง"  required />
                            </div> 
                            <div className="mb-2">
                                <label>มีโรคประจำตัวหรือไม่</label><label className="red-text">*</label><br/>
                                <Radio.Group onChange={this.disease} required >
                                    <Space direction="vertical">
                                    <Radio value={1}>มี</Radio>
                                    <Radio value={2}>ไม่มี</Radio>
                                   
                                    </Space>
                                </Radio.Group>
                                {this.state.disease === 1?<>
                                <br/>
                                <label>กรณีมีโรคประจำตัวโปรดระบุ</label>
                                <input className="form-control" onChange={this.onTextChange} name="otherdisease"
                                    value={this.state.otherdisease} placeholder="กรุณาระบุ โรคประจำตัว"  required />
                                </>:<></>}
                            </div> 
                            <div className="mb-2">
                                <label>สถานะภาพ</label><label className="red-text">*</label><br/>
                                <Radio.Group onChange={this.singlestatus} >
                                <Radio value="โสด">โสด</Radio>
                                <Radio value="สมรส">สมรส</Radio>
                                <Radio value="หย่า">หย่า</Radio>
                                <Radio value="หม้าย">หม้าย</Radio>
                                <Radio value="อื่นๆ">อื่นๆ ระบุ</Radio>
                                </Radio.Group>
                                {this.state.singlestatus === 'อื่นๆ'?<>
                                <br/>
                                <label>  ระบุ</label>
                                <input className="form-control" onChange={this.onTextChange} name="othersinglestatus"
                                    value={this.state.othersinglestatus} placeholder="กรุณาระบุอื่นๆ"   />
                                </>:<></>}
                            </div>

                            <div className="mb-2">
                                <label>เบอร์โทรมือถือ</label><label className="red-text">*</label>
                                <input  className="form-control" onChange={this.onTextChange} name="tel_in"
                                    value={this.state.tel_in} placeholder="กรุณาระบุ เบอร์โทร" required />
                            </div>

                            <div className="mb-2">
                                <label>ชื่อและเบอร์โทรบุคคลอื่นกรณีเกิดเหตุฉุกเฉิน</label><label className="red-text">*</label>
                                <input  className="form-control" onChange={this.onTextChange} name="referral"
                                    value={this.state.referral} placeholder="กรุณาระบุ เบอร์โทรบุคคลอื่นกรณีเกิดเหตุฉุกเฉิน" required />
                            </div>

                            <div className="mb-2">
                                <label>อีเมลล์</label>
                                <input className="form-control" onChange={this.onTextChange} name="email_in"
                                    value={this.state.email_in} placeholder="กรุณาระบุ อีเมลล์"  />
                            </div>

                            <div className="mb-2">
                                <label>ที่อยู่ในการจัดส่งเอกสาร</label><br/>
                                <label>ที่อยู่</label><label className="red-text">*</label>
                                <input  className="form-control" onChange={this.onTextChange} name="address"
                                value={this.state.address} placeholder="กรุณาระบุ ที่อยู่" required />

                                <label>จังหวัด</label><label className="red-text">*</label><br/>
                                <Select placeholder="กรุณาระบุ จังหวัด"  className="browser-default custom-select " options={this.state.PROVINCE}  onChange={this.handleprovince.bind(this)} /><br/>

                                <label>อำเภอ</label><label className="red-text">*</label>
                                <Select placeholder="กรุณาระบุ อำเภอ"  className="bbrowser-default custom-select " options={this.state.AMPHUR}  onChange={this.handleamphur.bind(this)} />

                                <label>ตำบล</label><label className="red-text">*</label>
                                <Select placeholder="กรุณาระบุ ตำบล"  className="bbrowser-default custom-select " options={this.state.DISTRICT}  onChange={this.handledistrict.bind(this)} />

                                <label>รหัสไปษณีย์</label><label className="red-text">*</label>
                                <input type="number" className="form-control" onChange={this.onTextChange} name="zipcode"
                                value={this.state.zipcode} placeholder="กรุณาระบุ รหัสไปษณีย์" required />
                            </div>

                            <div className="mb-2">
                                <label>เคยมีประกันชีวิตหรือไม่</label><label className="red-text">*</label><br/>
                                <Radio.Group onChange={this.historycam} required={true} >
                                    <Space direction="vertical">
                                    <Radio value={1}>มี</Radio>
                                    <Radio value={2}>ไม่มี</Radio>
                                    </Space>
                                </Radio.Group>
                                {/* <div className="form-control"  >
   <input  type="radio" name="optradio" />
   <label for="female-rb"> Female</label>
</div> */}

                                {this.state.historycam === 1 ?<>
                                <br/>
                                <label>กรณีมีประกันโปรดระบุ บริษัทและทุนประกันที่ทำไว้</label>
                                <input className="form-control" onChange={this.onTextChange} name="otherhistorycam"
                                    value={this.state.otherhistorycam} placeholder="กรุณาระบุ บริษัทและทุนประกัน"   />
                                </>:<></>}
                            </div> 

                            <div className="mb-2">
                                <label>เคยเป็นโควิดหรือไม่</label><label className="red-text">*</label><br/>
                                <Radio.Group onChange={this.covid1} required={true} >
                                    <Space direction="vertical">
                                    <Radio value={1}>เคย</Radio>
                                    <Radio value={2}>ไม่เคย</Radio>
                                    </Space>
                                </Radio.Group>
                                {this.state.covid1 === 1 ?<>
                                <br/>
                                <div className="mb-2">
                                <label>กรณีเคยเป็นโควิด เป็นในระดับใด</label><label className="red-text">*</label><br/>
                                <Radio.Group onChange={this.covid2} required={true} >
                                    <Space direction="vertical">
                                    <Radio value={1}>สีเขียว</Radio>
                                    <Radio value={2}>สีแดง</Radio>
                                    </Space>
                                </Radio.Group>
                                 </div> 

                                 <div className="mb-2">
                                <label>การเป็นโควิดมีอาการลงปอดหรือไม่</label><label className="red-text">*</label><br/>
                                <Radio.Group onChange={this.covid3} required={true} >
                                    <Space direction="vertical">
                                    <Radio value={1}>ลงปอด</Radio>
                                    <Radio value={2}>ไม่ลงปอด</Radio>
                                    </Space>
                                </Radio.Group>
                                 </div> 


                                <label>ชื่อโรงพยาบาลที่เข้ารับการรักษา</label>
                                <input className="form-control" onChange={this.onTextChange} name="hospital"
                                    value={this.state.hospital} placeholder="กรุณาระบุ ชื่อโรงพยาบาล"   />
                                </>:<></>}
                            </div> 

                           

                            <MDBBtn color="red darken-2">ข้อมูลอาชีพ</MDBBtn><br/>
                           
                            <div className="mb-2">
                                <label>อาชีพ</label><label className="red-text">*</label>
                                <input  className="form-control" onChange={this.onTextChange} name="occupation"
                                    value={this.state.occupation} placeholder="กรุณาระบุ อาชีพ" required />
                            </div>

                            <div className="mb-2">
                                <label>ตำแหน่ง</label><label className="red-text">*</label>
                                <input  className="form-control" onChange={this.onTextChange} name="position"
                                    value={this.state.position} placeholder="กรุณาระบุ ตำแหน่ง" required />
                            </div>

                            <div className="mb-2">
                                <label>ลักษณะงานที่ทำ</label><label className="red-text">*</label>
                                <input  className="form-control" onChange={this.onTextChange} name="work"
                                    value={this.state.work} placeholder="กรุณาระบุ ลักษณะงานที่ทำ" required />
                            </div>

                            <div className="mb-2">
                                <label>ชื่อบริษัทและที่ทำงาน</label><label className="red-text">*</label>
                                <input  className="form-control" onChange={this.onTextChange} name="company"
                                    value={this.state.company} placeholder="กรุณาระบุ ชื่อบริษัทและที่ทำงาน" required />
                            </div>

                            <div className="mb-2">
                                <label>รายได้ต่อปี</label><label className="red-text">*</label>
                                <input  className="form-control" onChange={this.onTextChange} name="income"
                                    value={this.state.income} placeholder="กรุณาระบุ รายได้ต่อปี" required />
                            </div>

                            <label>ที่อยู่ที่ทำงาน</label><br/>

                                <label>ที่อยู่</label><label className="red-text">*</label>
                                <input  className="form-control" onChange={this.onTextChange} name="addresspt"
                                value={this.state.addresspt} placeholder="กรุณาระบุ ที่อยู่" required />

                                <label>จังหวัด</label><label className="red-text">*</label>
                                <Select placeholder="กรุณาระบุ จังหวัด"  className="browser-default custom-select " options={this.state.PROVINCE}  onChange={this.handleprovincept.bind(this)} required/><br/>

                                <label>อำเภอ</label><label className="red-text">*</label>
                                <Select placeholder="กรุณาระบุ อำเภอ"  className="bbrowser-default custom-select " options={this.state.AMPHURPT}  onChange={this.handleamphurpt.bind(this)} required/>

                                <label>ตำบล</label><label className="red-text">*</label>
                                <Select placeholder="กรุณาระบุ ตำบล"  className="bbrowser-default custom-select " options={this.state.DISTRICTPT}  onChange={this.handledistrictpt.bind(this)} required/>

                                <label>รหัสไปษณีย์</label><label className="red-text">*</label>
                                <input  className="form-control" onChange={this.onTextChange} name="zipcodept"
                                value={this.state.zipcodept} placeholder="กรุณาระบุ รหัสไปษณีย์" required />



                                <MDBBtn color="red darken-2">ข้อมูลผู้รับผลประโยชน์</MDBBtn><br/>
                                <label>ชื่อ-นามสกุล ผู้รับผลประโยชน์</label><label className="red-text">*</label>
                                <input  className="form-control" onChange={this.onTextChange} name="namereceive"
                                value={this.state.namereceive} placeholder="กรุณาระบุ ผู้รับผลประโยชน์" required />

                                <label>ความสัมพันธ์ ผู้รับผลประโยชน์ (เช่น พ่อ แม่ พี่สาว พี่ชาย น้องสาว น้องชาย)</label><label className="red-text">*</label>
                                <input className="form-control" onChange={this.onTextChange} name="relationshipreceive"
                                value={this.state.relationshipreceive} placeholder="กรุณาระบุ ความสัมพันธ์" required />

                                <label>อายุ ผู้รับผลประโยชน์</label><label className="red-text">*</label>
                                <input  className="form-control" onChange={this.onTextChange} name="agereceive"
                                value={this.state.agereceive} placeholder="กรุณาระบุ อายุ" required />

                            <MDBBtn color="red darken-2">แนบเอกสารเพิ่มเติม</MDBBtn><br/>
                            <label>แนบไฟล์รูปบัตรประชาชน</label><label className="red-text">*</label>
                                {/* <input type="file" className="form-control" onChange={this.onTextChangefile1} 
                                value={this.state.filecid} placeholder="กรุณาระบุ เบอร์โทร" name="filecid" required /> */}
                               {/* <br/>  */}

                               {/* <input type="file" onChange={this.onTextChangefile1} accept="application/pdf" /> */}

                                <MDBInput  icon="file-upload" group   type="file" validate error="wrong" accept="image/jpeg"
                                        success="right"  name="filecid" value={this.state.onTextChangefile1}  onChange={this.onTextChangefile1} required/>
                            {/* <br/> */}
                            <label>แนบไฟล์ เพิ่มเติมอื่นๆ</label>
                                {/* <input type="file" className="form-control" onChange={this.onTextChangefile2} 
                                value={this.state.filecid2} placeholder="กรุณาระบุ เบอร์โทร" name="filecid2" required /> */}
                                <MDBInput  icon="file-upload" group   type="file" validate error="wrong" accept="image/jpeg"
                                        success="right"  name="filecid2" value={this.state.onTextChangefile2} onChange={this.onTextChangefile2} />
                                    {/* <br/>     */}
                                {/* <input type="file" onChange={this.onTextChangefile2} accept="application/pdf"  /> */}

                            <div className="mt-3 text-center">
                                <MDBBtn type="submit" color="red darken-2"> <MDBIcon far icon="save" /> ส่งข้อมูล</MDBBtn>
                                {/* <MDBBtn color="primary" onClick={this.submit} >บันทึก</MDBBtn> */}
                            </div>
                            </form>
                        
                    </MDBCardBody>

                    
                </MDBCard>
<br/>

               
            </div>
        )
    }
}

