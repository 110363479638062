import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBJumbotron,  MDBAlert,  MDBTable, MDBTableBody, MDBTableHead, MDBBtn, MDBIcon,  MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter} from "mdbreact";
import Axios from 'axios'
import { Layout, Menu, Breadcrumb, Icon, Select, Card  } from 'antd';
import { StarOutlined, StarFilled, StarTwoTone, CaretDownOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2/dist/sweetalert2.js'


export default class Listname2 extends Component {

    constructor(props) {
        super(props);
        this.state = {
          articlesci1:[],
          emp:[],
          term:'',
          empcouncil:[],
          modal: false,
          idExpert:'',
         
          idemp:'',
          citizen_id:'',
          employee_name:'',
          type:'2',
        };
        this.handleChange = this.handleChange.bind(this);

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getthcouncil = this.getthcouncil.bind(this);
      }


      componentDidMount() {

        setTimeout(() => {
           //ภายใน
            Axios.get('https://mis.kpru.ac.th/api/EmployeeInOrg/000')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ emp: res.data})
           }) 
         }, 0);

      }


      getthcouncil() {
        setTimeout(() => {
           //ภายใน
            Axios.get('https://git.kpru.ac.th/FrontEnd_Tabian/load/getthcouncil/2/'+this.state.term)
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ empcouncil: res.data})
           }) 
         }, 0);
      }


    updateSearchDepartment(event) {
       // console.log(event.target.value)
       const term = event.target.value;
       this.setState({ term });

       setTimeout(() => {
        this.getthcouncil()
      }, 500);
     }


     toggle = () => {
      this.setState({
        modal: !this.state.modal
      });
    }

    handleChange(event) {    this.setState({idExpert: event.target.value});  }

    
    onChange(value) {
        //  console.log(`selected ${value}`);
        Axios.get('https://mua.kpru.ac.th/FrontEnd_Mis/employee/procSEmpInfo/'+value)
        .then(res => {
         // console.log(res.data.procSEmpInfo[0].citizen_id);
        //  this.setState({ bankname: res.data, loading: false })
          this.setState({ citizen_id: res.data.procSEmpInfo[0].citizen_id})
          this.setState({ idemp: res.data.procSEmpInfo[0].employee_id})
          this.setState({ employee_name: res.data.procSEmpInfo[0].employee_name})
        })
    
         //  const idemp = value;
         //  this.setState({ idemp });
    }
    
     onBlur() {
      console.log('blur');
    }
    
     onFocus() {
      console.log('focus');
    }
    
     onSearch(val) {
      console.log('search:', val);
    }

    handleSubmit () {

        const dataadd = new FormData();
        dataadd.append("term", this.state.term);
        dataadd.append("idemp", this.state.idemp);
        dataadd.append("citizen_id", this.state.citizen_id);
        dataadd.append("employee_name", this.state.employee_name);
        dataadd.append("type", this.state.type);
      
        Axios.post('https://git.kpru.ac.th/FrontEnd_Tabian/load/Addthcouncil/',dataadd)
       
        .then(res => {
        //    console.log(res);
            if (res.status === 200) {
            //  alert("บันทึกสำเร็จ")
                      Swal.fire({
                          title: 'บันทึกข้อมูลสำเร็จ',
                          //text: 'มหาวิทยาลัยราชภัฏกำแพงเพชรขอขอบคุณ',
                          icon: 'success',
                        // showCancelButton: true,
                          confirmButtonText: 'ตกลง',
                        // cancelButtonText: 'No, keep it'
                        }).then((result) => {
                        
                        })
                     }
                })
             this.toggle()
             this.getthcouncil()
      }


    render() {
        console.log(this.state.citizen_id)
        const { Option } = Select;





        return (
        <MDBContainer fluid>
           <Card type="inner"  title={<h4><MDBIcon icon="grin-wink" /> รายชื่อ(รายชื่อสภามหาลัย)</h4>} >
            {/* <MDBAlert color="primary" >
            <h4><MDBIcon icon="address-book" className="indigo-text pr-3" /> จัดการรายชื่ออาจารย์กรรมการบริหาร</h4>
            </MDBAlert> */}
            
            <div>
            <CaretDownOutlined style={{ fontSize: '30px', color: '#0d47a1' }}  /> <select className="browser-default custom-select" style={{ width: '30%' }}   onChange={this.updateSearchDepartment.bind(this)}   icon="user" >
                <option>เลือกเทอม</option>
                <option value="3/2563">3/2563</option>
                <option value="1/2564">1/2564</option>
                <option value="2/2564">2/2564</option>
                <option value="3/2564">3/2564</option>
                <option value="1/2565">1/2565</option>
                <option value="2/2565">2/2565</option>
               
                </select>    
                
                 <br/>
            </div>
            <br/>
            { this.state.term === '' ? <></> :<> <MDBBtn onClick={this.toggle} color="primary"> <MDBIcon far icon="address-book" />  เพิ่มข้อมูล </MDBBtn> <h5 className="text-center">รายชื่ออาจารย์ในสภา เทอม {this.state.term}</h5> 
          
          {this.state.empcouncil === '' ? <>
          <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            </>: <>
            <MDBTable bordered>
                <MDBTableHead>
                    <tr>
                    <th>ลำดับ</th>
                    <th>เทอม</th>
                    <th>ชื่อ - สกุล</th>
                    <th>จัดการ</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                {this.state.empcouncil.map((empcouncil,index) => {
                  return <tr>
                          <td>{index+1}</td>
                          <td>{empcouncil.term}</td>
                          <td>{empcouncil.employee_name}</td>
                          <td>จัดการ</td>
                          </tr>
                })}   
                </MDBTableBody>
            </MDBTable>
            </> }
            {/* <p>หมายเหตุ : ข้อมูลใช้ประกอบการจัดตารางสอน ไม่ให้ชนกับการประชุม</p> */}
        </>}



            <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
              <MDBModalHeader toggle={this.toggle}>เพิ่มข้อมูลอาจารย์ในสภามหาวิทยาลัย เทอม {this.state.term} </MDBModalHeader>
              <MDBModalBody>
            
                  <Select
                    showSearch
                    style={{ width: 300 }}
                    placeholder="เลือกอาจารย์"
                    optionFilterProp="children"
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {/* <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="tom">Tom</Option> */}
                   
                   {this.state.emp.map((emp,index )  => 
                   <option value={emp.employee_id}>{emp.employee_name}</option>
                   )}
                  </Select>
                  <MDBBtn color="dark-green" size="sm" onClick={this.handleSubmit}>เพิ่มอาจารย์</MDBBtn>

              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn>
                {/* <MDBBtn color="dark-green">Save changes</MDBBtn> */}
              </MDBModalFooter>
            </MDBModal>


            </Card>
        </MDBContainer>
        
        )
    }
}
