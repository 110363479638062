import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
    MDBCarouselInner,  MDBCarouselItem,  MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink, } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable, } from 'mdbreact';

import { api } from '../../api';

export default class Showfileme extends Component {
    constructor(props) {
        super(props);
        this.state = {
          emp:[],
          news:[],
          procurement:[],
          ita:[],
          activeItem: "1",
        };
       this.getnews = this.getnews.bind(this);
       this.procurement = this.procurement.bind(this);
       this.ita = this.ita.bind(this);
       this.delete = this.delete.bind(this);
       this.toggle = this.toggle.bind(this);
      }

      componentDidMount() {
      this.getnews();
      this.procurement();
      this.ita();
       
      }

      toggle = tab => e => {
        if (this.state.activeItem !== tab) {
          this.setState({
            activeItem: tab
          });
        }};

      getnews() {
        setTimeout(() => {
           //ภายใน
           api.get('selectnews.php')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ news: res.data})
           }) 
         }, 0);
      }

      procurement() {
        setTimeout(() => {
           //ภายใน
           api.get('procurement.php')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ procurement: res.data})
           }) 
         }, 0);
      }

      ita() {
        setTimeout(() => {
           //ภายใน
           api.get('ita.php')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ ita: res.data})
           }) 
         }, 0);
      }



      delete = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
        api.get('deletenews.php?id=' + id)
            .then((res) => {
                this.getnews()
            })
      }


  render() {
    console.log(this.state.news)

    const data = {
      columns: [
        {
          label: 'ลำดับ',
          field: 'list',
          sort: 'list',
          width: 150
        },
        {
          label: 'ชื่อรายการ',
          field: 'NAME',
          sort: 'NAME',
          width: 200
        },
        {
          label: 'จัดการ',
          field: 'FACULTY',
          sort: 'FACULTY',
          width: 100
        }

      ],

      rows: [...this.state.news.map((data, i) => (
        {
           list: <>{i+1}</>,
           NAME: <>{data.news_name}</>,
           FACULTY: <> <MDBBtn color="secondary" size="sm" href={`http://kosamphi.kpo.go.th/kosamphigo/kosamphibackend/uploadsdesing/${data.news_file}`}  target="_blank">DOWNLOAD</MDBBtn>    </>,
        //    FACULTY: <> <MDBBtn size="sm" color="deep-orange" onClick={() => this.delete(data.news_id)} ><MDBIcon far icon="trash-alt" /> ลบ</MDBBtn></>,
          

          //  random: <h5 searchvalue={data.Curriculumopen_TEAM} >tourist <span className="badge badge-danger">narrow</span></h5>,

        }
       ))] 
  
  };

  const persons = <MDBDataTable
  // searching ={true}
   searchLabel="ค้นหา รายการ"
   striped
   bordered
   small 
   entries={5}
  // entriesOptions={["10", "20"]}
   order={["age", "desc"]}
   data={data}
   entriesLabel="จำนวนที่แสดง "
   infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}
   paginationLabel={["ย้อนกลับ", "ถัดไป"]}
   responsive 
   sortRows={['random']}
/>


const dataprocurement = {
  columns: [
    {
      label: 'ลำดับ',
      field: 'list',
      sort: 'list',
      width: 150
    },
    {
      label: 'ชื่อรายการ',
      field: 'NAME',
      sort: 'NAME',
      width: 200
    },
    {
      label: 'จัดการ',
      field: 'FACULTY',
      sort: 'FACULTY',
      width: 100
    }

  ],

  rows: [...this.state.procurement.map((data, i) => (
    {
       list: <>{i+1}</>,
       NAME: <>{data.procurement_name}</>,
       FACULTY: <> <MDBBtn color="secondary" size="sm" href={`http://kosamphi.kpo.go.th/kosamphigo/kosamphibackend/uploadsprocurement/${data.procurement_file}`}  target="_blank">DOWNLOAD</MDBBtn> </>,
    //    FACULTY: <> <MDBBtn size="sm" color="deep-orange" onClick={() => this.delete(data.news_id)} ><MDBIcon far icon="trash-alt" /> ลบ</MDBBtn></>,
      

      //  random: <h5 searchvalue={data.Curriculumopen_TEAM} >tourist <span className="badge badge-danger">narrow</span></h5>,

    }
   ))] 

};

const personsprocurement = <MDBDataTable
// searching ={true}
searchLabel="ค้นหา รายการ"
striped
bordered
small 
entries={5}
// entriesOptions={["10", "20"]}
order={["age", "desc"]}
data={dataprocurement}
entriesLabel="จำนวนที่แสดง "
infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}
paginationLabel={["ย้อนกลับ", "ถัดไป"]}
responsive 
sortRows={['random']}
/>

const dataita = {
  columns: [
    {
      label: 'ลำดับ',
      field: 'list',
      sort: 'list',
      width: 150
    },
    {
      label: 'ชื่อรายการ',
      field: 'NAME',
      sort: 'NAME',
      width: 200
    },
    {
      label: 'จัดการ',
      field: 'FACULTY',
      sort: 'FACULTY',
      width: 100
    }

  ],

  rows: [...this.state.ita.map((data, i) => (
    {
       list: <>{i+1}</>,
       NAME: <>{data.procurement_name}</>,
       FACULTY: <> <MDBBtn color="secondary" size="sm" href={`http://kosamphi.kpo.go.th/kosamphigo/kosamphibackend/uploadsprocurement/${data.procurement_file}`}  target="_blank">DOWNLOAD</MDBBtn></>,
    //    FACULTY: <> <MDBBtn size="sm" color="deep-orange" onClick={() => this.delete(data.news_id)} ><MDBIcon far icon="trash-alt" /> ลบ</MDBBtn></>,
      

      //  random: <h5 searchvalue={data.Curriculumopen_TEAM} >tourist <span className="badge badge-danger">narrow</span></h5>,

    }
   ))] 

};

const personita = <MDBDataTable
// searching ={true}
searchLabel="ค้นหา รายการ"
striped
bordered
small 
entries={5}
// entriesOptions={["10", "20"]}
order={["age", "desc"]}
data={dataita}
entriesLabel="จำนวนที่แสดง "
infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}
paginationLabel={["ย้อนกลับ", "ถัดไป"]}
responsive 
sortRows={['random']}
/>

    return (
     
      <MDBContainer>
       
        <MDBNav className="nav-tabs mt-1">
          <MDBNavItem>
            <MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={this.toggle("1")} role="tab" >
              ข่าวประชาสัมพันธ์
            </MDBNavLink>
          </MDBNavItem>
          <MDBNavItem>
            <MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this.toggle("2")} role="tab" >
             จัดซื้อจัดจ้าง
            </MDBNavLink>
          </MDBNavItem>
          {/* <MDBNavItem>
            <MDBNavLink link to="#" active={this.state.activeItem === "3"} onClick={this.toggle("3")} role="tab" >
              ข่าวรับสมัครงาน
            </MDBNavLink>
          </MDBNavItem> */}
          <MDBNavItem>
            <MDBNavLink link to="#" active={this.state.activeItem === "4"} onClick={this.toggle("4")} role="tab" >
              ITA
            </MDBNavLink>
          </MDBNavItem>
        </MDBNav>
        <MDBTabContent activeItem={this.state.activeItem} >
          <MDBTabPane tabId="1" role="tabpanel">
          {persons}
          </MDBTabPane>
          <MDBTabPane tabId="2" role="tabpanel">
           {personsprocurement}
          </MDBTabPane>
          {/* <MDBTabPane tabId="3" role="tabpanel">
            <p className="mt-2">
              Quisquam aperiam, pariatur. Tempora, placeat ratione porro
              voluptate odit minima. Lorem ipsum dolor sit amet,
              consectetur adipisicing elit. Nihil odit magnam minima,
              soluta doloribus reiciendis molestiae placeat unde eos
              molestias.
            </p>
          </MDBTabPane> */}
          <MDBTabPane tabId="4" role="tabpanel">
           {personita}
          </MDBTabPane>
        </MDBTabContent>

       
      </MDBContainer>
    )
  }
}
