import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
  MDBCarouselInner, MDBCarouselItem, MDBAlert  } from 'mdbreact';
export default class Kmroad extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            nann:"ss",
            KM :0,

        };
        this.onCloseddd = this.onCloseddd.bind(this);
       // this.onClose = this.onClose.bind(this);
       // this.onCloseddd = this.onCloseddd.bind(this);
    }

     //คำนวนทาง
// onCloseddd = () => {
//     const unit = 'kilometers'
//     const theta = this.state.longitude1 - this.state.longitude2;
//     const distance = 60 * 1.1515 * (180/Math.PI) * Math.acos(
//         Math.sin(this.state.latitude1 * (Math.PI/180)) * Math.sin(this.state.latitude2 * (Math.PI/180)) + 
//         Math.cos(this.state.latitude1 * (Math.PI/180)) * Math.cos(this.state.latitude2 * (Math.PI/180)) * Math.cos(theta * (Math.PI/180))
//     );
//     if (unit == 'miles') {
//       console.log(Math.round(distance, 2))
//         return Math.round(distance, 2);
        
        
//     } else if (unit == 'kilometers') {
//        console.log(distance * 1.609344, 2)
//         return Math.round(distance * 1.609344, 2);
       
        
//     }
//     };


 //คำนวนทาง
 onCloseddd = () => {
    const unit = 'kilometers'
    const theta = this.props.longitude1 - this.props.longitude2;
    const distance = 60 * 1.1515 * (180/Math.PI) * Math.acos(
        Math.sin(this.props.latitude1 * (Math.PI/180)) * Math.sin(this.props.latitude2 * (Math.PI/180)) + 
        Math.cos(this.props.latitude1 * (Math.PI/180)) * Math.cos(this.props.latitude2 * (Math.PI/180)) * Math.cos(theta * (Math.PI/180))
    );
    if (unit === 'miles') {
      console.log(Math.round(distance, 2))
        return Math.round(distance, 2);
        
        
    } else if (unit === 'kilometers') {
       console.log(distance * 1.609344, 2)
       const ss = (distance * 1.609344, 2)
       this.setState({
        KM:Math.round(distance * 1.609344, 4),
      });
        return Math.round(distance * 1.609344, 2);
       
        
    }
    };
  
    componentDidMount (){
     
  
  this.onCloseddd();
    }
  

  render() {


    return (
      <MDBContainer>
      <div className='mt-1'>
          <MDBAlert color="success" >
      
     
        {/* sdsds{this.props.latitude1}, {this.props.longitude1}, {this.props.latitude2}, {this.props.longitude2} <br/> */}
       <h5><MDBIcon icon="car-side" /> รถสไลด์ {this.props.namecar} อยู่ใกล้คุณ {this.state.KM} กิโลเมตร <a href="tel:0650961963"><MDBIcon icon="phone-volume" /> โทร</a></h5> 
 </MDBAlert>
        </div>
        </MDBContainer>
    )
  }
}
