import React, { Component } from 'react'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBAlert, MDBSelect , MDBInput, MDBIcon  } from 'mdbreact';
import { MDBSelectInput, MDBSelectOptions, MDBSelectOption } from "mdbreact";
import Axios from 'axios'
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip  } from 'antd';

const { Option } = Select;
function onChange(value) {
  console.log(`selected ${value}`);
}

function onBlur() {
  console.log('blur');
}

function onFocus() {
  console.log('focus');
}

function onSearch(val) {
  console.log('search:', val);
}

export default class Modaleditstatus extends Component {
    constructor(props) {
        super(props);
this.state = {
   modal: false,
   editstatus:'',
   id_send:'',
}


this.handleSubmit = this.handleSubmit.bind(this);
this.toggle = this.toggle.bind(this);

this.handleChangeedit = this.handleChangeedit.bind(this);
}


toggle = () => {
  this.setState({
    modal: !this.state.modal
  });
 // this.props.selectatf();
}

handleChangeedit(event) {  this.setState({editstatus: event});  }


 componentDidMount() {
      const { id_send } = this.props.id_send
      console.log(id_send);
      this.setState({ id_send:this.props.id_send})
  }


  handleSubmit() {
    // alert('A name was submitted: ' + this.state.value);
   setTimeout(() => {

      const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
      datafrm.append("idsend", this.props.id_send);
      datafrm.append("editstatus", this.state.editstatus);
     

  Axios.post("https://thenextth.com/registerfa/updatestatusregister.php",datafrm,{
    headers: {
        'content-type': 'multipart/form-data'
    }
   })

   .then(res => {
   // console.warn(res);
     console.log("response: ", res)
     if (res.status === 200) {
      // this.showitem = true;
       console.log(res)
       console.log(res.data.length) 
       message.error("แก้ไขสำเร็จ")
       window.location.href = 'https://thenextth.com/aia/selectregister'
    }else{
     // window.location.href = '/'
    }
  })
}, 500);

  }

  

    render() {
        console.log(this.state.editstatus)
        return (
            <>
               
            <MDBBtn size="sm" color="primary" onClick={this.toggle}><MDBIcon far icon="edit" />จัดการสถานะ</MDBBtn>

            <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
              <MDBModalHeader toggle={this.toggle}>แก้ไขสถานะผู้สมัคร</MDBModalHeader>
              <MDBModalBody>
              <div>

              <Select
                                required
                                        showSearch
                                      // style={{ width: 500}}
                                        style={{ width: "100%" }}
                                        placeholder="เลือกสถานะ"
                                        optionFilterProp="children"
                                        onChange={this.handleChangeedit}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                    >
                                        <Option value="2">ผ่าน</Option>
                                        <Option value="3">ไม่ผ่าน</Option>
                                        
                                    </Select>

              </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn>
                <MDBBtn color="primary" onClick={this.handleSubmit} >บันทึก</MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          </>
          
        )
    }
}
