import React, { Component } from 'react'
import { MDBContainer,  MDBJumbotron,  MDBAlert,  MDBTable, MDBTableBody, MDBTableHead, MDBBtn, MDBIcon,  
    MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBDataTable, MDBInput, MDBTooltip } from "mdbreact";
import Axios from 'axios'
import { Layout, Menu, Breadcrumb, Icon, Select, Card  } from 'antd';
import { StarOutlined, StarFilled, StarTwoTone, CaretDownOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {  MDBRow, MDBCol } from "mdbreact";
import Modalsentedit from './Modalsentedit';


export default class Classroom extends Component {

    constructor(props) {
        super(props);
        this.state = {
          articlesci1:[],
          emp:[],
          term:'',
          empcouncil:[],
          classroom:[],
          modal: false,
          idExpert:'',
         
          idemp:'',
          citizen_id:'',
          employee_name:'',
          type:'2',
          search: '',
          research: [],
          loading:false,
        };
        this.handleChange = this.handleChange.bind(this);

        this.onChange = this.onChange.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getthcouncil = this.getthcouncil.bind(this);
        this.getclassroom = this.getclassroom.bind(this);
        this.updateSearch = this.updateSearch.bind(this);
      }


      componentDidMount() {
        this.setState({ loading: true})
        setTimeout(() => {
           //ภายใน
            Axios.get('https://git.kpru.ac.th/FrontEnd_Tabian/load/getclassroom')
              // Axios.get('https://research.kpru.ac.th/Api_Research/index/research_search')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ classroom: res.data})
             this.setState({ loading: false})
           }) 
         }, 0);

      }

      getclassroom() {

        setTimeout(() => {
           //ภายใน
            Axios.get('https://git.kpru.ac.th/FrontEnd_Tabian/load/getclassroom')
              // Axios.get('https://research.kpru.ac.th/Api_Research/index/research_search')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ classroom: res.data})
           
           }) 
         }, 0);

      }


      getthcouncil() {
        setTimeout(() => {
           //ภายใน
            Axios.get('https://git.kpru.ac.th/FrontEnd_Tabian/load/getthcouncil/2/'+this.state.term)
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ empcouncil: res.data})
           }) 
         }, 0);
      }

      updateSearch(event) {
        this.setState({ search: event.target.value.substr(0, 20) });
      
    }


    updateSearchDepartment(event) {
       // console.log(event.target.value)
       const term = event.target.value;
       this.setState({ term });

       setTimeout(() => {
        this.getthcouncil()
      }, 500);
     }


     toggle = () => {
      this.setState({
        modal: !this.state.modal
      });
    }

    handleChange(event) {    this.setState({idExpert: event.target.value});  }

    
    onChange(value) {
        //  console.log(`selected ${value}`);
        Axios.get('https://mua.kpru.ac.th/FrontEnd_Mis/employee/procSEmpInfo/'+value)
        .then(res => {
         // console.log(res.data.procSEmpInfo[0].citizen_id);
        //  this.setState({ bankname: res.data, loading: false })
          this.setState({ citizen_id: res.data.procSEmpInfo[0].citizen_id})
          this.setState({ idemp: res.data.procSEmpInfo[0].employee_id})
          this.setState({ employee_name: res.data.procSEmpInfo[0].employee_name})
        })
    
         //  const idemp = value;
         //  this.setState({ idemp });
    }
    
     onBlur() {
      console.log('blur');
    }
    
     onFocus() {
      console.log('focus');
    }
    
     onSearch(val) {
      console.log('search:', val);
    }

    handleSubmit () {

        const dataadd = new FormData();
        dataadd.append("term", this.state.term);
        dataadd.append("idemp", this.state.idemp);
        dataadd.append("citizen_id", this.state.citizen_id);
        dataadd.append("employee_name", this.state.employee_name);
        dataadd.append("type", this.state.type);
      
        Axios.post('https://git.kpru.ac.th/FrontEnd_Tabian/load/Addthcouncil/',dataadd)
       
        .then(res => {
        //    console.log(res);
            if (res.status === 200) {
            //  alert("บันทึกสำเร็จ")
                      Swal.fire({
                          title: 'บันทึกข้อมูลสำเร็จ',
                          //text: 'มหาวิทยาลัยราชภัฏกำแพงเพชรขอขอบคุณ',
                          icon: 'success',
                        // showCancelButton: true,
                          confirmButtonText: 'ตกลง',
                        // cancelButtonText: 'No, keep it'
                        }).then((result) => {
                        
                        })
                     }
                })
             this.toggle()
             this.getthcouncil()

      }



    render() {
        console.log(this.state.classroom)
        const { Option } = Select;
        

        let filteredPage = this.state.classroom.filter(
            (classroom) => {
              return (classroom.id_room.indexOf(this.state.search) !== -1) 
            }
          )


       
          


        //   const persons = !data ?  <h2>loading...</h2>:<MDBDataTable
        //     searching ={false}
        //     striped
        //     bordered
        //     hover
        //     data={data}
        //     entriesLabel="จำนวนที่แสดง "
        //     infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}

        //     responsive 
        //     />


        return (
        <MDBContainer fluid>
          <Card type="inner"  title={<h4><MDBIcon icon="person-booth" /> จัดการห้องเรียน</h4>} >
            {/* <MDBAlert color="primary" >
            <h4><MDBIcon icon="address-book" className="indigo-text pr-3" /> จัดการห้องเรียน</h4>
            </MDBAlert> */}
            
            {/* <div>
            <CaretDownOutlined style={{ fontSize: '30px', color: '#0d47a1' }}  /> <select className="browser-default custom-select" style={{ width: '30%' }}   onChange={this.updateSearchDepartment.bind(this)}   icon="user" >
                <option>เลือกเทอม</option>
                <option value="3/2563">3/2563</option>
                <option value="1/2564">1/2564</option>
                <option value="2/2564">2/2564</option>
                <option value="3/2564">3/2564</option>
                <option value="1/2565">1/2565</option>
                <option value="2/2565">2/2565</option>
               
                </select>    
                
                 <br/>
            </div> */}
            <br/>

         
             {/* <h5 className="text-center">ข้อมูลห้องเรียน</h5>  */}
          
          {this.state.loading === true ? <>
            <MDBRow>
        <MDBCol></MDBCol>
        <MDBCol className="text-center">
            <div className="spinner-border text-primary" role="status">
            <span className="sr-only text-center">Loading...</span>
            </div>
        </MDBCol>
        <MDBCol></MDBCol>
      </MDBRow>
            
            </>: <>

            
            <MDBRow>
                <MDBCol size="6"> <MDBInput className="d-flex justify-content-end" label="ค้นหาห้อง"  style={{ width: '50%' }}  onChange={this.updateSearch.bind(this)} icon="user"  /> </MDBCol>
                <MDBCol size="6" className="text-right"> <MDBBtn onClick={this.toggle} color="primary" className="text-right"> <MDBIcon far icon="address-book" />  เพิ่มข้อมูล </MDBBtn></MDBCol>
              
            </MDBRow>
            
            <MDBTable bordered small>
                <MDBTableHead color="primary-color" textWhite>
                    <tr>
                    <th>ลำดับ</th>
                    <th>รหัสห้อง</th>
                    <th>ชื่อห้อง</th>
                    <th>จำนวนที่นั่ง</th>
                    <th>จัดการ</th>
                    </tr>
                </MDBTableHead>
                <MDBTableBody>
                {filteredPage.map((classroom,index) => {
                  return <tr>
                          <td>{index+1}</td>
                          <td>{classroom.id_room}</td>
                          <td>{classroom.name_room}</td>
                          <td>{classroom.amount}</td>
                          <td width="50">
                               <Modalsentedit id_send={classroom.id_room} name_room={classroom.name_room} amount={classroom.amount} getclassroom ={this.getclassroom}/> 
                          {/* <MDBBtn color="deep-purple" size="sm">แก้ไข</MDBBtn> */}
                          </td>
                          </tr>
                })}   
                </MDBTableBody>
            </MDBTable>
            </> }
            {/* <p>หมายเหตุ : ข้อมูลใช้ประกอบการจัดตารางสอน ไม่ให้ชนกับการประชุม</p> */}
     
            <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
              <MDBModalHeader toggle={this.toggle}>เพิ่มข้อมูลห้องเรียน </MDBModalHeader>
              <MDBModalBody>
            
                  <Select
                    showSearch
                    style={{ width: 300 }}
                    placeholder="เพิ่มข้อมูลห้อง"
                    optionFilterProp="children"
                    onChange={this.onChange}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {/* <Option value="jack">Jack</Option>
                    <Option value="lucy">Lucy</Option>
                    <Option value="tom">Tom</Option> */}
                   
                   {this.state.emp.map((emp,index )  => 
                   <option value={emp.employee_id}>{emp.employee_name}</option>
                   )}
                  </Select>
                  <MDBBtn color="dark-green" size="sm" onClick={this.handleSubmit}>เพิ่มห้องเรียน</MDBBtn>

              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn>
                {/* <MDBBtn color="dark-green">Save changes</MDBBtn> */}
              </MDBModalFooter>
            </MDBModal>
            </Card>
        </MDBContainer>
        
        )
    }
}
