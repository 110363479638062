import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';
import Modaladdfile from './Modaladdfile';
import { api } from '../../api';

export default class Addfileme extends Component {
    constructor(props) {
        super(props);
        this.state = {
          emp:[],
          news:[],
        };
       this.getnews = this.getnews.bind(this);
       this.delete = this.delete.bind(this);
       
      }

      componentDidMount() {
      this.getnews();
       
      }

      getnews() {
        setTimeout(() => {
           //ภายใน
           api.get('selectnews.php')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ news: res.data})
           }) 
         }, 0);
      }

      delete = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
        api.get('deletenews.php?id=' + id)
            .then((res) => {
                this.getnews()
            })
      }


  render() {
    console.log(this.state.news)

    const data = {
      columns: [
        {
          label: 'ลำดับ',
          field: 'list',
          sort: 'list',
          width: 150
        },
        {
          label: 'ชื่อรายการ',
          field: 'NAME',
          sort: 'NAME',
          width: 200
        },
         {
          label: 'วันที่เพิ่ม',
          field: 'DATES',
          sort: 'DATES',
          width: 200
        },
        {
          label: 'จัดการ',
          field: 'FACULTY',
          sort: 'FACULTY',
          width: 100
        }

      ],

      rows: [...this.state.news.map((data, i) => (
        {
           list: <>{i+1}</>,
           NAME: <>{data.news_name}</>,
           DATES: <>{data.news_datesent}</>,
           FACULTY: <> <MDBBtn size="sm" color="deep-orange" onClick={() => this.delete(data.news_id)} ><MDBIcon far icon="trash-alt" /> ลบ</MDBBtn></>,
          

          //  random: <h5 searchvalue={data.Curriculumopen_TEAM} >tourist <span className="badge badge-danger">narrow</span></h5>,

        }
       ))] 
  
  };

  const persons = <MDBDataTable
  // searching ={true}
   searchLabel="ค้นหา รายการ"
   striped
   bordered
   small 
   entries={20}
  // entriesOptions={["10", "20"]}
   order={["age", "desc"]}
   data={data}
   entriesLabel="จำนวนที่แสดง "
   infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}
   paginationLabel={["ย้อนกลับ", "ถัดไป"]}
   responsive 
   sortRows={['random']}
/>

    return (
     
      <MDBContainer>
        <br/>
        <h2 className='text-center'>ระบบจัดการไฟล์ประชาสัมพันธ์</h2>
        <h4 className='text-center'>ผู้ใช้งาน : {localStorage.getItem('name_member')}</h4>
      <p className='text-center'><Modaladdfile getnews={this.getnews}/></p> 
    
         {persons}
      </MDBContainer>
    )
  }
}
