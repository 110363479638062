import React, { Component } from 'react'
import Axios from 'axios';
import { MDBBtn, MDBIcon, MDBCard, MDBCardBody } from 'mdbreact';
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip     } from 'antd';
import Iframe from 'react-iframe'
import { Tabs, Checkbox } from 'antd';
import {    Divider,  Row, Col } from 'antd';

const { TabPane } = Tabs;

const { Option } = Select;
function onChange(value) {
  console.log(`selected ${value}`);
}

function onBlur() {
  console.log('blur');
}

function onFocus() {
  console.log('focus');
}

function onSearch(val) {
  console.log('search:', val);
}

export default class Addinsurance extends Component {

    constructor(props) {
        super(props)
        this.state = {
            id: '',
            name_in: '',
            age_in: '',
            tel_in: '',
            email_in: '',
            gender_in: '',
           
            update: false,
            positionlist:[],
            planlist:[],
            checked1:'',
            plan:[],
            name_plan:[],
            name_plan2:'',
        }
        
        this.handleChange = this.handleChange.bind(this)
        this.handleChange2 = this.handleChange2.bind(this)
        this.handleChange3 = this.handleChange3.bind(this)
        this.onChangebox = this.onChangebox.bind(this)
        this.onTextChange = this.onTextChange.bind(this)
        this.checkbox1 = this.checkbox1.bind(this)
      }


    onTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleChange(event) {    this.setState({Educational_register: event});  }
    handleChange2(event) {   this.setState({position_register: event});  }
    handleChange3(event) {    this.setState({gender_in: event});  }


    onChangebox(event) {
        // const joined = this.state.myArray.concat(event);
        // this.setState({ myArray: joined })
         this.setState({ plan : event })
      //console.log(event)
      setTimeout(() => {
         Axios.get('https://thenextth.com/registerfa/selectplanid.php?id_plan='+event)
         .then(res => {
         // console.log(res.data[0].name_position);
         //  this.setState({ bankname: res.data, loading: false })
          this.setState({ name_plan: res.data[0].name_plan})
          
          var joined = this.state.name_plan.concat(res.data[0].name_plan);
          this.setState({ name_plan: joined })

         }) 

       
         
       //  console.log(event[1])


      }, 1000);
     

     


        }
  

        checkbox1 = (e) => {
            this.setState({ checked1: e.target.checked })
           // console.log(`checked = ${e.target.checked}`);
        }
    

    submit = (event) => {
        event.preventDefault();

        const plann = this.state.plan.length
setTimeout(() => {
     for (let i = 0; i < plann; i++) {

            var datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
            datafrm.append("name_in", this.state.name_in);
            datafrm.append("tel_in", this.state.tel_in);
            datafrm.append("planid_in", this.state.plan[i]);
          
             Axios.post('https://thenextth.com/registerfa/insertplan.php', datafrm)
            //Axios.post('http://localhost/phpapi/insertplan.php', datafrm)
         
            .then((res) => {
               // this.props.history.push('/Successregister') //เมื่อบันทึกเสร็จจะทำการให้เปลี่ยนไปที่ path Customers
            })
            .catch((err) => { alert("ไม่สำเร็จ " + err) })
           // const element = array[i];
            
        }
}, 1000);
       
        var datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
        datafrm.append("name_in", this.state.name_in);
        datafrm.append("age_in", this.state.age_in);
        datafrm.append("tel_in", this.state.tel_in);
        datafrm.append("email_in", this.state.email_in);
        datafrm.append("gender_in", this.state.gender_in);
        datafrm.append("name_plan", this.state.name_plan);
        if (this.state.update === false) { //เช็คว่าเป็นหน้าสำหรับอัพเดตหรือไม่
            Axios.post('https://thenextth.com/registerfa/insertinsurance.php', datafrm)
                .then((res) => {
                    this.props.history.push('/Successinsurance') //เมื่อบันทึกเสร็จจะทำการให้เปลี่ยนไปที่ path Customers
                })
                .catch((err) => { alert("ไม่สำเร็จ " + err) })

        }
        else {
            Axios.post('https://thenextth.com/registerfa/update.php', datafrm)
                .then((res) => {
                    this.props.history.push('/Customers') //เมื่อบันทึกเสร็จจะทำการให้เปลี่ยนไปที่ path Customers
                })
                .catch((err) => { alert("ไม่สำเร็จ " + err) }) // เมื่อเกิด error จะมาทำที่คำสั่งนี้แทน
        }

    

    }

    componentDidMount() {
        // let data = this.props.location.data
        // if (data) { // ถ้ามี data เข้ามาแสดงว่ามีการอัพเดต
        //     this.setState({ update: true })
        //     this.setState(data)
        // }

        setTimeout(() => {
            //ภายใน
             Axios.get('https://thenextth.com/registerfa/selectposition.php')
            .then(res => {
              console.log(res);
            //  this.setState({ bankname: res.data, loading: false })
              this.setState({ positionlist: res.data})
            }) 
          }, 0);

          setTimeout(() => {
            //ภายใน
             Axios.get('https://thenextth.com/registerfa/selectplan.php')
            .then(res => {
              console.log(res);
            //  this.setState({ bankname: res.data, loading: false })
              this.setState({ planlist: res.data})
            }) 
          }, 0);
    
    }
    render() {

         console.log(this.state.name_plan)
        // console.log(this.state.name_plan2) 
        // console.log(this.state.age_in)
        // console.log(this.state.tel_in)
        // console.log(this.state.email_in)
        // console.log(this.state.gender_in)
        // console.log(this.state.position_register)
        // console.log(this.state.Income_register)
    //   console.log(this.state.plan[0])

        return (
            <div className='container mt-5'>
                {/* <MDBCard>
                    <MDBCardBody> */}
                        <h5 className="text-center red-text">สนใจออกแบบประกันที่เหมาะสมกับคุณ</h5>
                        <p className="text-center red-text">กรุณากรอกข้อมูลเพื่อให้ทีมงานติดต่อกลับ</p>
                        <form onSubmit={this.submit}>
                            <div className="mb-2">
                                <label>ชื่อ - นามสกุล</label>
                                <input className="form-control" onChange={this.onTextChange} name="name_in"
                                    value={this.state.name_in} placeholder="ชื่อ - นามสกุล" required />
                            </div>

                            <div className="mb-2">
                                <label>อายุ</label>
                                <input className="form-control" onChange={this.onTextChange} name="age_in"
                                    value={this.state.age_in} placeholder="กรุณาระบุอายุ"  required />
                            </div>

                            <div className="mb-2">
                                <label>เบอร์โทร</label>
                                <input type="number" className="form-control" onChange={this.onTextChange} name="tel_in"
                                    value={this.state.tel_in} placeholder="กรุณาระบุเบอร์โทร" required />
                            </div>

                            <div className="mb-2">
                                <label>อีเมลล์</label>
                                <input className="form-control" onChange={this.onTextChange} name="email_in"
                                    value={this.state.email_in} placeholder="กรุณาระบุอีเมลล์" required />
                            </div>


                            <div className="mb-2">
                                <label>เพศ</label><br/>
                              
                                <Select
                                required
                                        showSearch
                                      // style={{ width: 500}}
                                        style={{ width: "100%" }}
                                        placeholder="เพศ"
                                        optionFilterProp="children"
                                        onChange={this.handleChange3}
                                        onFocus={onFocus}
                                        onBlur={onBlur}
                                    >
                                       
                                        <Option value="ชาย">ชาย</Option>
                                        <Option value="หญิง">หญิง</Option>
                                      
                                    </Select>

                          
                            </div>

                            {/* <div className="mb-2">
                                <label>เพศ</label><br/>
                                <Checkbox onChange={onChange} value="1">Checkbox</Checkbox>
                            </div> */}
                            <br/>
                            <div className="mb-2">
                            <label>วัตถุประสงค์ในการซื้อประกัน</label><br/>
                            <Checkbox.Group style={{ width: '100%' }} onChange={this.onChangebox}>
                                <Row>
                                <div className="d-flex flex-wrap bd-highlight example-parent">
                                    {this.state.planlist.map( (planlist, index) =>  
                                    <div className="p-2 bd-highlight col-example">

                                       <Checkbox value={planlist.id_plan}>{planlist.name_plan}</Checkbox>
                                    </div>
                                        // <Col span={8}>
                                        // <Checkbox value={planlist.id_plan}>{planlist.name_plan}</Checkbox>
                                        // </Col>


                                    )}
                                    </div>
                                 </Row>
                            </Checkbox.Group>
                            </div>

                            <div className="mt-3 text-center">
                                <MDBBtn type="submit" color="red darken-2"> <MDBIcon far icon="save" /> ส่งข้อมูล</MDBBtn>
                            </div>

                        </form>
                    {/* </MDBCardBody>


                </MDBCard> */}
<br/>

                {/* <MDBCard>
                    <MDBCardBody>
                <h1 >แนะนำแบบประกัน</h1>
              
<Tabs defaultActiveKey="4"  color="red-7" centered>
<TabPane tab="ประกันสุขภาพ" key="4">
    <Iframe url="https://anyflip.com/bookcase/hbsna"
              width="100%"
              height="450px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"/>
    </TabPane>
    <TabPane tab="ความคุ้มครองชีวิต" key="1" >
          <Iframe url="http://www.anyflip.com/bookcase/okpha"
              width="100%"
              height="450px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"/>
          </TabPane>
    <TabPane tab="ค่าชดเชย และโรคร้ายแรง" key="2">
          <Iframe url="https://anyflip.com/bookcase/xnidt"
              width="100%"
              height="450px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"/>
    </TabPane>
    <TabPane tab="ประกันชีวิตควบการลงทุน" key="3">
    <Iframe url="https://anyflip.com/bookcase/asbpf"
              width="100%"
              height="450px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"/>
    </TabPane>
   
    <TabPane tab="ประกันอุบัติเหตุ" key="5">
    <Iframe url="https://anyflip.com/bookcase/ryuff"
              width="100%"
              height="450px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"/>
    </TabPane>
    <TabPane tab="สะสมทรัพย์" key="6">
    <Iframe url="https://anyflip.com/bookcase/xkpsj"
              width="100%"
              height="450px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"/>
    </TabPane>
    <TabPane tab="สิทธิพิเศษ สำหรับลูกค้า" key="7">
    <Iframe url="https://anyflip.com/bookcase/deaib"
              width="100%"
              height="450px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"/>
    </TabPane>
  </Tabs>


                    </MDBCardBody>
                </MDBCard> */}
            </div>
        )
    }
}

