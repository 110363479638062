import React, { Component ,useState} from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';
import Modaladdfile from './Modaladdfile';
import { api } from '../../api';
import Modaladdcategory from './Modaladdcategory';
import Modaladdpictrue from './Modaladdpictrue';
//import Lightbox from "react-lightbox-component";
//import "react-lightbox-component/build/css/index.css";
//import Lightbox from 'react-image-lightbox';
//import 'react-image-lightbox/style.css'; 
// import LightBox from "react-awesome-lightbox";
// import "react-awesome-lightbox/build/style.css";

//import Lightroom from 'react-lightbox-gallery'

//import Lightbox from "react-lightbox-component";
//import "react-lightbox-component/build/css/index.css";
//import { Image } from 'antd';
//const [visible, setVisible] = useState(false);


export default class Addcategory extends Component {
    constructor(props) {
        super(props);
        this.state = {
          emp:[],
          news:[],
          category:[],
          photoIndex: 0,
          isOpen: false,
          currentImage: 0,
          lightboxIsOpen:false,
          visible:false,
        };
       this.getcategory = this.getcategory.bind(this);
       this.delete = this.delete.bind(this);
       this.showpicc = this.showpicc.bind(this);
       this.showpicclose = this.showpicclose.bind(this);
       
      }

      componentDidMount() {
      this.getcategory();
       
      }

      getcategory() {
        setTimeout(() => {
           //ภายใน
           api.get('selectcategory.php')
           .then(res => {
            // console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ category: res.data})
           }) 
         }, 0);
      }

      showpicc() {
        this.setState({ visible: true})
      }

      showpicclose = (id) => {
        this.setState({ visible: id})
      }

      delete = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
        api.get('deletecategory.php?id=' + id)
            .then((res) => {
                this.getcategory()
            })
      }

  

  render() {
   // const [visible, setVisible] = useState(false);
   // const { photoIndex, isOpen } = this.state;
   // console.log(this.state.category)
  //  var images = [
  //   {
  //     src:
  //       "https://upload.wikimedia.org/wikipedia/commons/8/89/Ropy_pahoehoe.jpg",
  //     title: "Ropy pahoehoe",
  //     description:
  //       "By Tari Noelani Mattox. Licensed under Public Domain via Commons"
  //   },
  //   {
  //     src:
  //       "https://upload.wikimedia.org/wikipedia/commons/7/73/Pyroclastic_flows_at_Mayon_Volcano.jpg",
  //     title: "Pyroclastic flows at Mayon Volcano",
  //     description: "By C.G. Newhall. Licensed under Public Domain via Commons"
  //   },
  //   {
  //     src: "https://upload.wikimedia.org/wikipedia/commons/f/f3/Okataina.jpg",
  //     title: "Okataina",
  //     description: "By Richard Waitt. Licensed under Public Domain via Commons"
  //   }
  // ];

  // const images = [
  //   '//placekitten.com/1500/500',
  //   '//placekitten.com/4000/3000',
  //   '//placekitten.com/800/1200',
  //   '//placekitten.com/1500/1500',
  // ];


// var images = [
//   {
//     src: "https://images.unsplash.com/photo-1577279549270-b9e297533cdd?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1534&q=80",
//     desc: 'Person wearing shoes',
//     sub: 'Gift Habeshaw'
//   },
//   {
//     src: "https://images.unsplash.com/photo-1577277625082-36df4915ebeb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
//     desc: 'Blonde woman wearing sunglasses smiling at the camera ',
//     sub: 'Dmitriy Frantsev'
//   }
// ]
// var settings = {
//   columnCount:{
//     default:5,
//     mobile:3,
//     tab:4
//   },
//   mode: 'dark'
// }
// const photos = [
//   {
//     src: 'http://example.com/example/img1.jpg',
//     width: 4,
//     height: 3
//   },
//   {
//     src: 'http://example.com/example/img2.jpg',
//     width: 1,
//     height: 1
//   }
// ];




    const data = {
      columns: [
        {
          label: 'ลำดับ',
          field: 'list',
          sort: 'list',
          width: 150
        },
        {
          label: 'ชื่อรายการ',
          field: 'NAME',
          sort: 'NAME',
          width: 200
        },
         {
          label: 'วันที่เพิ่ม',
          field: 'DATES',
          sort: 'DATES',
          width: 200
        },
        {
          label: 'เพิ่มภาพ',
          field: 'ADDPIC',
          sort: 'ADDPIC',
          width: 100
        },
        {
          label: 'จัดการ',
          field: 'FACULTY',
          sort: 'FACULTY',
          width: 100
        }

      ],

      rows: [...this.state.category.map((data, i) => (
        {
           list: <>{i+1}</>,
           NAME: <><MDBIcon far icon="images" /> {data.name_category}</>,
           DATES: <>{data.date_category}</>,
           ADDPIC: <> <Modaladdpictrue id_category={data.id_category} name_category={data.name_category} /></>,
           FACULTY: <> <MDBBtn size="sm" color="deep-orange" onClick={() => this.delete(data.id_category)} ><MDBIcon far icon="trash-alt" /> ลบ</MDBBtn></>,
          

          //  random: <h5 searchvalue={data.Curriculumopen_TEAM} >tourist <span className="badge badge-danger">narrow</span></h5>,

        }
       ))] 
  
  };

  const persons = <MDBDataTable
  // searching ={true}
   searchLabel="ค้นหา รายการ"
   striped
   bordered
   small 
   entries={20}
  // entriesOptions={["10", "20"]}
   order={["age", "desc"]}
   data={data}
   entriesLabel="จำนวนที่แสดง "
   infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}
   paginationLabel={["ย้อนกลับ", "ถัดไป"]}
   responsive 
   sortRows={['random']}
/>

    return (
     
      <MDBContainer>
        <br/>
        <h2 className='text-center'>ระบบจัดการภาพผลงาน</h2>
        <h4 className='text-center'>ผู้ใช้งาน : {localStorage.getItem('name_member')}</h4>
          <p className='text-center'>< Modaladdcategory getcategory={this.getcategory}/></p> 
     
         {persons}<br/>
      
      </MDBContainer>
    )
  }
}
