import React, { Component } from 'react'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBAlert, MDBSelect , MDBInput  } from 'mdbreact';
import { MDBSelectInput, MDBSelectOptions, MDBSelectOption , MDBIcon} from "mdbreact";
import Axios from 'axios'
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip  } from 'antd';

import { api } from '../../api';
import Swal from 'sweetalert2'



export default class Modalregisterauthorities extends Component {

   


constructor(props) {
        super(props);

        this.state = {
            modal: false,
          
            auth_name:'',
            auth_lname:'',
            auth_agency:'',
            auth_tel:'',
            auth_status:'',
          
          
             filecomment1:null,
             filecomment2:null,
          }

this.onTextChange = this.onTextChange.bind(this);
this.onChangetype = this.onChangetype.bind(this);
this.handleSubmit = this.handleSubmit.bind(this);
this.toggle = this.toggle.bind(this);

}

toggle = () => {
  this.setState({
    modal: !this.state.modal
  });
 // this.props.selectatf();
}

 componentDidMount() {
    //  const { id_send } = this.props.id_send
    //  console.log(id_send);
     // this.setState({ id_send:this.props.id_send})
 
  }

  onTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
 }

 onChangetype(value) {
    this.setState({ auth_status:value})
  }

  handleSubmit() {
    // alert('A name was submitted: ' + this.state.value);
   setTimeout(() => {

      const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
      datafrm.append("auth_name", this.state.auth_name);
      datafrm.append("auth_lname", this.state.auth_lname);
      datafrm.append("auth_agency", this.state.auth_agency);
      datafrm.append("auth_tel", this.state.auth_tel);
      datafrm.append("auth_status", this.state.auth_status);

      
      api.post("insertauthorities.php",datafrm,{
                            headers: {
                                'content-type': 'multipart/form-data'
                            }
                        })

                        .then(res => {
                            console.warn(res);
                            console.log("response: ", res)
                            if (res.status=== 200) {
                            // this.showitem = true;
                           // console.log(res)
                           // console.log(res.data.length) 
                          // message.success("ลงทะเบียนสำเร็จ")
                          
                          Swal.fire({
                            title: 'บันทึกข้อมูลสำเร็จ',
                            icon: 'success',
                          // showCancelButton: true,
                            confirmButtonText: 'ตกลง',
                          // cancelButtonText: 'No, keep it'
                          }).then((result) => {
                            this.toggle();
      
                          
                          })

                            }else{
                            // window.location.href = '/'
                            }

                            // if (res.data.status = 'success999') {
                            // message.success("อัพโหลไฟล์สำเร็จ")
                            // // window.location.href = '/adminfaculty'
                            // }else{
                            // message.error("อัพโหลไฟล์ไม่สำเร็จ!")
                            // }
                        })

            }, 2000);

//window.location.href = '/adminfaculty'
//this.props.updateat();
//this.getExpert()

  }

    render() {

        console.log(this.state.auth_name)
        console.log(this.state.auth_lname)
        console.log(this.state.auth_agency)
        console.log(this.state.auth_tel)
        console.log(this.state.auth_status)
        
        return (
            <MDBContainer >
            <MDBBtn color="deep-orange" onClick={this.toggle} className="text-center">ลงทะเบียน</MDBBtn>

            <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
              <MDBModalHeader toggle={this.toggle}><MDBIcon icon="registered" /> ลงทะเบียนเจ้าหน้าที่/บุคคลทั่วไป</MDBModalHeader>
              <MDBModalBody className="text-left">
              <div>

              <form onSubmit={this.handleSubmit}>
                            <div className="mb-2">
                                <label>ชื่อ</label>
                                <input className="form-control" onChange={this.onTextChange} name="auth_name"
                                    value={this.state.auth_name} placeholder="กรุณาระบุชื่อ" required />
                            </div>

                            <div className="mb-2">
                                <label>นามสกุล</label>
                                <input className="form-control" onChange={this.onTextChange} name="auth_lname"
                                    value={this.state.auth_lname} placeholder="กรุณาระบุนามสกุล" required />
                            </div>

                            <div className="mb-2">
                                <label>หน่วยงาน</label>
                                <input className="form-control" onChange={this.onTextChange} name="auth_agency"
                                    value={this.state.auth_agency} placeholder="กรุณาระบุหน่วยงาน" required />
                            </div>

                            <div className="mb-2">
                                <label>เบอร์โทร</label>
                                <input className="form-control" onChange={this.onTextChange} name="auth_tel"
                                    value={this.state.auth_tel} placeholder="กรุณาระบุเบอร์โทร" required />
                            </div>

                            <div className="mb-2">
                                <label>ประเภทบุคลากร</label><br/>
                                <Select
                                    showSearch
                                    style={{ width:"100%" }}
                                    placeholder="เลือกประเภทบุคลากร"
                                    optionFilterProp="children"
                                    onChange={this.onChangetype}
                                    onFocus={this.onFocus}
                                    onBlur={this.onBlur}
                                    onSearch={this.onSearch}
                                    filterOption={(input, option) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                <option value="1">ภายใน</option>
                                <option value="2">ภายนอก</option>
                              
                                 </Select>
                            </div><br/>

                            <p className="purple-text text-center">ตรวจสอบข้อมูลว่าถูกต้องครบถ้วน</p>
                            {/* <div className="mt-3 text-center" >
                                <MDBBtn type="submit" color="primary" className="text-center"> <MDBIcon far icon="save" /> บันทึก</MDBBtn>
                            </div> */}


                        </form>


              </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn>
                <MDBBtn color="primary" onClick={this.handleSubmit} >บันทึก</MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          </MDBContainer>
          
        )
    }
}
