import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem } from 'mdbreact';
  import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBMask, MDBView, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader } from 'mdbreact';
import { Form, Input, Button, Checkbox, message } from 'antd';
import Login from './Login';
import Axios from 'axios'
import { URL } from '../api';
import logo from './img/logokpru.png'

import Swal from 'sweetalert2'
import Modalregisterauthorities from './register/Modalregisterauthorities';
import Modallogin from './register/Modallogin';
//import banner1 from './img/banner1.jpg'
import ReactJsTyping from 'reactjs-typing-effect';
import vw301318 from './img/vw301318.png'
import vo from './img/vo.mp4'
import { api } from '../api';
import banner1 from './img/banner1.jpg'
import banner2 from './img/banner2.jpg'
import banner3 from './img/banner3.jpg'
import banner4 from './img/banner4.jpg'
import banner5 from './img/banner5.jpg'
import fa1 from './img/fa1.png'
import fa2 from './img/fa2.png'
import s1 from './img/s1.jpg'
import s2 from './img/s2.jpg'
import s3 from './img/s3.jpg'

export default class Homepage extends Component {
    

        constructor(props) {
            super(props);
            this.state = {
                value: '',
                Psaaword:'',
                modal: false,
                collapse: false,
                isWideEnough: false,
                banner:[],
                loading:false,

            };
            this.handleChange = this.handleChange.bind(this);
            this.handleChange2 = this.handleChange2.bind(this);
         
            this.toggle = this.toggle.bind(this);
            this.onClick = this.onClick.bind(this);
            this.register = this.register.bind(this);
            this.registerauthor = this.registerauthor.bind(this);
             this.linktolas = this.linktolas.bind(this);
            this.linktoprime = this.linktoprime.bind(this);
             
          }

          onClick() {
            this.setState({
              collapse: !this.state.collapse,
            });
          }

          toggle = () => {
            this.setState({
              modal: !this.state.modal
            });
          }

          register() {
            // window.location.href = '/Register'
             window.location.href = '/kprurun2022/Registerstudent'
             // this.setState({Register
             //   collapse: !this.state.collapse,
             // });
           }

           registerauthor() {
            // window.location.href = '/Register'
             window.location.href = '/kprurun2022/Registerauthorities'
             // this.setState({Register
             //   collapse: !this.state.collapse,
             // });
           }

           linktolas() {
            // window.location.href = '/Register'
             window.location.href = '/Lastandard'
             // this.setState({Register
             //   collapse: !this.state.collapse,
             // });
           }
           linktoprime() {
            // window.location.href = '/Register'
             window.location.href = '/Faprime'
             // this.setState({Register
             //   collapse: !this.state.collapse,
             // });
           }
        
          handleChange(event) {    this.setState({value: event.target.value});  }
          handleChange2(event) {    this.setState({Psaaword: event.target.value});  }

          componentDidMount() {
            this.setState({ loading: true})
            setTimeout(() => {
               //ภายใน
               api.get('selectbannerall.php')
               .then(res => {
                 console.log(res);
               //  this.setState({ bankname: res.data, loading: false })
                 this.setState({ 
                  banner: res.data,
                  loading:false
                })
    
               }) 
             }, 0);
             
            }
       

    update = () => {
        this.setState({
            name: "",
            lname: ""
        })
    }

    render() {

      const listOfString=['THENEXT WELCOME','AIA THE NEXT']

      const listOfString2=['ได้มากกว่า']
      
        return (
       
                <>
           
           <MDBCarousel
          activeItem={1}
          length={this.state.banner.length}
          showControls={true}
          showIndicators={false}
          interval={4000} 
          className="z-depth-1 MDBCarousel"
          class="carousel slide carousel-fade"
        >
          <MDBCarouselInner fluid>

          {this.state.banner.map((banner,index )  => 
            <MDBCarouselItem itemId={index+1}>
              <MDBView fluid>
                <img
                  className="d-block w-100"
                  src={`https://thenextth.com/aiabackend/uploadsbanner/${banner.banner_file}`}
                  alt="First slide"
                />
                {/* <MDBMask overlay="black-light" /> */}
              </MDBView>
            </MDBCarouselItem>
            )}
           
          </MDBCarouselInner>
        </MDBCarousel><br/>

                <MDBContainer>
          <div>

                  <div className='text-center font-weight-bold' style={{ fontSize: 'clamp(10px,3vw,40px)', color: '#212121'}}>
                      <ReactJsTyping StringList={listOfString} speed={1000}/>
                  </div>
                  <h5  className='text-center font-weight-bold' style={{ fontSize: 'clamp(10px,3vw,20px)', color: '#757575'}}>เอไอเอเดอะเน็กซ์ เราพร้อมรักษาผลประโยชน์ของลูกค้าอย่างเต็มที่ </h5>
                  <h5 className='text-center font-weight-bold' style={{ fontSize: 'clamp(10px,3vw,20px)', color: '#757575'}}>เราสามารถออกแบบประกันได้ตามใจลูกค้า ประกันไม่มีความเสี่ยงเพียงแค่ทำตามสัญญา  </h5>
                  <h5 className='text-center font-weight-bold' style={{ fontSize: 'clamp(10px,3vw,20px)', color: '#757575'}}>ประกันปลอดภาษี ประกันเพิ่มมูลค่าเงิน ประกันแก้ปัญหากฎหมายคุ้มครองเงินฝาก  </h5>
                  <h5 className='text-center font-weight-bold' style={{ fontSize: 'clamp(10px,3vw,20px)', color: '#757575'}}>ถ้าเงินอยู่ถูกที่ทำหน้าที่ต่างกัน "มั่นคง อย่างมั่นใจ" </h5><br/>
               <video width="100%" height="100%" controls autostart  src={vo} type="video/mp4" />

          </div><br/>
          </MDBContainer>  
          {/* <h1 className='text-center font-weight-bold' style={{  color: '#d32f2f'}}>ปรึกษาด้านการเงินและแบบประกัน </h1> */}
          {/* <div className='service'>
            <p>sdsdsds</p>
            <p>sdsdsds</p>
            <p>sdsdsds</p>
            <p>sdsdsds</p>
          </div> */}
          <div >
          <MDBContainer>


          {/* <MDBCarousel
          activeItem={1}
          length={5}
          showControls={true}
          showIndicators={true}
          className="z-depth-1"
        >
          <MDBCarouselInner>
            <MDBCarouselItem itemId="1">
              <MDBView>
                <img
                  className="d-block w-100"
                  src={banner1}
                  alt="First slide"
                />
               
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="2">
              <MDBView>
                <img
                  className="d-block w-100"
                  src={banner2}
                  alt="Second slide"
                />
                
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="3">
              <MDBView>
                <img
                  className="d-block w-100"
                  src={banner3}
                  alt="Third slide"
                />
               
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="4">
              <MDBView>
                <img
                  className="d-block w-100"
                  src={banner4}
                  alt="Third slide"
                />
               
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="5">
              <MDBView>
                <img
                  className="d-block w-100"
                  src={banner5}
                  alt="Third slide"
                />
              
              </MDBView>
            </MDBCarouselItem>
          </MDBCarouselInner>
        </MDBCarousel> */}
        <br/>




            {/* <div className='text-center font-weight-bold' style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#212121'}}>
                  ให้คุณ <span  style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#d01440'}}><ReactJsTyping  StringList={listOfString2} speed={2000}/></span> การทำประกันทั่วไป
                  </div> 
 
            <h4 className='text-center font-weight-bold mt-2'  style={{fontSize:'clamp(12px,4vw,40px)'}}>วิเคราะห์ แนะนำ แม้ไม่เคยทำประกัน พร้อมออกแบบประกันที่เหมาะเฉพาะคุณ</h4> */}
          
            <MDBContainer>
            <MDBRow>
          <MDBCol lg="6" className='text-center'>
          <img onClick={this.linktolas}
                  className="d-block w-100"
                  src={fa1}
                  alt="First slide"
                />
                <MDBBtn color="danger" onClick={this.linktolas}>รายละเอียด FA STANDARD</MDBBtn>
          </MDBCol>
          <MDBCol lg="6" className='text-center'>
          <img onClick={this.linktoprime}
                  className="d-block w-100"
                  src={fa2}
                  alt="First slide"
                />
                 <MDBBtn  color="danger" onClick={this.linktoprime}>รายละเอียด FA PRIME</MDBBtn>
          </MDBCol>
        {/* Faprime */}
      </MDBRow>
<br/>

      <MDBCarousel
          activeItem={1}
          length={3}
          showControls={true}
          showIndicators={true}
          className="z-depth-1"
        >
          <MDBCarouselInner>
            <MDBCarouselItem itemId="1">
              <MDBView>
                <img
                  className="d-block w-100"
                  src={s1}
                  alt="First slide"
                />
               
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="2">
              <MDBView>
                <img
                  className="d-block w-100"
                  src={s2}
                  alt="Second slide"
                />
                
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="3">
              <MDBView>
                <img
                  className="d-block w-100"
                  src={s3}
                  alt="Third slide"
                />
               
              </MDBView>
            </MDBCarouselItem>
           
          </MDBCarouselInner>
        </MDBCarousel>
<br/> 






            </MDBContainer>
          
          
            {/* <img
                 // className="d-block w-40"
                  src={vw301318}
                   width={120}
                  alt="Second slide"
                /> */}
</MDBContainer> 

          </div>
        
                    </>
                   
                   
        )
    }
}
