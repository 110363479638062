import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";

import Home from './Pages/Home';
import About from './Pages/About';
import Header from './Component/Header';

import Timetable from './Pages/admincu/Timetable';
import Listname from './Pages/admincu/Listname';
import Footter from './Component/Footter';
import Listname2 from './Pages/admincu/Listname2';
import Classroom from './Pages/admincu/Classroom';
import Teach from './Pages/admincu/Teach';
import Learn from './Pages/admincu/Learn';


import Homepage from './Pages/Homepage';
import Registerstudent from './Pages/register/Registerstudent';
import Registerauthorities from './Pages/register/Registerauthorities';
import Header2 from './Component/Header2';

import Mainme from './Pages/adminme/Mainme';
import Addfileme from './Pages/adminme/Addfileme';
import Addbanner from './Pages/adminme/Addbanner';
import Addcategory from './Pages/adminme/Addcategory';
import History from './Pages/public/History';
import Vision from './Pages/public/Vision';
import Medical from './Pages/public/Medical';
import Addfileprocurement from './Pages/adminme/Addfileprocurement';
import Manage from './Pages/public/Manage';
import Nursing from './Pages/public/Nursing';
import Pharmacy from './Pages/public/Pharmacy';
import Dental from './Pages/public/Dental';
import Health from './Pages/public/Health';
import Primary from './Pages/public/Primary';
import Sanitary from './Pages/public/Sanitary';
import Identify from './Pages/public/Identify';
import Radiology from './Pages/public/Radiology';
import { Setmap } from './Pages/adminme/Setmap';
import { Customers } from './Pages/adminme/Customers';
import { Showlocation } from './Pages/adminme/Showlocation';
import Addcar from './Pages/adminme/Addcar';
import Header3 from './Component/Header3';
import Contect from './Pages/Contect';
import Ceck from './Pages/Ceck';
import Addguaranteetype from './Pages/adminme/Addguaranteetype';
import Addregister from './Pages/register/Addregister';
import selectregister from './Pages/register/selectregister';
import registerproportion from './Pages/register/registerproportion';
import selectposition from './Pages/register/selectposition';
import selectinsurance from './Pages/register/selectinsurance';
import Addinsurance from './Pages/register/Addinsurance';
import Lastandard from './Pages/Lastandard';
import Faprime from './Pages/Faprime';
import Addcomment from './Pages/register/Addcomment';
import Addinsurance2 from './Pages/register/Addinsurance2';
import Successregister from './Pages/register/Successregister';
import Successinsurance from './Pages/register/Successinsurance';













//กลุ่มงาน







export default class Routes extends Component {
    render() {
        return (
            <>
    
    
   
            <Router basename="/" >
                <Header />
                {/* <br/> */}
                {/* <Header2/> */}
                {/* <Header3 /> */}
                
                <div className="content-container p-0"> 
               <Switch>
                  
                    <Route exact path="/" component={Homepage} />
                    <Route exact path="/home1" component={Homepage} />
                    <Route exact path="/login" component={Home} />
                   
                    {/* <Route exact path="/Listname" component={Listname} />
                    <Route exact path="/Listname2" component={Listname2} />
                    <Route exact path="/Classroom" component={Classroom} />
                    <Route exact path="/Teach" component={Teach} />
                    <Route exact path="/Learn" component={Learn} />
                    <Route exact path="/Check/:idemp" component={Checkpage} />
                    <Route exact path="/Stuall" component={Stuallpage} />
                    <Route exact path="/Tmm" component={Tmm} />
                    <Route exact path="/Personnelpage" component={Personnelpage} />
                    <Route exact path="/Report" component={Report} />
                    <Route exact path="/Registerstudent" component={Registerstudent} />
                    <Route exact path="/Registerauthorities" component={Registerauthorities} /> */}


                    <Route exact path="/Mainme" component={Mainme} />
                    <Route exact path="/Addfileme" component={Addfileme} />
                    <Route exact path="/Addbanner" component={Addbanner} />
                    <Route exact path="/Addcategory" component={Addcategory} />
                    <Route exact path="/History" component={History} />
                    <Route exact path="/Vision" component={Vision} />
                    <Route exact path="/Medical" component={Medical} />
                    <Route exact path="/Addfileprocurement" component={Addfileprocurement} />
                    <Route exact path="/Setmap" component={Setmap} />
                    <Route exact path="/Customers" component={Customers} />
                    <Route exact path="/Showlocation" component={Showlocation} />
                    <Route exact path="/Addcar" component={Addcar} />
                     <Route exact path="/Contect" component={Contect} />
                     <Route exact path="/Ceck" component={Ceck} />
                     
                    <Route exact path="/Addguaranteetype" component={Addguaranteetype} />
                    <Route exact path="/Addregister" component={Addregister} />
                    <Route exact path="/selectregister" component={selectregister} />
                    <Route exact path="/registerproportion" component={registerproportion} />
                    <Route exact path="/selectposition" component={selectposition} />
                    <Route exact path="/selectinsurance" component={selectinsurance} />
                    <Route exact path="/Addinsurance" component={Addinsurance} />
                    <Route exact path="/Lastandard" component={Lastandard} />
                    <Route exact path="/Faprime" component={Faprime} />
                    <Route exact path="/Addcomment" component={Addcomment} />    
                    <Route exact path="/Addinsurance2" component={Addinsurance2} />  
                    <Route exact path="/Successregister" component={Successregister} />  
                    <Route exact path="/Successinsurance" component={Successinsurance} /> 
                    
                       {/* <Route exact path="/Addguaranteetype" component={Addguaranteetype} /> */}
                    {/* <Route exact path="/Manage" component={Manage} />
                    <Route exact path="/Nursing" component={Nursing} />
                    <Route exact path="/Pharmacy" component={Pharmacy} />
                    <Route exact path="/Dental" component={Dental} />
                    <Route exact path="/Health" component={Health} />
                    <Route exact path="/Primary" component={Primary} />
                    <Route exact path="/Sanitary" component={Sanitary} />
                    <Route exact path="/Identify" component={Identify} />
                    <Route exact path="/Radiology" component={Radiology} /> */}
                
                   
                    
                    <Route render={function () {return <h1>Not Found</h1> }} />
                </Switch>
                 {/* <br/> */}
            {/* <Footter />        */}
            
</div>
               <footer className="footer--pin">
<Footter /> 
</footer>
               
            </Router>
            
        
          </>  
        )
    }
}
