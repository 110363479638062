import React, { Component } from 'react'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBAlert, MDBSelect , MDBInput, MDBIcon  } from 'mdbreact';
import { MDBSelectInput, MDBSelectOptions, MDBSelectOption } from "mdbreact";
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import Axios from 'axios'
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip  } from 'antd';

const { Option } = Select;
function onChange(value) {
  console.log(`selected ${value}`);
}

function onBlur() {
  console.log('blur');
}

function onFocus() {
  console.log('focus');
}

function onSearch(val) {
  console.log('search:', val);
}

export default class Modalshowdeteal extends Component {
    constructor(props) {
        super(props);
this.state = {
   modal: false,
   editstatus:'',
   id_send:'',
   positionname:'',
   insurance2:'',

   name_in:'',
   age_in:'',
   weight:'',
   height:'',
   tel_in:'',
   name_in2:[],


}


this.onTextChange = this.onTextChange.bind(this)
//this.handleSubmit = this.handleSubmit.bind(this);
this.toggle = this.toggle.bind(this);

this.handleChangeedit = this.handleChangeedit.bind(this);
this.sendpdf = this.sendpdf.bind(this);
this.sendpdf2 = this.sendpdf2.bind(this);
this.sendpdf3 = this.sendpdf3.bind(this);
}

onTextChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
}


toggle = () => {
  this.setState({
    modal: !this.state.modal
  });
 // this.props.selectatf();
}

handleChangeedit(event) {  this.setState({editstatus: event});  }

sendpdf() { 
    window.open(
       // 'http://localhost/registerfa/TCPDF-main/thenext.php?id='+this.props.id_in ,
        'https://thenextth.com/registerfa/TCPDF-main/thenext.php?id='+this.props.id_in ,
        '_blank' // <- This is what makes it open in a new window.
      );
    
    }

    sendpdf2() { 
        window.open(
           // 'http://localhost/phpapi/TCPDF-main/thenext.php?id='+this.props.id_in ,
            'https://thenextth.com/registerfa/TCPDF-main/thenext2.php?id='+this.props.id_in ,
            '_blank' // <- This is what makes it open in a new window.
          );
        
        }
        sendpdf3() { 
          window.open(
             // 'http://localhost/phpapi/TCPDF-main/thenext.php?id='+this.props.id_in ,
              'https://thenextth.com/registerfa/TCPDF-main/thenext3.php?id='+this.props.id_in ,
              '_blank' // <- This is what makes it open in a new window.
            );
          
          }
        


 componentDidMount() {
       const { id_in } = this.props.id_in
    //   console.log(id_send);
    //   this.setState({ id_send:this.props.id_send})
    setTimeout(() => {
        Axios.get('https://thenextth.com/registerfa/selectinsurance2.php?id_in='+this.props.id_in)
       //Axios.get('http://localhost/phpapi/selectinsurance2.php?id_in='+this.props.id_in)
        .then(res => {
        //].data);
        //  this.setState({ bankname: res.data, loading: false })
         this.setState({ name_in: res.data[0].name_in})
          this.setState({ age_in: res.data[0].age_in})
         // this.setState({ weight: res.data[0].weight})
         // this.setState({ height: res.data[0].height})
         this.setState({ tel_in: res.data[0].tel_in})
        // var joined = this.state.name_plan.concat(res.data[0].name_in);
          this.setState({ name_in2: res.data })

        }) 
      //  console.log(event[1])
     }, 1000);
  }


 

  

    render() {
        console.log(this.state.positionname)
        return (
            <>
               
            <MDBBtn size="sm" color="primary" onClick={this.toggle}><MDBIcon far icon="edit" />รายละเอียด</MDBBtn>

            <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
              <MDBModalHeader toggle={this.toggle}>รายละเอียดผู้ขอเอาประกันชีวิต</MDBModalHeader>
              <MDBModalBody>
              <div>
              <MDBTable small>
      
      <MDBTableBody>
                <tr>
                    <td>ชื่อ-นามสกุล</td>
                    <td>{this.state.name_in}</td>
                </tr>
                <tr>
                    <td>อายุ</td>
                    <td>{this.state.age_in}</td>
                </tr>
                <tr>
                    <td>เบอร์โทร</td>
                    <td>{this.state.tel_in}</td>
                </tr>

                <MDBBtn color="dark-green" onClick={this.sendpdf}>พิมพ์ใบสมัคร</MDBBtn>
                <MDBBtn color="deep-purple" onClick={this.sendpdf2}>พิมพ์บัตร</MDBBtn>
                <MDBBtn color="unique" onClick={this.sendpdf3}>พิมพ์เอกสารแนบ</MDBBtn>
      </MDBTableBody>
    </MDBTable>
             
                          

              </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          </>
          
        )
    }
}
