import React, { Component } from 'react'
import {
    MDBContainer,
    MDBBtn,
    MDBModal,
    MDBModalBody,
    MDBModalHeader,
    MDBModalFooter
} from 'mdbreact';
import { MDBRow, MDBCol, MDBIcon } from "mdbreact";
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip , Divider } from 'antd';
import { api } from '../../api';
import Swal from 'sweetalert2'

export default class Modalcaredit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            loading: false,
            namebanner: '',
            fileupload: '',

            id_car:'',
            namecar:'',
            namedriver:'',
            id_user:'',
            teldriver:'',
            getadmin:[],

        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleChangefile = this.handleChangefile.bind(this);
        this.onTextChange = this.onTextChange.bind(this);
        this.getcar = this.getcar.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getadmin = this.getadmin.bind(this);
    }
    onTextChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleChangefile(event) {    this.setState({fileupload: event.target.files[0]});  }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
        this.getcar();
         this.getadmin();
    }

    getcar() {
        this.setState({ loading: true})
        setTimeout(() => {
           //ภายใน
           api.get('selectcaredit.php?id=' + this.props.id_car)
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ namecar: res.data[0].namecar,
                            namedriver: res.data[0].namedriver,
                            teldriver: res.data[0].teldriver,
                            id_user: res.data[0].id_user,
                            id_car: res.data[0].id_car,
                            loading:false})
           }) 
         }, 0);
      }

      getadmin() {
        this.setState({ loading: true})
        setTimeout(() => {
           //ภายใน
           api.get('selectadminshow.php')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ getadmin: res.data,loading:false})
           }) 
         }, 0);
      }

     
      
       onBlur() {
        console.log('blur');
      }
      
       onFocus() {
        console.log('focus');
      }

      handleChange(event) {    this.setState({id_user: event});  }

    //selectadminshow

    handleSubmit(e) {
        e.preventDefault();
        // alert('A name was submitted: ' + this.state.value);
     //   const date = new Date().toISOString().slice(0, 10)
       setTimeout(() => {
    
          const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
       
          datafrm.append("id_car", this.state.id_car);
          datafrm.append("namecar", this.state.namecar);
          datafrm.append("id_user", this.state.id_user);
          datafrm.append("teldriver", this.state.teldriver);
       
          //datafrm.append("id_workorder", this.state.id_workorder);
        
          api.post("updatecar.php",datafrm,{
                headers: {
                    'content-type': 'multipart/form-data'
                }
            })
    
       .then(res => {
        if (res.status=== 200) {
            Swal.fire({
                title: 'บันทึกข้อมูลรถสำเร็จ',
                icon: 'success',
              // showCancelButton: true,
                confirmButtonText: 'ตกลง',
              // cancelButtonText: 'No, keep it'
              }).then((result) => {
                this.setState({
                    namecar: '',
                    namedriver: '',
                    teldriver: '',
                });
                this.toggle();  
            //  this.getworklist();
                this.props.getcar();
              })
        }else{
          message.error("อัพโหลไฟล์ไม่สำเร็จ!")
        }
    
      })
    
    
    }, 2000);
    
    //window.location.href = '/adminfaculty'
    //this.props.updateat();
    //this.getExpert()
     
      }

    render() {
   // console.log(this.state.namework)
 //   console.log(this.state.fileupload)
 const { Option } = Select;
        return (
            <>
                <MDBBtn color="deep-orange" size='sm' onClick={this.toggle}>แก้ไขข้อมูลรถ</MDBBtn>
                <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                    <MDBModalHeader toggle={this.toggle}><MDBIcon icon="car-side" /> แก้ไขข้อมูลรถ</MDBModalHeader>
                    <MDBModalBody>
                        <form onSubmit={this.handleSubmit}>
                            <div className="mb-2 text-left">
                                <label className="font-weight-bold"><MDBIcon icon="bible" /> ลำดับรถ NO. </label>
                                <input className="form-control" onChange={this.onTextChange} name="namecar"
                                    value={this.state.namecar} placeholder="ลำดับรถ" required />
                            </div>
                            {/* <div className="mb-2 text-left">
                                <label className="font-weight-bold"><MDBIcon icon="bible" /> ชื่อคนขับ</label>
                                <input className="form-control" onChange={this.onTextChange} name="namedriver"
                                    value={this.state.namedriver} placeholder="ชื่อคนขับ" required />
                            </div> */}
                            <div className="mb-2 text-left">
                            <label className="font-weight-bold"><MDBIcon icon="bible" /> ชื่อคนขับ</label>
                            <Select
                                        showSearch
                                        style={{ width: 400 }}
                                        placeholder="เลือกคนขับ"
                                        optionFilterProp="children"
                                        onChange={this.handleChange}
                                        onFocus={this.onFocus}
                                        onBlur={this.onBlur}
                                        value={this.state.id_user}
                                        // onSearch={onSearch}
                                        // filterOption={(input, option) =>
                                        //   option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        // }
                                    >
                                        {this.state.getadmin.map((getadmin,index )  => 
                                                            <Option value={getadmin.id_member}>{getadmin.name_member}  </Option>
                                                            )}
                                        {/* <Option value="jack">Jack</Option>
                                        <Option value="lucy">Lucy</Option>
                                        <Option value="tom">Tom</Option> */}

                                    </Select>
                                    </div>
                            <div className="mb-2 text-left">
                                <label className="font-weight-bold"><MDBIcon icon="bible" /> เบอร์โทรคนขับ</label>
                                <input className="form-control" onChange={this.onTextChange} name="teldriver"
                                    value={this.state.teldriver} placeholder="เบอร์โทรคนขับ" required />
                            </div>
                           
                            {/* <Divider orientation="left" plain>
                            Left Text
                            </Divider> */}
                            {/* {this.state.filedesing_workorder == '' && this.state.filedesing_workorder == null ? <></> : <>
                                <MDBRow>
                                    <MDBCol>
                                        <img src={`https://lookmaenamping.com/sorlalumbackend/uploadsdesing/${this.state.filedesing_workorder}`} className="rounded mx-auto d-block " width="500" alt="aligment" />
                                    </MDBCol>
                                </MDBRow>
                            </>} */}


                            {/* <p className="purple-text text-center">ตรวจสอบข้อมูลว่าถูกต้องครบถ้วน</p> */}
                            <div className="mt-3 text-center" >
                                <MDBBtn type="submit" color="deep-purple" className="text-center"> <MDBIcon far icon="save" /> บันทึก</MDBBtn>
                                <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn>
                            </div>


                        </form>
                    </MDBModalBody>
                    <MDBModalFooter>
                        {/* <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn> */}
                        {/* <MDBBtn color="primary">Save changes</MDBBtn> */}
                    </MDBModalFooter>
                </MDBModal>
            </>
        )
    }
}
