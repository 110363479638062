import React, { Component } from 'react'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBAlert, MDBSelect , MDBInput, MDBBadge  } from 'mdbreact';
import { MDBSelectInput, MDBSelectOptions, MDBSelectOption } from "mdbreact";
import Axios from 'axios'
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip, Divider   } from 'antd';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import { ThunderboltFilled } from '@ant-design/icons';

export default class Modalcolor extends Component {
    constructor(props) {
        super(props);
this.state = {
  modal: false,

   CRHR:"",
   idcolor:'',
  

}

//this.handleChange = this.handleChange.bind(this);
this.handleChange2 = this.handleChange2.bind(this);
this.handleChange3 = this.handleChange3.bind(this);
this.handleSubmit = this.handleSubmit.bind(this);
this.toggle = this.toggle.bind(this);
this.handleChange4 = this.handleChange4.bind(this);
//this.getExpert = this.getExpert.bind(this);
}


toggle = () => {
  this.setState({
    modal: !this.state.modal
  });
 // this.props.getclassroom();
}


 componentDidMount() {
      const { idcolor } = this.props.idcolor
    //  console.log(this.props.id_send);
      this.setState({ idcolor:this.props.idcolor})
    //   this.setState({ SUBJNAME:this.props.SUBJNAME})
    //   this.setState({ CRHR:this.props.CRHR})
    //   this.setState({ id_major:this.props.id_major})
    //   this.setState({ major:this.props.major})
    //   this.setState({ poname:this.props.poname})
     // this.setState({ name_room:this.props.name_room})
    //  this.setState({ amount:this.props.amount})
  }


  handleChange2(event) {    this.setState({id_room: event.target.value});  }
  handleChange3(event) {    this.setState({name_room: event.target.value});  }
  handleChange4(event) {    this.setState({amount: event.target.value});  }


  handleSubmit() {
    // alert('A name was submitted: ' + this.state.value);
   setTimeout(() => {

      const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
      datafrm.append("id_room", this.state.id_room);
      datafrm.append("name_room", this.state.name_room);
      datafrm.append("amount", this.state.amount);
    
      
    Axios.post("https://git.kpru.ac.th/FrontEnd_Tabian/load/updateroom/",datafrm,{
    headers: {
        'content-type': 'multipart/form-data'
    }
   })

   .then(res => {
    //    console.log(res);
        if (res.status === 200) {
        //  alert("บันทึกสำเร็จ")
                  Swal.fire({
                      title: 'บันทึกข้อมูลสำเร็จ',
                      icon: 'success',
                    // showCancelButton: true,
                      confirmButtonText: 'ตกลง',
                    // cancelButtonText: 'No, keep it'
                    }).then((result) => {
                    
                    })
                 }
        })
        this.toggle()
      //  this.props.getclassroom()

}, 2000);

//window.location.href = '/adminfaculty'
//this.props.updateat();
//this.getExpert()
 
  }

    render() {
     //   console.log(this.state.th)
        // console.log(this.state.name_room)
        // console.log(this.state.amount)
        
        return (
            <MDBContainer>
            {/* <MDBBtn onClick={this.toggle} color="deep-purple" size="sm">แก้ไข</MDBBtn> */}

            {/* <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
              <MDBModalHeader toggle={this.toggle}>แก้ไขข้อมูล</MDBModalHeader>
              <MDBModalBody>
              <div>
             {console.log(this.state.th)} 

              <p>วิชา : {this.state.SUBJCODE} {this.state.SUBJNAME} {this.state.CRHR}</p>  
              <p>หมู่เรียน : {this.state.id_major} {this.state.major}</p> 
              <p>อาจารย์ : {this.state.poname}</p> 

              <p>รหัสห้อง </p>   
               <MDBInput type="text" label="รหัสห้อง"  outline />

               <p>ชื่อห้อง </p>
               <MDBInput type="text" label="ชื่อห้อง"  outline />

             
              </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn>
                <MDBBtn color="primary" onClick={this.handleSubmit} >บันทึก</MDBBtn>
              </MDBModalFooter>
            </MDBModal> */}
             { this.state.idcolor ==='1' ? <>  <Tag color="#CC0000">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='2' ? <>  <Tag color="#0d47a1">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='3' ? <>  <Tag color="#9933CC">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='4' ? <>  <Tag color="#007E33">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='5' ? <>  <Tag color="#0099CC">{this.state.idcolor}</Tag> </> : <> </>}
             { this.state.idcolor ==='6' ? <>  <Tag color="#f57f17">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='7' ? <>  <Tag color="#4a148c">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='8' ? <>  <Tag color="#007E33">{this.state.idcolor}</Tag> </> : <> </>}
             { this.state.idcolor ==='9' ? <>  <Tag color="#0d47a1">{this.state.idcolor}</Tag> </> : <> </>}
             { this.state.idcolor ==='10' ? <> <Tag color="#9933CC">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='11' ? <> <Tag color="#CC0000">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='12' ? <> <Tag color="#f57f17">{this.state.idcolor}</Tag> </> : <> </>}
             { this.state.idcolor ==='13' ? <> <Tag color="#007E33">{this.state.idcolor}</Tag> </> : <> </>}
             { this.state.idcolor ==='14' ? <> <Tag color="#0099CC">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='15' ? <> <Tag color="#9933CC">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='16' ? <> <Tag color="#0d47a1">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='17' ? <> <Tag color="#CC0000">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='18' ? <> <Tag color="#f57f17">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='19' ? <> <Tag color="#007E33">{this.state.idcolor}</Tag> </> : <> </>}
             { this.state.idcolor ==='20' ? <> <Tag color="#0099CC">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='21' ? <> <Tag color="#9933CC">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='22' ? <> <Tag color="#0d47a1">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='23' ? <> <Tag color="#CC0000">{this.state.idcolor}</Tag>  </> : <> </>}
             { this.state.idcolor ==='24' ? <> <Tag color="#f57f17">{this.state.idcolor}</Tag>  </> : <> </>}
          
           

          </MDBContainer>
          
        )
    }
}
