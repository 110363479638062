import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBJumbotron,MDBTabPane,
  MDBTabContent, } from 'mdbreact';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBMask, MDBView } from 'mdbreact';
import { Form, Input, Button, Checkbox, message } from 'antd';
import Login from './Login';
import Axios from 'axios'
import { URL } from '../api';
import logo from './img/logo.png'
import logo2 from './img/logo2.jpg'
import { api } from '../api';

//import Swal from 'sweetalert2'
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default class Home extends Component {
  

        constructor(props) {
            super(props);
            this.state = {
                value: '',
                Psaaword:'',
                modal: false,
                collapse: false,
                isWideEnough: false,
                activeItem: "1",
            };
            this.handleChange = this.handleChange.bind(this);
            this.handleChange2 = this.handleChange2.bind(this);
         //   this.handleSubmit = this.handleSubmit.bind(this);
            this.toggle = this.toggle.bind(this);
            this.onClick = this.onClick.bind(this);
            this.submit = this.submit.bind(this);
            this.toggle2 = this.toggle2.bind(this);
            
          }

          onClick() {
            this.setState({
              collapse: !this.state.collapse,
            });
          }

          toggle = () => {
            this.setState({
              modal: !this.state.modal
            });
          }

          toggle2 = tab => e => {
            if (this.state.activeItem !== tab) {
              this.setState({
                activeItem: tab
              });
            }
          };
        
          handleChange(event) {    this.setState({value: event.target.value});  }
          handleChange2(event) {    this.setState({Psaaword: event.target.value});  }

     submit = (event) => {
            event.preventDefault();
    
            var datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
            datafrm.append("username", this.state.value);
            datafrm.append("password", this.state.Psaaword);

        //    Axios
            // .post("https://admission.kpru.ac.th/refund/api/getdata/getuseradmin", { Username: this.form.name, Password: this.form.pass })
            api.post("selectlogin.php",datafrm)
              .then(res => {
                console.log("response: ", res)
                if (res.data.length !== 0) {
                 // this.showitem = true;
                  console.log(res)
                  console.log(res.data.length) 
                 // this.toggle()
                 localStorage.setItem('name_member', res.data[0].name_member);
                 localStorage.setItem('admin_type', res.data[0].type);
                 localStorage.setItem('id_member', res.data[0].id_member);
                        if (res.data[0].type === '2') {
                          window.location.href = '/Addbanner'
                        } else if(res.data[0].type === '4'){
                          window.location.href = '/Customers'
                        }
               }else{
                Swal.fire({
                  title: 'ไม่สามารถเข้าสู่ระบบได้',
                  //text: 'มหาวิทยาลัยราชภัฏกำแพงเพชรขอขอบคุณ',
                  icon: 'warning',
                // showCancelButton: true,
                  confirmButtonText: 'ตกลง',
                // cancelButtonText: 'No, keep it'
                }).then((result) => {
                
                })
                // window.location.href = '/'
               }
             })

            // Axios.post('http://localhost/medicinebackend/selectlogin.php', datafrm)
           
            // .then((res) => {
            //     console.log(res.data[0].status);
            //     if (res.status=== 200) {
  
                
            //          localStorage.setItem('status', res[0].status);
            //          localStorage.setItem('type2', res[0].employee_id);
            //          localStorage.setItem('admin_type', 2);
                   
                
            //     window.location.href = '/Listname'
            //     }else{
                  
                
            //        Swal.fire(
            //         'ไม่สามารถเข้าถึงระบบได้',
            //         'กรุณาลองอีกครั้ง',
            //         'question'
            //       )
                 
            //     }
            // })
           
        }




        update = () => {
            this.setState({
                name: "",
                lname: ""
            })
        }

        render() {
      
        return (
       
                <MDBContainer className='mt-4 p-0'><br/>
              <MDBJumbotron className="p-4 mt-4">
                    <MDBRow >
                    <MDBCol md="4">
                    </MDBCol>
                        <MDBCol md="4">
                            <div>
                            {/* <img src="https://research.kpru.ac.th/culture/LogoRDI.png" className="rounded mx-auto d-block w-50" alt="aligment" /><br/> */}
                            {/* <img src={logo2} className="rounded mx-auto d-block w-10 " width={140} height={120}  alt="aligment" /><br/> */}
                          <h1 className='text-center mt-4'>THENEXT</h1>
                        <form onSubmit={this.submit}>
                            <p className="h3 text-center ">เข้าสู่ระบบ</p>
                            {/* <p className="h5 text-center ">หจก.เชียงใหม่แบ้งค์รถยก</p>
                            <p className="h5 text-center ">0650961963</p> */}
                            {/* <p className="h5 text-center ">(สำหรับผู้ดูแลระบบ)</p> */}
                            {/* <p className="h5 text-center "> ฝ่ายหลักสูตรและแผนการเรียน</p> */}
                            <div className="grey-text">
                            <MDBInput label="Username " icon="user" group type="text" validate error="wrong"
                                success="right"  name="Username" value={this.state.value} onChange={this.handleChange} request/>

                            <MDBInput label="Password " icon="key" group type="password" validate error="wrong"
                                success="right" value={this.state.Psaaword} onChange={this.handleChange2} request />

                            </div>
                            <div className="text-center">
                            <MDBBtn  color="danger darken-1" dark type="submit" value="Submit" >
                             เข้าสู่ระบบ
                                {/* <MDBIcon far icon="paper-plane" className="ml-1" /> */}
                                <MDBIcon icon="hand-point-up" className="ml-1" />
                            </MDBBtn>
                            </div>
                        </form>
                         
                        </div>
                        </MDBCol>
                        <MDBCol md="4">
                    </MDBCol>
                    </MDBRow>

                    <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
        <MDBModalHeader toggle={this.toggle}>
        </MDBModalHeader>
        <MDBModalBody className="text-center">
         
        <MDBIcon icon="exclamation-circle" className="red-text text-center" size="5x" />
         <h3>usermame หรือ password ไม่ถูกต้อง</h3>
         <h3>กรุณาตรวจสอบความถูกต้อง อีกครั้ง!</h3>
        </MDBModalBody>
       
      </MDBModal>

      </MDBJumbotron>
                    </MDBContainer>
                   
     
        )
    }
}
