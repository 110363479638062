import React, { Component } from 'react'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBAlert, MDBSelect , MDBInput  } from 'mdbreact';
import { MDBSelectInput, MDBSelectOptions, MDBSelectOption } from "mdbreact";
import Axios from 'axios'
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip  } from 'antd';
import Swal from 'sweetalert2/dist/sweetalert2.js'

export default class Modalsentedit extends Component {
    constructor(props) {
        super(props);
this.state = {
  modal: false,

   inside:"",
   faculty:"",
   Expert:[],
   idExpert:"",
   id_send:"",
   id_room:"",
   name_room:"",
   amount:"",

  
}

//this.handleChange = this.handleChange.bind(this);
this.handleChange2 = this.handleChange2.bind(this);
this.handleChange3 = this.handleChange3.bind(this);
this.handleSubmit = this.handleSubmit.bind(this);
this.toggle = this.toggle.bind(this);
this.handleChange4 = this.handleChange4.bind(this);
//this.getExpert = this.getExpert.bind(this);
}


toggle = () => {
  this.setState({
    modal: !this.state.modal
  });
  this.props.getclassroom();
}


 componentDidMount() {
      const { id_send } = this.props.id_send
      console.log(id_send);
      this.setState({ id_room:this.props.id_send})
      this.setState({ name_room:this.props.name_room})
      this.setState({ amount:this.props.amount})
  }


  handleChange2(event) {    this.setState({id_room: event.target.value});  }
  handleChange3(event) {    this.setState({name_room: event.target.value});  }
  handleChange4(event) {    this.setState({amount: event.target.value});  }


  handleSubmit() {
    // alert('A name was submitted: ' + this.state.value);
   setTimeout(() => {

      const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
      datafrm.append("id_room", this.state.id_room);
      datafrm.append("name_room", this.state.name_room);
      datafrm.append("amount", this.state.amount);
    
      
   Axios.post("https://git.kpru.ac.th/FrontEnd_Tabian/load/updateroom/",datafrm,{
    headers: {
        'content-type': 'multipart/form-data'
    }
   })

   .then(res => {
    //    console.log(res);
        if (res.status === 200) {
        //  alert("บันทึกสำเร็จ")
                  Swal.fire({
                      title: 'บันทึกข้อมูลสำเร็จ',
                      icon: 'success',
                    // showCancelButton: true,
                      confirmButtonText: 'ตกลง',
                    // cancelButtonText: 'No, keep it'
                    }).then((result) => {
                    
                    })
                 }
        })
        this.toggle()
      //  this.props.getclassroom()

}, 2000);

//window.location.href = '/adminfaculty'
//this.props.updateat();
//this.getExpert()
 
  }

    render() {
        console.log(this.state.id_room)
        console.log(this.state.name_room)
        console.log(this.state.amount)
        
        return (
            <MDBContainer>
            <MDBBtn onClick={this.toggle} color="deep-purple" size="sm">แก้ไข</MDBBtn>

            <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
              <MDBModalHeader toggle={this.toggle}>แก้ไขข้อมูลห้องเรียน</MDBModalHeader>
              <MDBModalBody>
              <div>

              {/* <p>{this.state.id_send}</p>
              <p>{this.state.name_room}</p> */}
               <p>รหัสห้อง </p>   
               <MDBInput type="text" label="รหัสห้อง" value={this.state.id_room} onChange={this.handleChange2} outline />

               <p>ชื่อห้อง </p>
               <MDBInput type="text" label="ชื่อห้อง" value={this.state.name_room} onChange={this.handleChange3} outline />

               <p>จำนวนที่นั่ง </p>   
               <MDBInput type="text" label="จำนวนที่นั่ง" value={this.state.amount} onChange={this.handleChange4} outline />
              
              </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn>
                <MDBBtn color="primary" onClick={this.handleSubmit} >บันทึก</MDBBtn>
              </MDBModalFooter>
            </MDBModal>
          </MDBContainer>
          
        )
    }
}
