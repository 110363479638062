import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
    MDBCarouselInner, MDBDataTable,
    MDBCarouselItem } from 'mdbreact';
import logo2 from '../img/logo2.png'
import { api } from '../../api';
//กลุ่มงานสุขาภิบาล
export default class Identify extends Component {
    constructor(props) {
        super(props);
        this.state = {
          emp:[],
          procurement:[],
          ita:[],
        };
        this.getprocurement = this.getprocurement.bind(this);
        this.getita = this.getita.bind(this);
      }

      componentDidMount() {

            this.getprocurement();
            this.getita();

       
      }

      //จัดซื้อจัดจ้าง
      getprocurement() {
        setTimeout(() => {
           //ภายใน
           api.get('selectprocurement.php?id=1&id_member=14')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ procurement: res.data})
           }) 
         }, 0);
      }

      //ita
      getita() {
        setTimeout(() => {
           //ภายใน
           api.get('selectprocurement.php?id=2&id_member=14')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ ita: res.data})
           }) 
         }, 0);
      }


  render() {


    const data = {
      columns: [
        {
          label: 'ลำดับ',
          field: 'list',
          sort: 'list',
          width: 150
        },
        {
          label: 'เอกสาร',
          field: 'NAME',
          sort: 'NAME',
          width: 200
        },
         {
          label: 'วันที่เพิ่ม',
          field: 'DATES',
          sort: 'DATES',
          width: 200
        },
       

      ],

      rows: [...this.state.procurement.map((data, i) => (
       
        {
           list: <>{i+1}</>,
           NAME: <>{data.procurement_name}</>,
           DATES: <><MDBBtn color="indigo" size="sm" href={`http://kosamphi.kpo.go.th/kosamphigo/kosamphibackend/uploadsprocurement/${data.procurement_file}`}  target="_blank">PDF</MDBBtn></>,
         
          //  random: <h5 searchvalue={data.Curriculumopen_TEAM} >tourist <span className="badge badge-danger">narrow</span></h5>,

        }
       ))] 
  
  };

  const persons = <MDBDataTable
  // searching ={true}
   searchLabel="ค้นหา รายการ"
   striped
   bordered
   small 
   entries={20}
  // entriesOptions={["10", "20"]}
   order={["age", "desc"]}
   data={data}
   entriesLabel="จำนวนที่แสดง "
   infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}
   paginationLabel={["ย้อนกลับ", "ถัดไป"]}
   responsive 
   sortRows={['random']}
/>


const dataita = {
  columns: [
    {
      label: 'ลำดับ',
      field: 'list',
      sort: 'list',
      width: 150
    },
    {
      label: 'เอกสาร',
      field: 'NAME',
      sort: 'NAME',
      width: 200
    },
     {
      label: 'วันที่เพิ่ม',
      field: 'DATES',
      sort: 'DATES',
      width: 200
    },
   

  ],

  rows: [...this.state.ita.map((data, i) => (
   
    {
       list: <>{i+1}</>,
       NAME: <>{data.procurement_name}</>,
       DATES: <><MDBBtn color="indigo" size="sm" href={`http://kosamphi.kpo.go.th/kosamphigo/kosamphibackend/uploadsprocurement/${data.procurement_file}`}  target="_blank">PDF</MDBBtn></>,
     
      //  random: <h5 searchvalue={data.Curriculumopen_TEAM} >tourist <span className="badge badge-danger">narrow</span></h5>,

    }
   ))] 

};

const ita = <MDBDataTable
// searching ={true}
searchLabel="ค้นหา รายการ"
striped
bordered
small 
entries={20}
// entriesOptions={["10", "20"]}
order={["age", "desc"]}
data={dataita}
entriesLabel="จำนวนที่แสดง "
infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}
paginationLabel={["ย้อนกลับ", "ถัดไป"]}
responsive 
sortRows={['random']}
/>







    return (
     
      <MDBContainer>
        <br/>
        <img src={logo2} className="rounded mx-auto d-block w-10 " width={120} height={120}  alt="aligment" /><br/>
        <h2 className='text-center'>กลุ่มงานชันสูตร</h2>
        {/* <img src="http://localhost/kosamphibackend/uploadspic/1452.jpg" className="rounded mx-auto d-block w-10 " width="50%"   alt="aligment" /><br/> */}
        <h4><MDBIcon far icon="file-pdf" /> เอกสารจัดซื้อจัดจ้าง</h4>
        {persons}
        <hr/>
        <h4><MDBIcon far icon="file-pdf" /> เอกสาร ITA</h4>
         {ita}
     </MDBContainer>
    )
  }
}
