import React, { Component } from 'react'
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn, MDBIcon, MDBCard, MDBCardBody, MDBBadge } from 'mdbreact';
import { GoogleApiWrapper, InfoWindow, Marker, Map } from 'google-maps-react';
import CurrentLocation from './Map';
import GoogleMapReact from 'google-map-react'
import Showlocation from './Showlocation';
import { api } from '../../api';
import Swal from 'sweetalert2'


// const mapStyles = {
//     width: '100%',
//     height: '100%'
//   };
export  class Customers extends Component {

  
    constructor(props) {
        super(props);
        this.state = {
            data: [], //กำหนดค่าเริ่มต้นเป็น Array ว่าง เพื่อรอรับค่าต่างๆ
            showingInfoWindow: false,  // Hides or shows the InfoWindow
           activeMarker: {},          // Shows the active marker upon click
           selectedPlace: {}  ,  
           id_user:'',
           lat:'c',
           long:'c',


        };

       this.onMarkerClick = this.onMarkerClick.bind(this);
       this.onClose = this.onClose.bind(this);
       this.handleSubmit = this.handleSubmit.bind(this);
      
      }


    onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

    onTextChange = (e) => { //เมื่อมีการพิมพ์ค้นหา ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่งค่าที่ต้องการค้นหาไปที่ไฟล์ search.php
        Axios.get('http://localhost/PHP/search.php?id=' + e.target.value)
            .then((res) => {
                this.setState({ data: res.data })
            })
    }


    delete = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
        Axios.get('http://localhost/PHP/delete.php?id=' + id)
            .then((res) => {
                this.getdata()
            })
    }

    getdata = () => { //เป็นฟังก์ชั่นไว้เรียกใช้สำหรับดึงข้อมูลมาทั้งหมด
        Axios.get('http://localhost/PHP/select.php')
            .then((res) => {
                this.setState({ data: res.data })
            })
    }

    componentDidMount() { //เป็นฟังก์ชั่นชอง react โดนเฉพาะ โดยฟังก์ชั่นนี้จะถูกเรียกใช้งานครั้งแรกเสมอเมื่อมีการโหลด component นี้
       // this.getdata()

       this.setState({ id_user: localStorage.getItem('id_member'),
        // lat: localStorage.getItem('latitude'),
        // long: localStorage.getItem('longitude')
       })


    }


    handleSubmit() {
    //  e.preventDefault();
      // alert('A name was submitted: ' + this.state.value);
   //   const date = new Date().toISOString().slice(0, 10)
     setTimeout(() => {
  
        const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
        datafrm.append("id_user", this.state.id_user);
        datafrm.append("lat", localStorage.getItem('latitude'));
        datafrm.append("long1", localStorage.getItem('longitude'));
     
        //datafrm.append("id_workorder", this.state.id_workorder);
      
        api.post("updatelocation.php",datafrm,{
              headers: {
                  'content-type': 'multipart/form-data'
              }
          })
  
     .then(res => {
      if (res.status=== 200) {
          Swal.fire({
              title: 'ระบุสำเร็จ',
              icon: 'success',
            // showCancelButton: true,
              confirmButtonText: 'ตกลง',
            // cancelButtonText: 'No, keep it'
            }).then((result) => {
              // this.setState({
              //     namecar: '',
              //     namedriver: '',
              //     teldriver: '',
              // });
            //  this.toggle();  
          //  this.getworklist();
            //  this.props.getbanner();
            })
      }else{
       // message.error("อัพโหลไฟล์ไม่สำเร็จ!")
      }
  
    })
  
  
  }, 2000);
  
  //window.location.href = '/adminfaculty'
  //this.props.updateat();
  //this.getExpert()
   
    }

    render() {
        const location = {
            address: '1600 Amphitheatre Parkway, Mountain View, california.',
            lat: 37.42216,
            lng: -122.08427,
          }

        return (
            <div className="container"><br/>
                {/* <MDBCard>
                    <MDBCardBody>

                        <h1>รายชื่อสมาชิก</h1>

                        <div className="text-right">
                            <input onChange={this.onTextChange} placeholder="ค้นหาชื่อ" />
                        </div>


                        <MDBTable small style={{ border: "1px" }} border width="100%">
                            <MDBTableHead  >
                                <tr  >
                                    <th>Firstname</th>
                                    <th>Lastname</th>
                                    <th>Age</th>
                                    <th>sex</th>
                                    <th>จัดการ</th>
                                </tr>
                            </MDBTableHead>


                            <MDBTableBody>
                                {this.state.data.map((data) => {
                                    return <tr>
                                        <td>{data.name}</td>
                                        <td>{data.lname}</td>
                                        <td>{data.age}</td>
                                        <td>{data.sex}</td>
                                        <td>
                                            <Link to={{ pathname: "/AddCustomers/", data: data }} >
                                                <MDBBtn size="sm" color="primary"><MDBIcon far icon="edit" /> แก้ไข</MDBBtn>
                                            </Link>
                                            <MDBBtn size="sm" color="pink" onClick={() => this.delete(data.id)} ><MDBIcon far icon="trash-alt" /> ลบ</MDBBtn>
                                        </td>
                                    </tr>
                                })}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCardBody>
                </MDBCard> */}
<h6 className='text-center'><MDBIcon icon="map-marker-alt" /> ตำแหน่งปัจจุบันของคุณ <MDBBadge color="secondary">latitude: {localStorage.getItem('latitude')} </MDBBadge>  <MDBBadge color="success">longitude: {localStorage.getItem('longitude')}</MDBBadge>  </h6>
                <Showlocation />
                <p className='text-center'><MDBBtn color="unique" onClick={this.handleSubmit}><MDBIcon icon="map-marker-alt" /> ระบุตำแหน่ง</MDBBtn></p>

                <CurrentLocation
        centerAroundCurrentLocation
        google={this.props.google}
      >
        <Marker onClick={this.onMarkerClick} name={'Current Location'} />
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <h4>{this.state.selectedPlace.name}</h4>
          </div>
        </InfoWindow>
      </CurrentLocation>
            </div>
        )
    }
}

export default GoogleApiWrapper(
    {
        apiKey: 'AIzaSyALbZ4_sHMQ2CoxtfHXeh4Q1uR0hqBJSOI'
      }
    // (props) => ({
    //     apiKey: props.apiKey
    //   }
    // )
  )(Customers);
