import React, { Component } from 'react'
import { MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBAlert, MDBSelect , MDBInput, MDBRow, MDBCol,  MDBIcon, MDBCard,MDBJumbotron  } from 'mdbreact';
import { MDBSelectInput, MDBSelectOptions, MDBSelectOption } from "mdbreact";
import Axios from 'axios'
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip, Radio, Card  } from 'antd';
import { CheckOutlined,CloseOutlined } from '@ant-design/icons';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Sound from 'react-sound';
import music from '../img/56.mp3'


export default class Modalteachingschedule extends Component {
    constructor(props) {
        super(props);
this.state = {
   modal: false,
   
   inside:"",
   faculty:"",
   Expert:[],
   idExpert:"",
   id_send:"",
   id_room:"",
   name_room:"",
   amount:"",

   SUBJCODE:"",
   SUBJNAME:"",
   CRHR:"",
   id_major:"",
   major:"",
   poname:"",
   teacher_id:"",
   term:"",

   th:[],
   room:[],
   time:[],
   day:[],
   datacheck:[],
   datacheck2:[],
   datacheck3:[],

   roomset:'',
   timeset:'',
   timesetend:'',
   dayset:'',
   hourset:'',

   roomset2:'',
   timeset2:'',
   timesetend2:'',
   dayset2:'',
   hourset2:'',

   roomset3:'',
   timeset3:'',
   timesetend3:'',
   dayset3:'',
   hourset3:'',

   roomset4:'',
   timeset4:'',
   timesetend4:'',
   dayset4:'',
   hourset4:'',

   radio:"",
   group_id:"",
   sound:"",

   //สถานะตรวจสอบซ้ำ
   ststuscheck : null,
   ststuscheck2 : null,
   ststuscheck3 : null,

}

//this.handleChange = this.handleChange.bind(this);
this.handleChange2 = this.handleChange2.bind(this);
this.handleChange3 = this.handleChange3.bind(this);
this.handleSubmit = this.handleSubmit.bind(this);
this.toggle = this.toggle.bind(this);
this.handleChange4 = this.handleChange4.bind(this);
this.getroom = this.getroom.bind(this);
this.gettime = this.gettime.bind(this);
this.getday = this.getday.bind(this);
this.check = this.check.bind(this); //เซ็คข้อมูลซ้ำช่วงที่ 1 จากฐานข้อมูล
this.checkdatabase2 = this.checkdatabase2.bind(this); //เซ็คข้อมูลซ้ำช่วงที่ 2 จากฐานข้อมูล
this.checkdatabase3 = this.checkdatabase3.bind(this); //เซ็คข้อมูลซ้ำช่วงที่ 3 จากฐานข้อมูล

this.check2 = this.check2.bind(this); //เซ็คข้อมูลซ้ำช่วงที่ 2 จาก from
this.check3 = this.check3.bind(this); //เซ็คข้อมูลซ้ำช่วงที่ 3 จาก from
this.onChangeradio = this.onChangeradio.bind(this);

this.onChangeday = this.onChangeday.bind(this);
this.onChangetime = this.onChangetime.bind(this);
this.onChangetimeend = this.onChangetimeend.bind(this);
this.onChangeroom = this.onChangeroom.bind(this);
this.onChangehour = this.onChangehour.bind(this);

this.onChangeday2 = this.onChangeday2.bind(this);
this.onChangetime2 = this.onChangetime2.bind(this);
this.onChangetimeend2 = this.onChangetimeend2.bind(this);
this.onChangeroom2 = this.onChangeroom2.bind(this);
this.onChangehour2 = this.onChangehour2.bind(this);

this.onChangeday3 = this.onChangeday3.bind(this);
this.onChangetime3 = this.onChangetime3.bind(this);
this.onChangetimeend3 = this.onChangetimeend3.bind(this);
this.onChangeroom3 = this.onChangeroom3.bind(this);
this.onChangehour3 = this.onChangehour3.bind(this);

this.onChangeday4 = this.onChangeday4.bind(this);
this.onChangetime4 = this.onChangetime4.bind(this);
this.onChangetimeend4 = this.onChangetimeend4.bind(this);
this.onChangeroom4 = this.onChangeroom4.bind(this);
this.onChangehour4 = this.onChangehour4.bind(this);
}


toggle = () => {
  this.setState({
    modal: !this.state.modal
  });
  this.getroom();
  this.gettime();
  this.getday();
  this.props.getteachingschedule();
  this.setState({ sound:'' })
}


 componentDidMount() {
      const { SUBJCODE } = this.props.SUBJCODE
    //  console.log(this.props.id_send);
      this.setState({ SUBJCODE:this.props.SUBJCODE})
      this.setState({ SUBJNAME:this.props.SUBJNAME})
      this.setState({ CRHR:this.props.CRHR})
      this.setState({ id_major:this.props.id_major})
      this.setState({ major:this.props.major})
      this.setState({ poname:this.props.poname})
      this.setState({ teacher_id:this.props.teacher_id})
      this.setState({ term:this.props.term})
      this.setState({ group_id:this.props.group_id})
    //  this.setState({ amount:this.props.amount})
  }


  handleChange2(event) {    this.setState({id_room: event.target.value});  }
  handleChange3(event) {    this.setState({name_room: event.target.value});  }
  handleChange4(event) {    this.setState({amount: event.target.value});  }


 
  onBlur() {
    console.log('blur');
  }
  
   onFocus() {
    console.log('focus');
   // this.setState({ sound:"" })
  }
  
   onSearch(val) {
    console.log('search:', val);
  }

//เช็คจากฐานข้อมูลช่วงที่1
  check() {
  
    setTimeout(() => {
      const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
      datafrm.append("timeset", this.state.timeset);
      datafrm.append("timesetend", this.state.timesetend);
      datafrm.append("dayset", this.state.dayset);
      datafrm.append("roomset", this.state.roomset);
      datafrm.append("hourset", this.state.hourset);
      datafrm.append("id_major", this.state.id_major);
      datafrm.append("SUBJCODE", this.state.SUBJCODE.substring(0, 7));
      datafrm.append("teacher_id", this.state.teacher_id);
      datafrm.append("term", this.state.term);
    
   Axios.post("https://git.kpru.ac.th/FrontEnd_Tabian/load/getcheck/",datafrm,{
    headers: {
        'content-type': 'multipart/form-data'
           }
   })
   .then(res => {
    //    console.log(res);
        if (res.data.length !== 0) {
          this.setState({ datacheck: res.data, ststuscheck:false})
        //  alert("บันทึกสำเร็จ")
              if (res.data[0].group_id !== this.state.group_id) {
                
                  Swal.fire({
                      title: 'ช่วงเวลาที่เลือกไม่ว่าง',
                      text: 'ซ้ำกับวิชา '+res.data[0].SUBJCODE + res.data[0].SUBJNAME+' หมู่เรียน '+ res.data[0].GROUP_NO+' อาจารย์'+ res.data[0].poname ,
                      icon: 'warning',
                    // showCancelButton: true,
                      confirmButtonText: 'ตกลง',
                    // cancelButtonText: 'No, keep it'
                    }).then((result) => {
                    
                    })
                    this.setState({ sound:'1' })
              }else{
                this.setState({ ststuscheck:true })
              }
                
          }else{
             this.setState({ ststuscheck:true })
          }

        })
       // this.toggle()
      //  this.props.getclassroom()
}, 600);
  }

  //เช็คจากฐานข้อมูลช่วงที่2
  checkdatabase2() {
  
    setTimeout(() => {
      const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
      datafrm.append("timeset", this.state.timeset2);
      datafrm.append("timesetend", this.state.timesetend2);
      datafrm.append("dayset", this.state.dayset2);
      datafrm.append("roomset", this.state.roomset2);
      datafrm.append("hourset", this.state.hourset2);
      datafrm.append("id_major", this.state.id_major);
      datafrm.append("SUBJCODE", this.state.SUBJCODE.substring(0, 7));
      datafrm.append("teacher_id", this.state.teacher_id);
      datafrm.append("term", this.state.term);
    
    Axios.post("https://git.kpru.ac.th/FrontEnd_Tabian/load/getcheck/",datafrm,{
    headers: {
            'content-type': 'multipart/form-data'
           }
    })
   .then(res => {
    //    console.log(res);
        if (res.data.length !== 0) {
          this.setState({ datacheck2: res.data, ststuscheck2:false})
        //  alert("บันทึกสำเร็จ")
                 

                    if (res.data[0].group_id !== this.state.group_id) {
                
                      Swal.fire({
                        title: 'ช่วงเวลาที่เลือกถูกเลือกแล้ว',
                        icon: 'warning',
                        text: 'ซ้ำกับวิชา'+res.data[0].SUBJCODE + res.data[0].SUBJNAME+' หมู่เรียน '+ res.data[0].GROUP_NO+' อาจารย์'+ res.data[0].poname ,
                      // showCancelButton: true,
                        confirmButtonText: 'ตกลง',
                      // cancelButtonText: 'No, keep it'
                      }).then((result) => {
                      
                      })
                  }else{
                   // this.setState({ ststuscheck2:true })
                  }
          }else{
           // this.setState({ ststuscheck2:true })
          }
        })
       // this.toggle()
      //  this.props.getclassroom()
}, 600);
  }

  //เช็คจากฐานข้อมูลช่วงที่3
  checkdatabase3() {
  
    setTimeout(() => {
      const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล3
      datafrm.append("timeset", this.state.timeset3);
      datafrm.append("timesetend", this.state.timesetend3);
      datafrm.append("dayset", this.state.dayset3);
      datafrm.append("roomset", this.state.roomset3);
      datafrm.append("hourset", this.state.hourset3);
      datafrm.append("id_major", this.state.id_major);
      datafrm.append("SUBJCODE", this.state.SUBJCODE.substring(0, 7));
      datafrm.append("teacher_id", this.state.teacher_id);
      datafrm.append("term", this.state.term);
    
    Axios.post("https://git.kpru.ac.th/FrontEnd_Tabian/load/getcheck/",datafrm,{
    headers: {
            'content-type': 'multipart/form-data'
           }
    })
   .then(res => {
    //    console.log(res);
        if (res.data.length !== 0) {
          this.setState({ datacheck3: res.data, ststuscheck3:false})
        //  alert("บันทึกสำเร็จ")
            
                    if (res.data[0].group_id !== this.state.group_id) {
                
                      Swal.fire({
                        title: 'ช่วงเวลาที่เลือกถูกเลือกแล้ว',
                        icon: 'warning',
                        text: 'ซ้ำกับวิชา'+res.data[0].SUBJCODE + res.data[0].SUBJNAME+' หมู่เรียน '+ res.data[0].GROUP_NO+' อาจารย์'+ res.data[0].poname ,
                      // showCancelButton: true,
                        confirmButtonText: 'ตกลง',
                      // cancelButtonText: 'No, keep it'
                      }).then((result) => {
                      })
                  }else{
                       // this.setState({ ststuscheck2:true })
                  }
          }else{
            //  this.setState({ ststuscheck3:true })
          }
        })
       // this.toggle()
      //  this.props.getclassroom()
}, 600);
  }

  
  //เซ็คซ้ำช่วงที่ 2 จากfrom
  check2() {
         if (this.state.dayset === this.state.dayset2 & this.state.timeset === this.state.timeset2 & this.state.roomset === this.state.roomset2  ) {
          Swal.fire({
                          title: 'ช่วงเวลาที่ 2 ซ้ำกับ ช่วงเวลาที่ 1 กรุณาตรวจสอบ',
                          icon: 'warning',
                        // showCancelButton: true,
                          confirmButtonText: 'ตกลง',
                        // cancelButtonText: 'No, keep it'
                        }).then((result) => {
           })
           this.setState({ ststuscheck2:false })
        }else{
          this.setState({ ststuscheck2:true })
         
        }
        this.checkdatabase2()
        
  }

  //เซ็คซ้ำช่วงที่ 3 ซ้ำกับ ช่วงที่ 1 กับ 2 หรือไม่จากfrom
  check3() {
    if (this.state.dayset === this.state.dayset3 & this.state.timeset === this.state.timeset3 & this.state.roomset === this.state.roomset3  ) {
     Swal.fire({
                     title: 'ช่วงเวลาที่ 3 ซ้ำกับ ช่วงเวลาที่ 1 กรุณาตรวจสอบ',
                     icon: 'warning',
                   // showCancelButton: true,
                     confirmButtonText: 'ตกลง',
                   // cancelButtonText: 'No, keep it'
                   }).then((result) => {
              })
              this.setState({ ststuscheck3:false })
             this.checkdatabase3()
   }else if (this.state.dayset2 === this.state.dayset3 & this.state.timeset2 === this.state.timeset3 & this.state.roomset2 === this.state.roomset3) {
    Swal.fire({
                    title: 'ช่วงเวลาที่ 3 ซ้ำกับ ช่วงเวลาที่ 2 กรุณาตรวจสอบ',
                    icon: 'warning',
                  // showCancelButton: true,
                    confirmButtonText: 'ตกลง',
                  // cancelButtonText: 'No, keep it'
                  }).then((result) => {
              })
             
        this.checkdatabase3()
         this.setState({ ststuscheck3:false })
   }else{

    this.setState({ ststuscheck3:true })
    this.checkdatabase3()
    
   }

  
 }



  handleSubmit() {
    // alert('A name was submitted: ' + this.state.value);
   setTimeout(() => {

      const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
      datafrm.append("timeset", this.state.timeset);
      datafrm.append("timesetend", this.state.timesetend);
      datafrm.append("dayset", this.state.dayset);
      datafrm.append("roomset", this.state.roomset);
      datafrm.append("hourset", this.state.hourset);
      datafrm.append("timeset2", this.state.timeset2);
      datafrm.append("timesetend2", this.state.timesetend2);
      datafrm.append("dayset2", this.state.dayset2);
      datafrm.append("roomset2", this.state.roomset2);
      datafrm.append("hourset2", this.state.hourset2);
      datafrm.append("timeset3", this.state.timeset3);
      datafrm.append("timesetend3", this.state.timesetend3);
      datafrm.append("dayset3", this.state.dayset3);
      datafrm.append("roomset3", this.state.roomset3);
      datafrm.append("hourset3", this.state.hourset3);
      datafrm.append("id_major", this.state.id_major);
      datafrm.append("SUBJCODE", this.state.SUBJCODE.substring(0, 7));
      datafrm.append("teacher_id", this.state.teacher_id);
      datafrm.append("term", this.state.term);
    
      
   Axios.post("https://git.kpru.ac.th/FrontEnd_Tabian/load/updategroupid/",datafrm,{
    headers: {
        'content-type': 'multipart/form-data'
           }
   })

   .then(res => {
    //    console.log(res);
        if (res.status === 200) {
        //  alert("บันทึกสำเร็จ")
                  Swal.fire({
                      title: 'บันทึกข้อมูลสำเร็จ',
                      icon: 'success',
                    // showCancelButton: true,
                      confirmButtonText: 'ตกลง',
                    // cancelButtonText: 'No, keep it'
                    }).then((result) => {
                    
                    })
                 }
        })
        this.toggle()
      //  this.props.getclassroom()

}, 2000);


//window.location.href = '/adminfaculty'
//this.props.updateat();
//this.getExpert()
 
  }


  getroom() {
    //  console.log(`selected ${value}`);
    Axios.get('https://git.kpru.ac.th/FrontEnd_Tabian/load/getclassroom')
    .then(res => {
      this.setState({ room: res.data})
    })
     //  const idemp = value;
     //  this.setState({ idemp });
    }


  gettime() {
      //  console.log(`selected ${value}`);
      Axios.get('https://git.kpru.ac.th/FrontEnd_Tabian/load/gettime')
      .then(res => {
        this.setState({ time: res.data})
      })
       //  const idemp = value;
       //  this.setState({ idemp });
   }


  getday() {
        //  console.log(`selected ${value}`);
        Axios.get('https://git.kpru.ac.th/FrontEnd_Tabian/load/getdate')
        .then(res => {
          this.setState({ day: res.data})
        })
         //  const idemp = value;
         //  this.setState({ idemp });
   }


//ช่วงที่1
  onChangeday(value) {
     this.setState({ dayset:value})
  }
  onChangetime(value) {
    this.setState({ timeset:value})
  }
  onChangetimeend(value) {
    this.setState({ timesetend:value})
  }
  onChangeroom(value) {
    this.setState({ sound:"" })
    this.setState({ roomset:value})
    setTimeout(() => {
       this.check()
    }, 300);
  }
  onChangehour =  event => {
    this.setState({
      hourset: event.target.value
    })
  }

  //ช่วงที่2
  onChangeday2(value) {
    this.setState({ dayset2:value})
 }
 onChangetime2(value) {
   this.setState({ timeset2:value})
 }
 onChangetimeend2(value) {
   this.setState({ timesetend2:value})
 }
 onChangeroom2(value) {
   this.setState({ roomset2:value})
   setTimeout(() => {
      this.check2()
   }, 500);
 }
 onChangehour2 =  event => {
   this.setState({
     hourset2: event.target.value
   })
 }

  //ช่วงที่3
 onChangeday3(value) {
    this.setState({ dayset3:value})
 }
 onChangetime3(value) {
   this.setState({ timeset3:value})
 }
 onChangetimeend3(value) {
   this.setState({ timesetend3:value})
 }
 onChangeroom3(value) {
   this.setState({ roomset3:value})
   setTimeout(() => {
      this.check3()
   }, 500);
 }
 onChangehour3 =  event => {
   this.setState({
     hourset3: event.target.value
   })
 }

 //ช่วงที่4
 onChangeday4(value) {
  this.setState({ dayset4:value})
}
onChangetime4(value) {
 this.setState({ timeset4:value})
}
onChangetimeend4(value) {
 this.setState({ timesetend4:value})
}
onChangeroom4(value) {
 this.setState({ roomset4:value})
 setTimeout(() => {
    this.check()
 }, 500);
}
onChangehour4 =  event => {
 this.setState({
   hourset4: event.target.value
 })
}




 onChangeradio = e => {
  //  console.log('radio checked', e.target.value);
   // setValue(e.target.value);
    this.setState({
      radio: e.target.value
    })
  };

 


    render() {
        //  console.log(this.state.roomset)
        //  console.log(this.state.timeset)
        //  console.log(this.state.dayset)
        //  console.log(this.state.hourset)
          console.log(this.state.datacheck)
         // const [value] = React.useState(1);
        return (
            <MDBContainer>
            <MDBBtn onClick={this.toggle} color="deep-purple" size="sm">เพิ่มข้อมูล</MDBBtn>

            <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg">

              <MDBModalHeader toggle={this.toggle}><MDBIcon icon="plus-circle" /> เพิ่ม วัน เวลา ห้องเรียน</MDBModalHeader>
              {/* <MDBContainer> */}
              <MDBModalBody>
              <div>
             {console.log(this.state.th)} 

             <Alert
              message={<><p>วิชา : {this.state.SUBJCODE} {this.state.SUBJNAME} {this.state.CRHR}</p>  
              {/* <p>วิชา :  {this.state.SUBJCODE.substring(0, 7)}</p>   */}
              <p>หมู่เรียน : {this.state.id_major} {this.state.major}</p> 
              <p>อาจารย์ : {this.state.poname}</p> </>}
              description=""
              type="success"
            />
            <br/>
       
          
  <MDBJumbotron> 
 <h4 className="text-center">เลือกช่วงเวลา</h4>
  <MDBRow className="text-center d-flex justify-content-center">
   <br/>
  <div className="d-flex justify-content-center">
    <Radio.Group onChange={this.onChangeradio} >
      <Radio value={2}>2 ช่วงเวลา</Radio>
      <Radio value={3}>3 ช่วงเวลา</Radio>
      {/* <Radio value={4}>4 ช่วงเวลา</Radio> */}
    </Radio.Group>
  </div>
  </MDBRow>
  <br/>

       
    <p><Badge status="success" /> ช่วงที่ 1 </p>
    <MDBRow>
    <MDBCol sm="2" md="2"> <p>วัน </p>   
        <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกวัน"
                    optionFilterProp="children"
                    onChange={this.onChangeday}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   
                   {this.state.day.map((day,index )  => 
                   <option value={day.id_day}>{day.name_day}</option>
                   )}
            </Select>
    </MDBCol>
    <MDBCol sm="2" md="2"> <p>เวลาเริ่ม </p>
        <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกเวลา"
                    optionFilterProp="children"
                    onChange={this.onChangetime}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   
                   {this.state.time.map((time,index )  => 
                  //  <option value={time.name_time}>{time.name_time}</option>
                   <option value={time.name_time}>{time.name_time}</option>
                   )}
            </Select>
    </MDBCol>

    <MDBCol sm="2"> <p>เวลาสิ้นสุด </p>
        <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกเวลา"
                    optionFilterProp="children"
                    onChange={this.onChangetimeend}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   
                   {this.state.time.map((time,index )  => 
                   <option value={time.name_time}>{time.name_time}</option>
                   )}
            </Select>
    </MDBCol>

    <MDBCol sm="2"> <p>จำนวนชั่วโมง </p>
        <Input placeholder="จำนวนชั่วโมง" 
         onChange={this.onChangehour} 
        // value="fdf"
         style={{ width: 120 }}
         />
    </MDBCol>

    <MDBCol sm="2">  <p>สถานที่ </p>
              <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกสถานที่"
                    optionFilterProp="children"
                    onChange={this.onChangeroom}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   
                   {this.state.room.map((room,index )  => 
                   <option value={room.id_room}>{room.id_room}</option>
                   )}
            </Select>
      </MDBCol>
 

      <MDBCol sm="2"> <p>t/f </p>
      { this.state.ststuscheck === true ?<><CheckOutlined style={{ fontSize: '16px', color: '#007E33' }} /> ผ่าน  </> :<></> }
      { this.state.ststuscheck === false ?<> <CloseOutlined style={{ fontSize: '16px', color: '#CC0000' }} />ไม่ผ่าน </> :<></> }
      </MDBCol>

     </MDBRow>
     <br/>



     { this.state.radio >= 2 ? <>
      
      {/* ช่วงที่ 2 */}
    <p><Badge status="red" /> ช่วงที่ 2 </p>
    <MDBRow>
    <MDBCol sm="2" md="2"> <p>วัน </p>   
        <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกวัน"
                    optionFilterProp="children"
                    onChange={this.onChangeday2}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   
                   {this.state.day.map((day,index )  => 
                   <option value={day.id_day}>{day.name_day}</option>
                   )}
            </Select>
    </MDBCol>
    <MDBCol sm="2" md="2"> <p>เวลาเริ่ม </p>
        <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกเวลา"
                    optionFilterProp="children"
                    onChange={this.onChangetime2}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   
                   {this.state.time.map((time,index )  => 
                   <option value={time.name_time}>{time.name_time}</option>
                   )}
            </Select>
    </MDBCol>
    <MDBCol sm="2"> <p>เวลาสิ้นสุด </p>
        <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกเวลา"
                    optionFilterProp="children"
                    onChange={this.onChangetimeend2}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   
                   {this.state.time.map((time,index )  => 
                   <option value={time.name_time}>{time.name_time}</option>
                   )}
            </Select>
    </MDBCol>
    <MDBCol sm="2"> <p>จำนวนชั่วโมง </p>
      
        <Input placeholder="จำนวนชั่วโมง" 
         onChange={this.onChangehour2} 
         style={{ width: 120 }}
         />
    </MDBCol>
    <MDBCol sm="2">  <p>สถานที่ </p>
              <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกสถานที่"
                    optionFilterProp="children"
                    onChange={this.onChangeroom2}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   {this.state.room.map((room,index )  => 
                   <option value={room.id_room}>{room.id_room}</option>
                   )}
            </Select>
      </MDBCol>
      <MDBCol sm="2">  <p>t/f </p>
      { this.state.ststuscheck2 === true ?<><CheckOutlined style={{ fontSize: '16px', color: '#007E33' }} />  </> :<></> }
      { this.state.ststuscheck2 === false ?<> <CloseOutlined style={{ fontSize: '16px', color: '#CC0000' }} /> </> :<></> }
      </MDBCol>
     </MDBRow>

      </> :<></>}
      <br/>


      { this.state.radio >= 3 ? <>
      {/* ช่วงที่ 3 */}
    <p><Badge status="cyan" /> ช่วงที่ 3 </p>
    <MDBRow>
    <MDBCol sm="2" md="2"> <p>วัน </p>   
        <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกวัน"
                    optionFilterProp="children"
                    onChange={this.onChangeday3}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   
                   {this.state.day.map((day,index )  => 
                   <option value={day.id_day}>{day.name_day}</option>
                   )}
            </Select>
    </MDBCol>
    <MDBCol sm="2" md="2"> <p>เวลาเริ่ม </p>
        <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกเวลา"
                    optionFilterProp="children"
                    onChange={this.onChangetime3}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   
                   {this.state.time.map((time,index )  => 
                   <option value={time.name_time}>{time.name_time}</option>
                   )}
            </Select>
    </MDBCol>

    <MDBCol sm="2"> <p>เวลาสิ้นสุด </p>
        <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกเวลา"
                    optionFilterProp="children"
                    onChange={this.onChangetimeend3}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   
                   {this.state.time.map((time,index )  => 
                   <option value={time.name_time}>{time.name_time}</option>
                   )}
            </Select>
    </MDBCol>

    <MDBCol sm="2"> <p>จำนวนชั่วโมง </p>
      
        <Input placeholder="จำนวนชั่วโมง" 
         onChange={this.onChangehour3} 
         style={{ width: 120 }}
         />
    </MDBCol>

    <MDBCol sm="2">  <p>สถานที่ </p>
              <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกสถานที่"
                    optionFilterProp="children"
                    onChange={this.onChangeroom3}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   {this.state.room.map((room,index )  => 
                   <option value={room.id_room}>{room.id_room}</option>
                   )}
            </Select>
      </MDBCol>
      <MDBCol sm="2">  <p>t/f </p>
      { this.state.ststuscheck3 === true ?<><CheckOutlined style={{ fontSize: '16px', color: '#007E33' }} />  </> :<></> }
      { this.state.ststuscheck3 === false ?<> <CloseOutlined style={{ fontSize: '16px', color: '#CC0000' }} /> </> :<></> }
      </MDBCol>
     </MDBRow>
      </> :<></>}
              <br/>
      { this.state.radio >= 4 ? <>
      {/* ช่วงที่ 3 */}
    <p><Badge status="purple" /> ช่วงที่ 4 </p>
    <MDBRow>
    <MDBCol sm="2" md="2"> <p>วัน </p>   
        <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกวัน"
                    optionFilterProp="children"
                    onChange={this.onChangeday4}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   
                   {this.state.day.map((day,index )  => 
                   <option value={day.id_day}>{day.name_day}</option>
                   )}
            </Select>
    </MDBCol>
    <MDBCol sm="2" md="2"> <p>เวลาเริ่ม </p>
        <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกเวลา"
                    optionFilterProp="children"
                    onChange={this.onChangetime4}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   
                   {this.state.time.map((time,index )  => 
                   <option value={time.name_time}>{time.name_time}</option>
                   )}
            </Select>
    </MDBCol>

    <MDBCol sm="2"> <p>เวลาสิ้นสุด </p>
        <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกเวลา"
                    optionFilterProp="children"
                    onChange={this.onChangetimeend4}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   
                   {this.state.time.map((time,index )  => 
                   <option value={time.name_time}>{time.name_time}</option>
                   )}
            </Select>
    </MDBCol>

    <MDBCol sm="2"> <p>จำนวนชั่วโมง </p>
      
        <Input placeholder="จำนวนชั่วโมง" 
         onChange={this.onChangehour4} 
         style={{ width: 120 }}
         />
    </MDBCol>

    <MDBCol sm="2">  <p>สถานที่ </p>
              <Select
                    showSearch
                    style={{ width: 120 }}
                    placeholder="เลือกสถานที่"
                    optionFilterProp="children"
                    onChange={this.onChangeroom4}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    onSearch={this.onSearch}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                   {this.state.room.map((room,index )  => 
                   <option value={room.id_room}>{room.id_room}</option>
                   )}
            </Select>
      </MDBCol>
     </MDBRow>
      </> :<></>}

      </MDBJumbotron>
               {/* <p>รหัสห้อง </p>   
               <MDBInput type="text" label="รหัสห้อง" value={this.state.id_room} onChange={this.handleChange2} outline />

               <p>ชื่อห้อง </p>
               <MDBInput type="text" label="ชื่อห้อง" value={this.state.name_room} onChange={this.handleChange3} outline />

               <p>จำนวนที่นั่ง </p>   
               <MDBInput type="text" label="จำนวนที่นั่ง" value={this.state.amount} onChange={this.handleChange4} outline />
               */}
             
               
              </div>
              </MDBModalBody>
              {/* </MDBContainer> */}
              { this.state.sound  === '1' ?<>
               <Sound
                    url={music}
                    playStatus={Sound.status.PLAYING}
                    playFromPosition={300 /* in milliseconds */}
                    onLoading={this.handleSongLoading}
                    onPlaying={this.handleSongPlaying}
                    onFinishedPlaying={this.handleSongFinishedPlaying}
                  />
              </>:<></>}
             
               
              <MDBModalFooter>
                <MDBBtn color="secondary" onClick={this.toggle}>ปิด</MDBBtn>
                { this.state.ststuscheck  === true ? <>
                <MDBBtn color="primary" onClick={this.handleSubmit} >บันทึก</MDBBtn>
                </>:<>
                <MDBBtn color="primary" disabled  >บันทึก</MDBBtn>
                </>}
                
              </MDBModalFooter>

{/* {this.state.sou  === true} */}
             
            </MDBModal>
          </MDBContainer>
          
        )
    }
}
