import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem } from 'mdbreact';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBMask, MDBView, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader } from 'mdbreact';
import { Form, Input, Button, Checkbox, message } from 'antd';

import Axios from 'axios'



import Swal from 'sweetalert2'
import { api } from '../../api';



export default class Registerstudent extends Component {
    

        constructor(props) {
            super(props);
            this.state = {
                value: '',
                Psaaword:'',
                modal: false,
                collapse: false,
                isWideEnough: false,

                code:'',
                bd:'',
            };
           
            this.handleSubmit = this.handleSubmit.bind(this);
            this.toggle = this.toggle.bind(this);
            this.onClick = this.onClick.bind(this);
            this.onTextChange = this.onTextChange.bind(this);
            this.getstudent1 = this.getstudent1.bind(this);
          }

          onClick() {
            this.setState({
              collapse: !this.state.collapse,
            });
          }

          toggle = () => {
            this.setState({
              modal: !this.state.modal
            });
          }

          onTextChange = (e) => {
            this.setState({ [e.target.name]: e.target.value })
         }

        
         getstudent1() {
          Axios.get('https://arit.kpru.ac.th/ap/api/?nu=tabianStudentCheck&code=621121128&password=28/04/42&Token=S2FtcGhhZW5nIFBoZXQgUmFqYWJoYXQgVW5pdmVyc2l0eQ==',
          {headers: {"Access-Control-Allow-Origin": "*"}}
          )
            .then((res) => {
                this.setState({ data: res.data })
            })
          }
        

          handleSubmit(event) {
            // alert('A name was submitted: ' + this.state.value);
             event.preventDefault();

            const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
         datafrm.append("txtemail", this.state.value);
         datafrm.append("txtpass", this.state.Psaaword);

         
         const user = {
          "txtemail": this.state.value,
          "txtpass": this.state.Psaaword
        };

         fetch('https://mua.kpru.ac.th/FrontEnd_Mis/login/login/', {
          method: 'POST',
          body: JSON.stringify(user),
          headers: {
        
          }
        })
          .then(res => res.json())
          .then((res) => {
              console.log(res);
              if (res[0].loginstatus === "1" ) {

           
                //  alert("เข้าสู่ระบบสำเร็จ")

                   localStorage.setItem('name', res[0].frist_name +"  "+ res[0].last_name);
      
                   message.success("เข้าสู่ระบบสำเร็จ")
                   localStorage.setItem('loginstatus', res[0].loginstatus);
                   localStorage.setItem('type2', res[0].employee_id);
                   localStorage.setItem('admin_type', 2);
                 
              //   window.location.href = '/components/administrator/reportall'
             // window.location.href = 'https://conference.kpru.ac.th/adminconference-sd/adminfaculty'
            //  window.location.href = 'https://e-student.kpru.ac.th/grade/adminfaculty'
              window.location.href = '/vaccinekpru/Stuall'
              }else{
                 // message.error("เข้าสู่ระบบไม่สำเร็จ")
                  


                 Swal.fire(
                  'ไม่สามารถเข้าถึงระบบได้',
                  'กรุณาลองอีกครั้ง',
                  'question'
                )
                //  window.location.href = '/'
              }
            
          })
          .catch(err => {
            console.log('request failed', err);
          })
         

          }
      
       




    render() {
      console.log(this.state.code)

        return (
       
                <MDBContainer >
           
           <MDBCarousel
          activeItem={1}
          length={3}
          showControls={true}
          showIndicators={true}
          className="z-depth-1"
        >
          <MDBCarouselInner>
            <MDBCarouselItem itemId="1">
              <MDBView>
                <img
                  className="d-block w-100"
                  src="https://mdbootstrap.com/img/Others/documentation/img%20(136)-mini.webp"
                  alt="First slide"
                />
                <MDBMask overlay="black-light" />
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="2">
              <MDBView>
                <img
                  className="d-block w-100"
                  src="https://mdbootstrap.com/img/Others/documentation/img%20(137)-mini.webp"
                  alt="Second slide"
                />
                <MDBMask overlay="black-strong" />
              </MDBView>
            </MDBCarouselItem>
            <MDBCarouselItem itemId="3">
              <MDBView>
                <img
                  className="d-block w-100"
                  src="https://mdbootstrap.com/img/Others/documentation/img%20(141)-mini.webp"
                  alt="Third slide"
                />
                <MDBMask overlay="black-slight" />
              </MDBView>
            </MDBCarouselItem>
          </MDBCarouselInner>
        </MDBCarousel>

                 

<br/>
<h2 className="text-center"> <MDBIcon icon="running" /> ลงทะเบียนนักศึกษา</h2>
      <MDBRow >
        
        <MDBCol md="12" className="d-flex justify-content-center">
        <MDBCard style={{ width: "32rem", marginTop: "1rem" }}>
    <MDBCardBody>
      <MDBCardTitle className="text-center">สำหรับนักศึกษา</MDBCardTitle>
        <div className="text-center">
        <MDBIcon icon="user-tie" size="4x" className="text-center"/>
        </div> 
       <br/>
                            <div className="mb-2">
                                <label>รหัสนักศึกษา</label>
                                <input className="form-control" onChange={this.onTextChange} name="code"
                                    value={this.state.code} placeholder="กรุณาระบุรหัสนักศึกษา" required />
                            </div>
                            <div className="mb-2">
                                <label>วัน/เดือน/ปีเกิด เช่น 10/01/65</label>
                                <input className="form-control" onChange={this.onTextChange} name="bd"
                                    value={this.state.bd} placeholder="กรุณาระบุวัน/เดือน/ปีเกิด" required />
                            </div>
      
      <div className="text-center">
        <MDBBtn color="deep-orange" onClick={this.getstudent1} >ลงทะเบียน</MDBBtn>
      </div>
      
    </MDBCardBody>
  </MDBCard>
        </MDBCol>


       
      </MDBRow>

<br/>
      <MDBRow>
        <MDBCol md="4"></MDBCol>
        <MDBCol md="4" className="d-flex justify-content-center">
        <div className="text-center">
          {/* <Modallogin /> */}
        </div>
          </MDBCol>
        <MDBCol md="4"></MDBCol>
     </MDBRow>

                   
                    </MDBContainer>
                   
     
        )
    }
}
