import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';
import Modaladdfile from './Modaladdfile';
import { api } from '../../api';
import Modaladdfileprocurement from './Modaladdfileprocurement';
import logo2 from '../img/logo2.png'

export default class Addfileprocurement extends Component {
    constructor(props) {
        super(props);
        this.state = {
          emp:[],
          news:[],
          procurement:[],
          ita:[],
        };
       this.getnews = this.getnews.bind(this);
       this.delete = this.delete.bind(this);
        this.getprocurement = this.getprocurement.bind(this);
        this.getita = this.getita.bind(this);
      }

      componentDidMount() {
      this.getnews();
      this.getprocurement();
      this.getita();
       
      }

      getnews() {
        setTimeout(() => {
           //ภายใน
           api.get('selectnews.php')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ news: res.data})
           }) 
         }, 0);
      }

      delete = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
        api.get('deletenews.php?id=' + id)
            .then((res) => {
                this.getnews()
            })
      }

      //จัดซื้อจัดจ้าง
      getprocurement() {
        setTimeout(() => {
           //ภายใน
           api.get('selectprocurement.php?id=1&id_member='+localStorage.getItem('id_member'))
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ procurement: res.data})
           }) 
         }, 0);
      }

      //ita
      getita() {
        setTimeout(() => {
           //ภายใน
           api.get('selectprocurement.php?id=2&id_member='+localStorage.getItem('id_member'))
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ ita: res.data})
           }) 
         }, 0);
      }


  render() {
    console.log(this.state.news)

    const data = {
      columns: [
        {
          label: 'ลำดับ',
          field: 'list',
          sort: 'list',
          width: 150
        },
        {
          label: 'เอกสาร',
          field: 'NAME',
          sort: 'NAME',
          width: 200
        },
         {
          label: 'วันที่เพิ่ม',
          field: 'DATES',
          sort: 'DATES',
          width: 200
        },
       

      ],

      rows: [...this.state.procurement.map((data, i) => (
       
        {
           list: <>{i+1}</>,
           NAME: <>{data.procurement_name}</>,
           DATES: <><MDBBtn color="indigo" size="sm" href={`http://kosamphi.kpo.go.th/kosamphigo/kosamphibackend/uploadsprocurement/${data.procurement_file}`}  target="_blank">PDF</MDBBtn></>,
         
          //  random: <h5 searchvalue={data.Curriculumopen_TEAM} >tourist <span className="badge badge-danger">narrow</span></h5>,

        }
       ))] 
  
  };

  const persons = <MDBDataTable
  // searching ={true}
   searchLabel="ค้นหา รายการ"
   striped
   bordered
   small 
   entries={20}
  // entriesOptions={["10", "20"]}
   order={["age", "desc"]}
   data={data}
   entriesLabel="จำนวนที่แสดง "
   infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}
   paginationLabel={["ย้อนกลับ", "ถัดไป"]}
   responsive 
   sortRows={['random']}
/>


const dataita = {
  columns: [
    {
      label: 'ลำดับ',
      field: 'list',
      sort: 'list',
      width: 150
    },
    {
      label: 'เอกสาร',
      field: 'NAME',
      sort: 'NAME',
      width: 200
    },
     {
      label: 'วันที่เพิ่ม',
      field: 'DATES',
      sort: 'DATES',
      width: 200
    },
   

  ],

  rows: [...this.state.ita.map((data, i) => (
   
    {
       list: <>{i+1}</>,
       NAME: <>{data.procurement_name}</>,
       DATES: <><MDBBtn color="indigo" size="sm" href={`http://kosamphi.kpo.go.th/kosamphigo/kosamphibackend/uploadsprocurement/${data.procurement_file}`}  target="_blank">PDF</MDBBtn></>,
     
      //  random: <h5 searchvalue={data.Curriculumopen_TEAM} >tourist <span className="badge badge-danger">narrow</span></h5>,

    }
   ))] 

};

const ita = <MDBDataTable
// searching ={true}
searchLabel="ค้นหา รายการ"
striped
bordered
small 
entries={20}
// entriesOptions={["10", "20"]}
order={["age", "desc"]}
data={dataita}
entriesLabel="จำนวนที่แสดง "
infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}
paginationLabel={["ย้อนกลับ", "ถัดไป"]}
responsive 
sortRows={['random']}
/>


    return (
     
      <MDBContainer>
        <br/>
        <h2 className='text-center'>ระบบจัดการไฟล์</h2>
        <h4 className='text-center'>ผู้ใช้งาน : {localStorage.getItem('name_member')}</h4>
       <Modaladdfileprocurement getprocurement={this.getprocurement} getita={this.getita} />
       <br/>

      <h4><MDBIcon far icon="file-pdf" /> เอกสารจัดซื้อจัดจ้าง</h4>
        {persons}
        <hr/>
        <h4><MDBIcon far icon="file-pdf" /> เอกสาร ITA</h4>
         {ita}
      </MDBContainer>
    )
  }
}
