import React, { Component } from 'react'
import { MDBContainer, MDBAlert,  MDBTable, MDBTableBody, MDBTableHead, MDBBtn, MDBIcon, MDBCol, MDBCard, MDBCardBody  } from 'mdbreact';
import {  MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, MDBNavLink } from "mdbreact";
import Axios from 'axios'
import { Steps, Alert  } from 'antd';
//import Modalarticlefaculty from './Modalarticlefaculty';
//import Modalsentedit from './Modalsentedit';
//import Modalsuccess from './Modalsuccess';
import { Chart } from "react-google-charts";
import Modaleditstatus from './Modaleditstatus';




export default class  registerproportion extends Component {

    constructor(props) {
        super(props);
        this.state = {
          articlesci1:[],
          articlestapat2:[],
          articlestapat3:[],
          articlestapat4:[],
          articlestapat5:[],
          articlestapat6:[],
            article2:[],

            activeItem: "1",
            admin_faculty:"",

            pass:[],
            nopass:[],
        };
       //  this.selectarticlefaculty = this.selectarticlefaculty.bind(this);
        //  this.selectatf = this.selectatf.bind(this);
        // this.handleChange2 = this.handleChange2.bind(this);
        // this.updateat = this.updateat.bind(this);
      }



      componentDidMount() {

        // const token = JSON.parse(localStorage.getItem('nameuser'));
        // if (localStorage.getItem('nameuser') == null) {
        //     window.location.href = '/'
        // } else {
        //     this.setState({ admin_faculty:localStorage.getItem('admin_faculty')})
        // }

        setTimeout(() => {
           //ภายใน
            Axios.get('https://thenextth.com/registerfa/selectrepass.php')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ pass: res.data})
           }) 

           Axios.get('https://thenextth.com/registerfa/selectrenopass.php')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ nopass: res.data})
           }) 


         }, 0);
      }

      // selectatf() {

      //   // const token = JSON.parse(localStorage.getItem('nameuser'));
      //   if (localStorage.getItem('nameuser') == null) {
      //       window.location.href = '/'
      //   } else {
      //       this.setState({ admin_faculty:localStorage.getItem('admin_faculty')})
      //   }

        
      // }


      // selectarticlefaculty() {
      //   // const token = JSON.parse(localStorage.getItem('nameuser'));
      //   if (localStorage.getItem('nameuser') == null) {
      //       window.location.href = '/'
      //   } else {
      //       this.setState({ admin_faculty:localStorage.getItem('admin_faculty')})
      //   }
      //   setTimeout(() => {
      //      //ภายใน
      //       Axios.get('https://thenextth.com/registerfa/selectarticlefaculty.php?faculty='+localStorage.getItem('admin_faculty'))
      //      .then(res => {
      //        console.log(res);
      //      //  this.setState({ bankname: res.data, loading: false })
      //        this.setState({ articlesci1: res.data})
      //      }) 

      //    }, 0);
      // }


    render() {
        return (
            <MDBContainer className="container mt-2" >
                 <MDBCard>
                    <MDBCardBody>
            <div className="container mt-2">
             
               <h1>สัดส่วนผู้ลงทะเบียน</h1>
               

                <MDBAlert color="success" >
              
        <h4><MDBIcon icon="user-check" className="green-text pr-3" /> ผู้สมัครที่ผ่าน {this.state.pass.length} คน</h4>
                </MDBAlert>

                {/* <Alert message={"บทความภายใน สาขาวิทยาศาสตร์และเทคโนโลยี จำนวน "+ this.state.articlesci1.length+" บทความ"} type="info" /> */}
          <MDBTable responsive>
                    <MDBTableHead>
                        <tr>
                        <th>ลำดับ</th>
                        <th>ชื่อ-สกุล</th>
                        <th>อายุ</th>
                        <th>เบอร์โทร</th>
                        <th>อีเมลล์</th>
                        <th>ID line</th>
                        <th>Facebook</th>
                        <th>วุฒิการศึกษา</th>
                        <th>ตำแหน่ง</th>
                        <th>รายได้ต่อเดือน</th>
                        <th>จัดการ</th>
                        </tr>
                    </MDBTableHead>

                    <MDBTableBody>
                    {this.state.pass.map((data,index )  => 
                        <tr>
                        <td>{index+1}</td>
                        <td>{data.name_register}  </td>
                        <td>{data.age_register}  </td>
                        <td>{data.tel_register}  </td>
                        <td>{data.email_register}  </td>
                        <td>{data.lineid_register}  </td>
                        <td>{data.facebook_register}  </td>
                        <td>{data.Educational_register}  {data.other_register === 'ต่ำกว่าปริญญาตรี' ? <><br/>{data.other_register}</> :<></>} </td>
                        <td>{data.name_position}  </td>
                        <td>{data.Income_register}  </td>
                        <td><Modaleditstatus id_send={data.id_register}/>  </td>
                        </tr>
                      )}
                    </MDBTableBody>
           </MDBTable><br/>

               <MDBAlert color="primary" >
                    <h4><MDBIcon icon="user-times"  className="blue-text pr-3"/> ผู้สมัครที่ไม่ผ่าน {this.state.nopass.length} คน</h4>
                </MDBAlert>
                <MDBTable responsive>
                    <MDBTableHead>
                    <tr>
                        <th>ลำดับ</th>
                        <th>ชื่อ-สกุล</th>
                        <th>อายุ</th>
                        <th>เบอร์โทร</th>
                        <th>อีเมลล์</th>
                        <th>ID line</th>
                        <th>Facebook</th>
                        <th>วุฒิการศึกษา</th>
                        <th>ตำแหน่ง</th>
                        <th>รายได้ต่อเดือน</th>
                        <th>จัดการ</th>
                        </tr>
                    </MDBTableHead>

                    <MDBTableBody>
                    {this.state.nopass.map((data,index )  => 
                         <tr>
                         <td>{index+1}</td>
                         <td>{data.name_register}  </td>
                         <td>{data.age_register}  </td>
                         <td>{data.tel_register}  </td>
                         <td>{data.email_register}  </td>
                         <td>{data.lineid_register}  </td>
                        <td>{data.facebook_register}  </td>
                        <td>{data.Educational_register} {data.Educational_register === 'ต่ำกว่าปริญญาตรี' ? <><br/>{data.other_register}</> :<></>} </td>
                        <td>{data.name_position}  </td>
                        <td>{data.Income_register}  </td>
                         <td><Modaleditstatus id_send={data.id_register}/>  </td>
                         </tr>
                      )}
                    </MDBTableBody>
           </MDBTable>



           <MDBCol size="6">
              
              <div className={"my-pretty-chart-container"}>
              <Chart
    width={'700px'}
    height={'400px'}
    chartType="PieChart"
    loader={<div>Loading Chart</div>}
    data={[
      ['Task', 'Hours per Day'],
      ['ผ่าน', this.state.pass.length],
      ['ไม่ผ่าน', this.state.nopass.length],
     
    ]}
    options={{
      title: 'กราฟสรุปจำนวนผู้สมัคร',
      // Just add this option
      is3D: true,
    }}
    rootProps={{ 'data-testid': '2' }}
  />
        </div>
              </MDBCol>

              
            </div>
                  </MDBCardBody>
                </MDBCard>
            </MDBContainer>

        )
    }
}
