import React, { Component } from 'react'
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn, MDBIcon, MDBCard, MDBCardBody, MDBJumbotron, MDBRow, MDBCol } from 'mdbreact';
import { GoogleApiWrapper, InfoWindow, Marker, Map } from 'google-maps-react';

import CurrentLocation from './Map';

const mapStyles = {
    width: '100%',
    height: '100%'
  };


export  class Setmap extends Component {

  
    constructor(props) {
        super(props);
        this.state = {
            data: [], //กำหนดค่าเริ่มต้นเป็น Array ว่าง เพื่อรอรับค่าต่างๆ
            showingInfoWindow: false,  // Hides or shows the InfoWindow
           activeMarker: {},          // Shows the active marker upon click
           selectedPlace: {}   ,
           
           lat:'',
           long:'',

        };

       this.onMarkerClick = this.onMarkerClick.bind(this);
       this.onClose = this.onClose.bind(this);
       this.latlong = this.latlong.bind(this);
      }


    onMarkerClick = (props, marker, e) =>{
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });
    console.log(marker)
  }
    

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  

    componentDidMount() { //เป็นฟังก์ชั่นชอง react โดนเฉพาะ โดยฟังก์ชั่นนี้จะถูกเรียกใช้งานครั้งแรกเสมอเมื่อมีการโหลด component นี้
       // this.getdata()
      //  console.log(CurrentLocation)
    }


    latlong() { 
     // this.setState({ data:  })
   }

    render() {

      const lattt = localStorage.getItem('latitude');
      const lnggg = localStorage.getItem('longitude');
      
        return (
            <div className="container">
              <p>sd</p>

            
 <CurrentLocation
        centerAroundCurrentLocation
        google={this.props.google}
  >
        {/* <Marker onClick={this.onMarkerClick} name={'Current Location'} />
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <h4>{this.state.selectedPlace.name}</h4>
          </div>
        </InfoWindow> */}
  </CurrentLocation>
                     
     
     
            </div>
        )
    }
}

export default GoogleApiWrapper(
    {
        apiKey: 'AIzaSyALbZ4_sHMQ2CoxtfHXeh4Q1uR0hqBJSOI'
      }


    // (props) => ({
    //     apiKey: props.apiKey
    //   }
    // )
  ) (Setmap);
