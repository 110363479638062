import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem, MDBAlert  } from 'mdbreact';
  import { MDBTable, MDBTableBody, MDBTableHead,   MDBTabPane,
    MDBTabContent,MDBNav } from 'mdbreact';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBMask, MDBView, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader } from 'mdbreact';
import { Form, Input,  Checkbox, message } from 'antd';
import Login from './Login';
import Axios from 'axios'
import { URL } from '../api';
import logo from './img/logokpru.png'

import Swal from 'sweetalert2'
import Modalregisterauthorities from './register/Modalregisterauthorities';
import Modallogin from './register/Modallogin';
import banner1 from './img/banner1.jpg'
import ReactJsTyping from 'reactjs-typing-effect';
import vw301318 from './img/vw301318.png'
import Iframe from 'react-iframe'
import Addcomment from './register/Addcomment';






export default class Contect extends Component {
    

        constructor(props) {
            super(props);
            this.state = {
                value: '',
                Psaaword:'',
                modal: false,
                collapse: false,
                isWideEnough: false,
                activeItem: "1",
            };
            this.handleChange = this.handleChange.bind(this);
            this.handleChange2 = this.handleChange2.bind(this);
         
            this.toggle = this.toggle.bind(this);
            this.toggle1 = this.toggle1.bind(this);
            this.onClick = this.onClick.bind(this);
            this.register = this.register.bind(this);
            this.registerauthor = this.registerauthor.bind(this);
            
          }

          onClick() {
            this.setState({
              collapse: !this.state.collapse,
            });
          }

          toggle = () => {
            this.setState({
              modal: !this.state.modal
            });
          }

          toggle1 = tab => e => {
            if (this.state.activeItem !== tab) {
              this.setState({
                activeItem: tab
              });
            }
          };

          register() {
            // window.location.href = '/Register'
             window.location.href = '/kprurun2022/Registerstudent'
             // this.setState({Register
             //   collapse: !this.state.collapse,
             // });
           }

           registerauthor() {
            // window.location.href = '/Register'
             window.location.href = '/kprurun2022/Registerauthorities'
             // this.setState({Register
             //   collapse: !this.state.collapse,
             // });
           }
        
          handleChange(event) {    this.setState({value: event.target.value});  }
          handleChange2(event) {    this.setState({Psaaword: event.target.value});  }

       

    update = () => {
        this.setState({
            name: "",
            lname: ""
        })
    }

    render() {

      const listOfString=['THENEXT WELCOME','ยินดีต้อนรับทุกท่าน']

      const listOfString2=['ได้มากกว่า']
      
        return (
       
                <>
           <br/>

         
                 <MDBContainer>
                 <h1 className='text-center font-weight-bold' style={{ fontSize: 'clamp(12px,4vw,40px)'}}>ติดต่อเรา</h1>
          <div className='service3' >   <br/>
            <h4 className='text-center font-weight-bold text-white' style={{ fontSize: 'clamp(10px,3vw,30px)'}}>ช่องทางติดต่อ</h4>
                  {/* <div className='text-center font-weight-bold' style={{ fontSize: 40, color: '#212121'}}>
                      <ReactJsTyping StringList={listOfString} speed={2000}/>
                  </div> */}
                   <MDBRow className='text-center'>
         <MDBCol xl="4">

          <div className='ml-2 mr-2'>
            <a href="tel:0815955492">
         <MDBAlert color="dark" >
            <MDBIcon icon="phone" size="3x" className="black-text pr-3" style={{ fontSize: 'clamp(10px,3vw,30px)'}} /><br/><h4 className="black-text mt-3" style={{ fontSize: 'clamp(10px,3vw,30px)'}}>โทร 0815955492</h4>
           </MDBAlert>
           </a>
           </div>
         </MDBCol>  

        <MDBCol xl="4">
        <div className='ml-2 mr-2'>
          <a href="https://line.me/ti/p/-vQFhWJFH0" target="_blank">
          <MDBAlert color="dark" >
          <MDBIcon fab icon="line" size="3x" className="black-text pr-3" style={{ fontSize: 'clamp(10px,3vw,30px)'}}/><br/><h4 className="black-text mt-3" style={{ fontSize: 'clamp(10px,3vw,30px)'}}>line</h4>
          </MDBAlert>
          </a>
          </div> 
        </MDBCol>
       
        <MDBCol xl="4">
        <div className='ml-2 mr-2'>
          <a href="https://www.facebook.com/icewitsanu" target="_blank">
          <MDBAlert color="dark" >
          <MDBIcon fab icon="facebook-f" size="3x" className="black-text pr-3" style={{ fontSize: 'clamp(10px,3vw,30px)'}} /><br/><h4 className="black-text mt-3" style={{ fontSize: 'clamp(10px,3vw,30px)'}}>Facebook</h4>
          </MDBAlert>
          </a>
          </div> 
        </MDBCol>
       
      </MDBRow>
                  {/* <h5  className='text-center font-weight-bold' style={{  color: '#757575'}}>ไม่ใช่แค่แบบประกันที่ดีและเหมาะสมที่เราจะมอบให้กับคุณ แต่คือการบริการที่เป็นเลิศ </h5>
                  <h5 className='text-center font-weight-bold' style={{  color: '#757575'}}>หลังจากส่งมอบกรมธรรม์แล้ว เราพร้อมรักษาผลประโยชน์ของลูกค้าอย่างเต็มที่ ในทุกครั้งที่เกิดปัญหา  </h5> */}
          </div><br/>
          </MDBContainer> <br/>

          <div className='text-center'>
                <MDBIcon icon="map-marker-alt" size="4x" className="red-text pr-3" /><br/>
              <h2 className="mt-3 font-weight-bold" style={{ fontSize: 'clamp(10px,3vw,40px)'}}>ที่อยู่</h2>
                 <h3 className="mt-3 " style={{ fontSize: 'clamp(10px,3vw,40px)'}}>206/6-7 ม.4 นครชุม เมือง กำแพงเพชร 62000</h3><br/>
                <Iframe url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d368.78271264967384!2d99.49772989482088!3d16.47477594026025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30de19b303d1ec57%3A0x2f45f9f221a3afdf!2z4Liq4LmN4Liy4LiZ4Lix4LiB4LiH4Liy4LiZ4LiE4Li44LiT4Lin4Li04Lip4LiT4Li4IOC4nuC4uOC4l-C4mOC4o-C4seC4geC4qeC4suC4oeC4k-C4tQ!5e0!3m2!1sth!2sth!4v1691997930703!5m2!1sth!2sth"
            width="100%"
            height="620px"
            id=""
            className=""
            display="block"
            frameBorder="0"
            position="relative"/>

            </div>

            <br/>

            <Addcomment/>

        

          {/* <h1 className='text-center font-weight-bold' style={{  color: '#d32f2f'}}>ปรึกษาด้านการเงินและแบบประกัน </h1> */}
          {/* <div className='service'>
            <p>sdsdsds</p>
            <p>sdsdsds</p>
            <p>sdsdsds</p>
            <p>sdsdsds</p>
          </div> */}
          {/* <div className='service2'><br/>
          <div className='text-center font-weight-bold' style={{ fontSize: 40, color: '#212121'}}>
                  ให้คุณ <span  style={{ fontSize: 40, color: '#CC0000'}}><ReactJsTyping  StringList={listOfString2} speed={2000}/></span> การทำประกันทั่วไป
                  </div> 


       
            <h3 className='text-center font-weight-bold mt-2' >วิเคราะห์ แนะนำ แม้ไม่เคยทำประกัน พร้อมออกแบบประกันที่เหมาะเฉพาะคุณ</h3>
            <img
                 // className="d-block w-40"
                  src={vw301318}
                   width={120}
                  alt="Second slide"
                />
          </div> */}
                    </>
                   
                   
        )
    }
}
