import React, { Component } from 'react'
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { MDBTable, MDBTableBody, MDBTableHead, MDBBtn, MDBIcon, MDBCard, MDBCardBody } from 'mdbreact';
import Modaleditstatus from './Modaleditstatus';
import { Alert, Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip  } from 'antd';
import Modaladdposition from './Modaladdposition';

export default class selectposition extends Component { 
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            switch1: true,
        }
        this.openstatus = this.openstatus.bind(this)
        this.closestatus = this.closestatus.bind(this)
        //this.handleChange3 = this.handleChange3.bind(this)
      }

    onTextChange = (e) => { //เมื่อมีการพิมพ์ค้นหา ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่งค่าที่ต้องการค้นหาไปที่ไฟล์ search.php
        Axios.get('https://thenextth.com/registerfa/search.php?id=' + e.target.value)
            .then((res) => {
                this.setState({ data: res.data })
            })
    }


    delete = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
        Axios.get('https://thenextth.com/registerfa/deleteposition.php?id=' + id)
            .then((res) => {
                this.getdata()
            })
    }

    getdata = () => { //เป็นฟังก์ชั่นไว้เรียกใช้สำหรับดึงข้อมูลมาทั้งหมด
        Axios.get('https://thenextth.com/registerfa/selectrefa.php')
            .then((res) => {
                this.setState({ data: res.data })
            })
    }

    openstatus = (id) => { //เป็นฟังก์ชั่นไว้เรียกใช้สำหรับดึงข้อมูลมาทั้งหมด
        const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
        datafrm.append("id_position", id);

        Axios.post('https://thenextth.com/registerfa/updatepositionopen.php',datafrm,{
            headers: {
                'content-type': 'multipart/form-data'
            }
           })
           .then(res => {
            // console.warn(res);
              console.log("response: ", res)
              if (res.status === 200) {
               // this.showitem = true;
                //console.log(res)
               // console.log(res.data.length) 
                message.success("เปิดสำเร็จ")
                this.getdata()
               // window.location.href = '/selectregister'
             }else{
              // window.location.href = '/'
             }
           })
    }

    closestatus = (id) => { //เป็นฟังก์ชั่นไว้เรียกใช้สำหรับดึงข้อมูลมาทั้งหมด
        const datafrm = new FormData(); //สร้างฟอร์มสำหรับการส่งข้อมูล
        datafrm.append("id_position", id);

        Axios.post('https://thenextth.com/registerfa/updatepositionclose.php',datafrm,{
            headers: {
                'content-type': 'multipart/form-data'
            }
           })
           .then(res => {
            // console.warn(res);
              console.log("response: ", res)
              if (res.status === 200) {
               // this.showitem = true;
                //console.log(res)
               // console.log(res.data.length) 
                message.success("ปิดสำเร็จ")
                this.getdata()
               // window.location.href = '/selectregister'
             }else{
              // window.location.href = '/'
             }
           })
    }

    componentDidMount() { //เป็นฟังก์ชั่นชอง react โดนเฉพาะ โดยฟังก์ชั่นนี้จะถูกเรียกใช้งานครั้งแรกเสมอเมื่อมีการโหลด component นี้
        this.getdata()
    }

    handleSwitchChange = nr => () => {
        let switchNumber = `switch${nr}`;
        this.setState({
          [switchNumber]: !this.state[switchNumber]
        });
      }

    render() {
        return (
            <div className="container mt-2">
                <MDBCard>
                    <MDBCardBody>

                        <h1>รายการตำแหน่ง</h1>

                       <div className="text-left">
                         <Modaladdposition/>
                        </div>
                        <div className="text-right">
                            <input onChange={this.onTextChange} placeholder="ค้นหาตำแหน่ง" />
                        </div>


                        <MDBTable small style={{ border: "1px" }} border width="100%">
                            <MDBTableHead  >
                                <tr>
                                    <th>ลำดับ</th>
                                    <th>ตำแหน่ง</th>
                                    <th>สถานะ</th>
                                   
                                </tr>
                            </MDBTableHead>


                            <MDBTableBody>
                                {this.state.data.map((data, index) => {
                                    return <tr>
                                        <td>{index+1}</td>
                                        <td>{data.name_position}</td>
                                        <td>{data.status_position}</td>
                                        <td>
                                            {/* <Link to={{ pathname: "/AddCustomers/", data: data }} >
                                                <MDBBtn size="sm" color="primary"><MDBIcon far icon="edit" /> จัดการสถานะ</MDBBtn>
                                            </Link> */}
                                            {/* <Modaleditstatus id_send={data.id_position}/> */}
                                            {data.status_position === '2' ? <><MDBBtn size="sm" color="dark-green" onClick={() => this.openstatus(data.id_position)} ><MDBIcon far icon="edit" /> คลิกเพื่อเปิด</MDBBtn></> : <><MDBBtn size="sm" color="unique" onClick={() => this.closestatus(data.id_position)}><MDBIcon far icon="edit" /> คลิกเพื่อปิด</MDBBtn></>}
                                            
                                            <MDBBtn size="sm" color="pink" onClick={() => this.delete(data.id_position)} ><MDBIcon far icon="trash-alt" /> ลบ</MDBBtn>
                                        </td>
                                    </tr>
                                })}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBCardBody>
                </MDBCard>
            </div>
        )
    }
}
