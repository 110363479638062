import React from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';


import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Routes from './Routes';
function App() {
  return (
    <Router >
      <Routes />
    </Router>
  );
}
export default App;
