import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem } from 'mdbreact';
  import { MDBTable, MDBTableBody, MDBTableHead,   MDBTabPane,
    MDBTabContent,MDBNav } from 'mdbreact';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBMask, MDBView, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader } from 'mdbreact';
import { Form, Input,  Checkbox, message } from 'antd';
import Login from './Login';
import Axios from 'axios'
import { URL } from '../api';
import logo from './img/logokpru.png'

import Swal from 'sweetalert2'
import Modalregisterauthorities from './register/Modalregisterauthorities';
import Modallogin from './register/Modallogin';
import banner1 from './img/banner1.jpg'
import ReactJsTyping from 'reactjs-typing-effect';
import vw301318 from './img/vw301318.png'
import Iframe from 'react-iframe'
import { Tabs } from 'antd';
import  { TabsProps } from 'antd';
import { api } from '../api';
import Addinsurance from './register/Addinsurance';
import Addinsurance2 from './register/Addinsurance2';



  
const { TabPane } = Tabs;




export default class Ceck extends Component {
    

        constructor(props) {
            super(props);
            this.state = {
                value: '',
                Psaaword:'',
                modal: false,
                collapse: false,
                isWideEnough: false,
                activeItem: "1",
                loading:false,
                insurance:[],
                insurance1:[],
                insurance2:[],
                insurance3:[],
                insurance4:[],
                insurance5:[],
                insurance6:[],
                insurance7:[],
                insurance8:[],
                insurance9:[],
                insurance10:[],
            };
            this.handleChange = this.handleChange.bind(this);
            this.handleChange2 = this.handleChange2.bind(this);
         
            this.toggle = this.toggle.bind(this);
            this.toggle1 = this.toggle1.bind(this);
            this.onClick = this.onClick.bind(this);
            this.register = this.register.bind(this);
            this.registerauthor = this.registerauthor.bind(this);
             this.onChange = this.onChange.bind(this);
          }

          onClick() {
            this.setState({
              collapse: !this.state.collapse,
            });
          }

          toggle = () => {
            this.setState({
              modal: !this.state.modal
            });
          }

          toggle1 = tab => e => {
            if (this.state.activeItem !== tab) {
              this.setState({
                activeItem: tab
              });
            }
          };

          register() {
            // window.location.href = '/Register'
             window.location.href = '/kprurun2022/Registerstudent'
             // this.setState({Register
             //   collapse: !this.state.collapse,
             // });
           }

           registerauthor() {
            // window.location.href = '/Register'
             window.location.href = '/kprurun2022/Registerauthorities'
             // this.setState({Register
             //   collapse: !this.state.collapse,
             // });
           }
        
          handleChange(event) {    this.setState({value: event.target.value});  }
          handleChange2(event) {    this.setState({Psaaword: event.target.value});  }

       

    update = () => {
        this.setState({
            name: "",
            lname: ""
        })
    }

     onChange = (key) => {
        console.log(key);
      };

      componentDidMount() {
        this.setState({ loading: true})
        setTimeout(() => {
           //ภายใน
           api.get('selectinsurance.php')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ insurance: res.data,loading:false})

             const filtered1 = res.data.filter(employee => { return employee.type_Insurance === '1' });
             this.setState({ insurance1: filtered1})

             const filtered2 = res.data.filter(employee => { return employee.type_Insurance === '2' });
             this.setState({ insurance2: filtered2})

             const filtered3 = res.data.filter(employee => { return employee.type_Insurance === '3' });
             this.setState({ insurance3: filtered3})

             const filtered4 = res.data.filter(employee => { return employee.type_Insurance === '4' });
             this.setState({ insurance4: filtered4})

             const filtered5 = res.data.filter(employee => { return employee.type_Insurance === '5' });
             this.setState({ insurance5: filtered5})

             const filtered6 = res.data.filter(employee => { return employee.type_Insurance === '6' });
             this.setState({ insurance6: filtered6})

             const filtered7 = res.data.filter(employee => { return employee.type_Insurance === '7' });
             this.setState({ insurance7: filtered7})

             const filtered8 = res.data.filter(employee => { return employee.type_Insurance === '8' });
             this.setState({ insurance8: filtered8})

             const filtered9 = res.data.filter(employee => { return employee.type_Insurance === '9' });
             this.setState({ insurance9: filtered9})

             const filtered10 = res.data.filter(employee => { return employee.type_Insurance === '10' });
             this.setState({ insurance10: filtered10})
             //console.log(filtered1);

             const listItems = filtered1.map((number) =>    <li>{number.Insurance}</li>  );  
             return (
              <ul>{listItems}</ul>  );
           }) 
         }, 0);
         
        }

    render() {






      const listOfString=['THENEXT WELCOME','ยินดีต้อนรับทุกท่าน']
      const listOfString1=['ประกันสุขภาพ']
      const listOfString2=['ค่าชดเชย']
      const listOfString3=['ความคุมครองชีวิต']
      const listOfString4=['สะสมทรัพย์']
      const listOfString5=['ประกันชีวิตควบการลงทุน']
      const listOfString6=['ประกันอุบัติเหตุ']
      const listOfString7=['เพรสทีจ']
      const listOfString8=['แบบบำนาญ']
      const listOfString9=['โรคร้ายแรง']
      const listOfString10=['ไวทัลลิตี้']


      const items: TabsProps['items'] =  [
        {
          key: '1',
          label: `ประกันสุขภาพ`,
          children:  <>
                  <div className='text-center font-weight-bold' style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#212121'}}>
                 <b> แบบประกัน <span  style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#d81b60'}}><ReactJsTyping  StringList={listOfString1} speed={1000}/></span> </b>
                  </div> <br/>

          <div className="d-flex flex-wrap bd-highlight example-parent d-flex justify-content-center">
          
            {this.state.insurance1.map((insurance1,index )  => 
             <div className="p-2 col-example text-center">
               {/* <img src={`http://localhost/aiabackend/${insurance1.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="50%" /> */}
               <a href={`https://thenextth.com/aiabackend/${insurance1.pdf_Insurance}`} target="_blank"> <img src={`https://thenextth.com/aiabackend/${insurance1.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="60%" /></a><br/>
               <p className='text-center'>{insurance1.name_Insurance}</p>
               </div>
             )}
           </div> 
          </>,
        },
        {
          key: '2',
          label: `ค่าชดเชย`,
          children:  <>
                 <div className='text-center font-weight-bold' style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#212121'}}>
                  แบบประกัน <span  style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#d81b60'}}><ReactJsTyping  StringList={listOfString2} speed={2000}/></span> 
                  </div> <br/>

           <div className="d-flex flex-wrap bd-highlight example-parent d-flex justify-content-center">
             {this.state.insurance2.map((insurance2,index )  => 
              <div className="p-2 col-example text-center">
                {/* <img src={`http://localhost/aiabackend/${insurance1.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="50%" /> */}
                <a href={`https://thenextth.com/aiabackend/${insurance2.pdf_Insurance}`} target="_blank">  <img src={`https://thenextth.com/aiabackend/${insurance2.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="60%" /></a><br/>
                <p className='text-center'>{insurance2.name_Insurance}</p>
                </div>
              )}
            </div> 
           </>,
        },
        {
          key: '3',
          label: `ความคุมครองชีวิต`,
          children: <>
                <div className='text-center font-weight-bold' style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#212121'}}>
                  แบบประกัน <span  style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#d81b60'}}><ReactJsTyping  StringList={listOfString3} speed={2000}/></span> 
                  </div> <br/>

                      <div className="d-flex flex-wrap bd-highlight example-parent d-flex justify-content-center">
                        {this.state.insurance3.map((insurance3,index )  => 
                        <div className="p-2 col-example text-center">
                          {/* <img src={`http://localhost/aiabackend/${insurance1.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="50%" /> */}
                          <a href={`https://thenextth.com/aiabackend/${insurance3.pdf_Insurance}`} target="_blank"> <img src={`https://thenextth.com/aiabackend/${insurance3.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="60%" /></a><br/>
                          <p className='text-center'>{insurance3.name_Insurance}</p>
                          </div>
                        )}
                      </div> 
                    </>,
        },
        {
            key: '4',
            label: `สะสมทรัพย์`,
            children: <>
                  <div className='text-center font-weight-bold' style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#212121'}}>
                  แบบประกัน <span  style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#d81b60'}}><ReactJsTyping  StringList={listOfString4} speed={2000}/></span> 
                  </div> <br/>
                        <div className="d-flex flex-wrap bd-highlight example-parent d-flex justify-content-center">
                          {this.state.insurance4.map((insurance4,index )  => 
                          <div className="p-2 col-example text-center">
                            {/* <img src={`http://localhost/aiabackend/${insurance1.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="50%" /> */}
                            <a href={`https://thenextth.com/aiabackend/${insurance4.pdf_Insurance}`} target="_blank"> <img src={`https://thenextth.com/aiabackend/${insurance4.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="60%" /></a><br/>
                            <p className='text-center'>{insurance4.name_Insurance}</p>
                            </div>
                          )}
                        </div> 
                      </>,
          },
          {
            key: '5',
            label: `ประกันชีวิตควบการลงทุน`,
            children: <>
                  <div className='text-center font-weight-bold' style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#212121'}}>
                  แบบประกัน <span  style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#d81b60'}}><ReactJsTyping  StringList={listOfString5} speed={2000}/></span> 
                  </div> <br/>
                        <div className="d-flex flex-wrap bd-highlight example-parent d-flex justify-content-center">
                          {this.state.insurance5.map((insurance5,index )  => 
                          <div className="p-2 col-example text-center">
                            {/* <img src={`http://localhost/aiabackend/${insurance1.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="50%" /> */}
                            <a href={`https://thenextth.com/aiabackend/${insurance5.pdf_Insurance}`} target="_blank">  <img src={`https://thenextth.com/aiabackend/${insurance5.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="60%" /></a><br/>
                            <p className='text-center'>{insurance5.name_Insurance}</p>
                            </div>
                          )}
                        </div> 
                      </>,
          },
          {
            key: '6',
            label: `ประกันอุบัติเหตุ`,
            children: <>
                 <div className='text-center font-weight-bold' style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#212121'}}>
                  แบบประกัน <span  style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#d81b60'}}><ReactJsTyping  StringList={listOfString6} speed={1000}/></span> 
                  </div> <br/>
                          <div className="d-flex flex-wrap bd-highlight example-parent d-flex justify-content-center">
                            {this.state.insurance6.map((insurance6,index )  => 
                            <div className="p-2 col-example text-center">
                              {/* <img src={`http://localhost/aiabackend/${insurance1.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="50%" /> */}
                              <a href={`https://thenextth.com/aiabackend/${insurance6.pdf_Insurance}`} target="_blank">  <img src={`https://thenextth.com/aiabackend/${insurance6.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="60%" /></a><br/>
                              <p className='text-center'>{insurance6.name_Insurance}</p>
                              </div>
                            )}
                          </div> 
                        </>,
          },
          {
            key: '7',
            label: `เพรสทีจ`,
            children: <>
                 <div className='text-center font-weight-bold' style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#212121'}}>
                  แบบประกัน <span  style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#d81b60'}}><ReactJsTyping  StringList={listOfString7} speed={1000}/></span> 
                  </div> <br/>
                          <div className="d-flex flex-wrap bd-highlight example-parent d-flex justify-content-center">
                            {this.state.insurance7.map((insurance7,index )  => 
                            <div className="p-2 col-example text-center">
                              {/* <img src={`http://localhost/aiabackend/${insurance1.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="50%" /> */}
                              <a href={`https://thenextth.com/aiabackend/${insurance7.pdf_Insurance}`} target="_blank">   <img src={`https://thenextth.com/aiabackend/${insurance7.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="60%" /></a><br/>
                              <p className='text-center'>{insurance7.name_Insurance}</p>
                              </div>
                            )}
                          </div> 
                        </>,
          },
          {
            key: '8',
            label: `แบบบำนาญ`,
            children: <>
                 <div className='text-center font-weight-bold' style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#212121'}}>
                  แบบประกัน <span  style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#d81b60'}}><ReactJsTyping  StringList={listOfString8} speed={1000}/></span> 
                  </div> <br/>
                          <div className="d-flex flex-wrap bd-highlight example-parent d-flex justify-content-center">
                            {this.state.insurance8.map((insurance8,index )  => 
                            <div className="p-2 col-example text-center">
                              {/* <img src={`http://localhost/aiabackend/${insurance1.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="50%" /> */}
                              <a href={`https://thenextth.com/aiabackend/${insurance8.pdf_Insurance}`} target="_blank">    <img src={`https://thenextth.com/aiabackend/${insurance8.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="60%" /></a><br/>
                              <p className='text-center'>{insurance8.name_Insurance}</p>
                              </div>
                            )}
                          </div> 
                        </>,
          },
          {
            key: '9',
            label: `โรคร้ายแรง`,
            children: <>
                 <div className='text-center font-weight-bold' style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#212121'}}>
                  แบบประกัน <span  style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#d81b60'}}><ReactJsTyping  StringList={listOfString9} speed={1000}/></span> 
                  </div> <br/>
                          <div className="d-flex flex-wrap bd-highlight example-parent d-flex justify-content-center">
                            {this.state.insurance9.map((insurance9,index )  => 
                            <div className="p-2 col-example text-center">
                              {/* <img src={`http://localhost/aiabackend/${insurance1.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="50%" /> */}
                              <a href={`https://thenextth.com/aiabackend/${insurance9.pdf_Insurance}`} target="_blank">     <img src={`https://thenextth.com/aiabackend/${insurance9.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="60%" /></a><br/>
                              <p className='text-center'>{insurance9.name_Insurance}</p>
                              </div>
                            )}
                          </div> 
                        </>,
          },
          {
            key: '10',
            label: `ไวทัลลิตี้`,
            children: <>
                 <div className='text-center font-weight-bold' style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#212121'}}>
                  แบบประกัน <span  style={{ fontSize: 'clamp(12px,4vw,40px)', color: '#d81b60'}}><ReactJsTyping  StringList={listOfString10} speed={1000}/></span> 
                  </div> <br/>
                          <div className="d-flex flex-wrap bd-highlight example-parent d-flex justify-content-center">
                            {this.state.insurance10.map((insurance10,index )  => 
                            <div className="p-2 col-example text-center">
                              {/* <img src={`http://localhost/aiabackend/${insurance1.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="50%" /> */}
                              <a href={`https://thenextth.com/aiabackend/${insurance10.pdf_Insurance}`} target="_blank">   <img src={`https://thenextth.com/aiabackend/${insurance10.pic_Insurance}`} alt="thumbnail" className="img-thumbnail" width="60%" /></a><br/>
                              <p className='text-center'>{insurance10.name_Insurance}</p>
                              </div>
                            )}
                          </div> 
                        </>,
          },
          
      ];




      
        return (
       
                <>
           <br/>

          <MDBContainer>

          <div>
            
           
                  <h1 className='text-center font-weight-bold' style={{ fontSize: 'clamp(12px,4vw,40px)'}}>แนะนำแบบประกัน</h1>
                  {/* <div className='text-center font-weight-bold' style={{ fontSize: 40, color: '#212121'}}>
                      <ReactJsTyping StringList={listOfString} speed={2000}/>
                  </div> */}
                 
                  {/* <h5  className='text-center font-weight-bold' style={{  color: '#757575'}}>ไม่ใช่แค่แบบประกันที่ดีและเหมาะสมที่เราจะมอบให้กับคุณ แต่คือการบริการที่เป็นเลิศ </h5>
                  <h5 className='text-center font-weight-bold' style={{  color: '#757575'}}>หลังจากส่งมอบกรมธรรม์แล้ว เราพร้อมรักษาผลประโยชน์ของลูกค้าอย่างเต็มที่ ในทุกครั้งที่เกิดปัญหา  </h5> */}
          </div><br/>
          <div>
                <Tabs  centered="true" defaultActiveKey="1" items={items} onChange={this.onChange} />
                </div>   <hr/>   
                <br/>
<Addinsurance2 />
            <br/>
                {/* <Tabs centered="true">
        <TabPane tab="Tab 1" key="1">
        {this.state.insurance1.map((insurance1,index )  => 
                       <p>{insurance1.name_Insurance}</p>
                      )}
        </TabPane>
        <TabPane tab="Tab 2" key="2">
          2nd TAB PANE Content
        </TabPane>
        <TabPane tab="Tab 3" key="3">
          3rd TAB PANE Content
        </TabPane>
      </Tabs> */}

          </MDBContainer> <br/>


        

                    </>
                   
                   
        )
    }
}
