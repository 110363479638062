import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem } from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';

import { api } from '../../api';
import Modalbanner from './Modalbanner';
import LoadingOverlay from 'react-loading-overlay';
import Modalguaranteetype from './Modalguaranteetype';


export default class Addguaranteetype extends Component {
    constructor(props) {
        super(props);
        this.state = {
          loading:false,
          emp:[],
          news:[],
          banner:[],
        };
       this.getbanner = this.getbanner.bind(this);
       this.delete = this.delete.bind(this);
       
      }

      componentDidMount() {
      this.getbanner();
       
      }

      getbanner() {
        this.setState({ loading: true})
        setTimeout(() => {
           //ภายใน
           api.get('selectinsurance.php')
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ banner: res.data,loading:false})
           }) 
         }, 0);
      }

      delete = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
        api.get('deleteinsurance.php?id=' + id)
            .then((res) => {
                this.getbanner()
            })
      }


  render() {
    console.log(this.state.news)

    const data = {
      columns: [
        {
          label: 'ลำดับ',
          field: 'list',
          sort: 'list',
          width: 150
        },
        {
            label: 'รูปปก',
            field: 'PIC',
            sort: 'PIC',
            width: 200
          },
        {
          label: 'ชื่อแบบประกัน',
          field: 'NAME',
          sort: 'NAME',
          width: 200
        },
         {
          label: 'วันที่เพิ่ม',
          field: 'DATES',
          sort: 'DATES',
          width: 200
        },
        {
          label: 'จัดการ',
          field: 'FACULTY',
          sort: 'FACULTY',
          width: 100
        }

      ],

      rows: [...this.state.banner.map((data, i) => (
        {
           list: <>{i+1}</>,
           PIC: <> <MDBCol >
           <img src={`https://thenextth.com/aiabackend/${data.pic_Insurance}`} className="img-fluid" alt="" width="50%" />
         </MDBCol></>,
           NAME: <>{data.name_Insurance}</>,
           DATES: <>{data.date_Insurance}</>,
           FACULTY: <> <MDBBtn size="sm" color="deep-orange" onClick={() => this.delete(data.id_Insurance)} ><MDBIcon far icon="trash-alt" /> ลบ</MDBBtn></>,
          

          //  random: <h5 searchvalue={data.Curriculumopen_TEAM} >tourist <span className="badge badge-danger">narrow</span></h5>,

        }
       ))] 
  
  };

  const persons = <MDBDataTable
  // searching ={true}
   searchLabel="ค้นหา รายการ"
   striped
   bordered
   small 
   entries={20}
  // entriesOptions={["10", "20"]}
   order={["age", "desc"]}
   data={data}
   entriesLabel="จำนวนที่แสดง "
   infoLabel= {["แสดง", "ถึง", "จากทั้งหมด", "รายการ"]}
   paginationLabel={["ย้อนกลับ", "ถัดไป"]}
   responsive 
   sortRows={['random']}
/>

    return (
      <LoadingOverlay
      active={this.state.loading}
      spinner
      text='Loading your data...'
      >
      <MDBContainer className='mt-2'> 
        <br/>
        <h2 className='text-center'>จัดการแบบประกัน</h2>
        <h5 className='text-center'>ผู้ใช้งาน : {localStorage.getItem('name_member')}</h5>
      <p className='text-center'><Modalguaranteetype getbanner={this.getbanner}/></p> 
    
         {persons}
      </MDBContainer>
      </LoadingOverlay>  
    )
  }
}
