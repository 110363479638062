import React, { Component } from 'react'
import { Map, GoogleApiWrapper, InfoWindow, Marker, Polyline    } from 'google-maps-react';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
    MDBCarouselInner,
    MDBCarouselItem } from 'mdbreact';
import { red } from '@material-ui/core/colors';
import zIndex from '@material-ui/core/styles/zIndex';


const mapStyles = {
    width: '80%',
    height: '40%',
    padding: '10px',
    position: 'absolute',
    top: 0,
     bottom: 0,
  };

 

export  class Showlocation extends Component {
  

      constructor(props) {
        super(props);
    
        this.state = {
            showingInfoWindow: false,  // Hides or shows the InfoWindow
            activeMarker: {},          // Shows the active marker upon click
            selectedPlace: {} ,
            nann:"ss",

            latitude1: 16.4675,
            longitude1: 99.5182,
            latitude2: 16.4727013,
            longitude2: 99.5123584,

          


        };
        this.onMarkerClick = this.onMarkerClick.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onCloseddd = this.onCloseddd.bind(this);
    }



      onMarkerClick = (props, marker, e) =>{
      console.log(marker)
          this.setState({
      selectedPlace: props,
      nann:props.name,
      activeMarker: marker,
      showingInfoWindow: true
    });
      }
   
  

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };


 //คำนวนทาง
onCloseddd = () => {
  const unit = 'kilometers'
  const theta = this.state.longitude1 - this.state.longitude2;
  const distance = 60 * 1.1515 * (180/Math.PI) * Math.acos(
      Math.sin(this.state.latitude1 * (Math.PI/180)) * Math.sin(this.state.latitude2 * (Math.PI/180)) + 
      Math.cos(this.state.latitude1 * (Math.PI/180)) * Math.cos(this.state.latitude2 * (Math.PI/180)) * Math.cos(theta * (Math.PI/180))
  );
  if (unit == 'miles') {
    console.log(Math.round(distance, 2))
      return Math.round(distance, 2);
      
      
  } else if (unit == 'kilometers') {
     console.log(distance * 1.609344, 2)
      return Math.round(distance * 1.609344, 2);
     
      
  }
  };

  componentDidMount (){
   

this.onCloseddd();
  }


    render() {

    

      const someCoords= [
        {lat: 16.4675, lng: 99.5182},
        {lat: 16.4727013, lng: 99.5123584},
        {lat: 16.4739208, lng: 99.5187794}
      ];

      const lattt = localStorage.getItem('latitude');
      const lnggg = localStorage.getItem('longitude');
        return (

            <div >
<MDBContainer >
<div>

        {/* <Map
        google={this.props.google}
        zoom={14}
        style={mapStyles}
        initialCenter={
          {
            lat: 16.4675,
            lng: 99.5182
          }
        }
      >
        <Marker
          onClick={this.onMarkerClick}
          name={'Kenyatta International Convention Centre'}
          position={{lat:16.4675, lan:99.5182}}
        />
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <h4>{this.state.selectedPlace.name}</h4>
          </div>
        </InfoWindow>
      </Map> */}

{/* <Polyline path={[{ lat: 16.4675, lng: 99.5182 }, { lat: 16.4727013, lng: 99.5123584 }]}/> */}
        <Map
          google={this.props.google}
         
          zoom={14}
          zoomControl={false}
         // style={mapStyles}
          style={{  width: '80%', height: '400px', textalign: 'left', zIndex : 20, marginleft: 0 ,margin:0, marginright: 0,marginbutton: 0, margintop: 'fixed',  }}
          initialCenter={
            {
              lat: lattt,
              lng: lnggg,
             
            }
          }
        
         // gestureHandling={'cooperative'}
        >

                      <Marker
                        onClick={this.onMarkerClick}
                        position={{ lat: lattt, lng: lnggg }}
                        icon={{
                            url: `https://www.24carfix.com/img/thumb/photo-290x290--1.png`,
                            scaledSize: new window.google.maps.Size(55, 55)
                          }}
                        name={'CAR02 0865458741'}
                        
                      />
                    <InfoWindow
                      marker={this.state.activeMarker}
                      visible={this.state.showingInfoWindow}
                      onClose={this.onClose}
                    >
                      <div>
                        <h4>{this.state.nann}</h4>
                      </div>
                    </InfoWindow>   
                    {/* <Polyline
              path={someCoords}
              strokeColor="#FF0000"
              strokeOpacity={0.8}
              strokeWeight={5} 
               geodesic={true}
              /> */}
              
           {/* <AnyReactComponent lat={lat} lng={lng} text='My Marker' /> */}
                    {/* <Polyline /> */}
        </Map>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>
<br/>

        </div>
        </MDBContainer>
           </div>
        )
    }


    
}



export default GoogleApiWrapper(
    {
        apiKey: 'AIzaSyALbZ4_sHMQ2CoxtfHXeh4Q1uR0hqBJSOI'
      }
    // (props) => ({
    //     apiKey: props.apiKey
    //   }
    // )
  )(Showlocation);
