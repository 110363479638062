import React, { Component } from 'react'
import {
  MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem
} from 'mdbreact';
import { MDBTable, MDBTableBody, MDBTableHead, MDBDataTable } from 'mdbreact';
import { api } from '../../api';
import Showcategorypic from './Showcategorypic';
import { MDBCard, MDBCardTitle, MDBCardText, MDBCardGroup, MDBCardBody } from "mdbreact";

export default class Showcategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emp: [],
      news: [],
      categorylimit: [],
    };
    this.getcategory = this.getcategory.bind(this);
    this.delete = this.delete.bind(this);

  }

  componentDidMount() {
    this.getcategory();

  }

  getcategory() {
    setTimeout(() => {
      //ภายใน
      api.get('selectcategorylimit.php')
        .then(res => {
          console.log(res);
          //  this.setState({ bankname: res.data, loading: false })
          this.setState({ categorylimit: res.data })
        })
    }, 0);
  }

  delete = (id) => { //เมื่อมีการคลิกลบ ฟังก์ชั่นนี้จะถูกเรียกใช้งาน แล้วส่ง id ที่ต้องการลบไปที่ไฟล์ delete.php
    api.get('deletenews.php?id=' + id)
      .then((res) => {
        this.getnews()
      })
  }


  render() {
    console.log(this.state.news)

 
    return (

      <MDBContainer>
       

        <div className="d-flex justify-content-center flex-wrap bd-highlight example-parent text-center">
          {this.state.categorylimit.map((categorylimit, index) => {
            return <>

              {/* <MDBCard className="card-body" style={{ width: "22rem", marginTop: "1rem" }}>
                <MDBCardTitle>Panel Title</MDBCardTitle>
              
              </MDBCard> */}

              <MDBCardGroup className='mt-2 p-3' deck>
                <MDBCard>
                  {/* <MDBCardTitle tag="h5">Panel title</MDBCardTitle> */}
                  <div className="p-2 bd-highlight col-example">
                    <Showcategorypic id_category={categorylimit.id_category} /><p className='text-center'>{categorylimit.name_category}<br/>{categorylimit.date_category}</p>
                    {/* <MDBBtn color="pink" size='sm'>ดูทั้งหมด</MDBBtn> */}
                  </div>
                  {/* <MDBCardText small muted>
                    Last updated 3 mins ago
                  </MDBCardText> */}

                </MDBCard>

              </MDBCardGroup>

            </>
          })}

          {/* <div className="p-2 col-example text-left">Flex item 2</div>
      <div className="p-2 col-example text-left">Flex item 3</div> */}

        </div>
        {/* {persons} */}
      </MDBContainer>
    )
  }
}
