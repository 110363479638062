import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBJumbotron,  MDBAlert,  MDBTable, MDBTableBody, MDBTableHead, MDBBtn, MDBIcon, MDBBadge, MDBInput} from "mdbreact";
import Axios from 'axios'
import { Layout, Menu, Breadcrumb, Icon, Select, Input, Button ,Card   } from 'antd';
import { StarOutlined, StarFilled, StarTwoTone, CaretDownOutlined, UserOutlined } from '@ant-design/icons';
import Modalteachingschedule from './Modalteachingschedule';
import Modalcolor from './Modalcolor';



export default class Learn extends Component {
    
    constructor(props) {
        super(props);
        this.state = {

          thschedule:[],
          modal: false,
          term:'',
          type:'1',
          idemp:'',
          empcouncilcheck:[],
          empcouncilcheck2:[],
          showteachingschedule:[],
          dd3:'',
          teacher:'',
          major:'',

        };
        this.onChangemajor = this.onChangemajor.bind(this);
        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onSearch = this.onSearch.bind(this);

        this.getthschedule = this.getthschedule.bind(this);
        this.updateSearchDepartment = this.updateSearchDepartment.bind(this);
        this.getteachingschedule = this.getteachingschedule.bind(this);
        this.time = this.time.bind(this);

      }


    updateSearchDepartment(value) {
        // console.log(event.target.value)
        // const term = event.target.value;
        const term = value;
        this.setState({ term });
 
           setTimeout(() => {
             this.getthschedule()
           }, 500);
      }


      getthschedule() {
        setTimeout(() => {
           //ภายใน
            Axios.get('https://git.kpru.ac.th/FrontEnd_Tabian/load/getthschedule/'+this.state.term)
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ thschedule: res.data})
           }) 
         }, 0);
      }

      getteachingschedule() {
        setTimeout(() => {
           //ภายใน
            Axios.get('https://git.kpru.ac.th/FrontEnd_Tabian/load/showstudentschedule/'+this.state.term+'/'+this.state.major)
           .then(res => {
             console.log(res);
           //  this.setState({ bankname: res.data, loading: false })
             this.setState({ showteachingschedule: res.data})

             if (res.data.length !== 0) {
              const teacher = res.data[0].poname;
              this.setState({ teacher });
            }

            
           }) 
         }, 0);
      }


    


      onChangemajor= e =>{
       console.log(e.target.value);
         // const idemp = value;
          this.setState({ major:e.target.value });

        //   setTimeout(() => {
        //      Axios.get('https://git.kpru.ac.th/FrontEnd_Tabian/load/getthcouncilcheck/1/'+this.state.term+'/'+this.state.idemp)
        //     .then(res => {
        //             this.setState({ empcouncilcheck: res.data})
        //     }) 
        //   }, 0);

        //   setTimeout(() => {
        //      Axios.get('https://git.kpru.ac.th/FrontEnd_Tabian/load/getthcouncilcheck/2/'+this.state.term+'/'+this.state.idemp)
        //     .then(res => {
        //             this.setState({ empcouncilcheck2: res.data})
        //     }) 
        //   }, 0);
        //   setTimeout(() => {
        //     this.getteachingschedule()
        //   }, 500);
     }


         onBlur() {
            console.log('blur');
          }
          
           onFocus() {
            console.log('focus');
          }
          
           onSearch(val) {
            console.log('search:', val);
          }

          time() {
            const d = new Date();
            d.setMinutes(17);
            document.getElementById("demo").innerHTML = d;
          }


    render() {

        const { Option } = Select;
       // let majorname =  this.state.showteachingschedule[0].map(showteachingschedule => { {showteachingschedule.major} }  )     
        return (
            <MDBContainer fluid>
                {/* <MDBAlert color="primary" >
                <h4><MDBIcon icon="address-book" className="indigo-text pr-3" /> ตารางเรียน </h4>
                </MDBAlert>
                <br/> */}
      <Card type="inner"  title={<h4><MDBIcon icon="list-alt" /> ตารางเรียน</h4>} >
    <MDBRow>

            <MDBCol sm="4">
                <div>
                    <CaretDownOutlined style={{ fontSize: '30px', color: '#0d47a1' }}  />   
                    
                        <Select
                            showSearch
                            style={{ width: 300 }}
                            placeholder="เลือกเทอม"
                            optionFilterProp="children"
                            onChange={this.updateSearchDepartment}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onSearch={this.onSearch}
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        >
                            <Option value="3/2563">3/2563</Option>
                            <Option value="1/2564">1/2564</Option>
                            <Option value="2/2564">2/2564</Option>
                            <Option value="3/2564">3/2564</Option>
                            <Option value="1/2565">1/2565</Option>
                            <Option value="2/2565">2/2565</Option>
                        </Select>
                    </div>
                    {/* <br/> */}

            </MDBCol>

            <MDBCol sm="4">
          { this.state.term === "" ?<> </>:<> 
 
                        <div>
                        <UserOutlined style={{ fontSize: '30px', color: '#0d47a1' }}  />   
                        <Input placeholder="หมู่เรียน"  style={{ width: 300 }}  onChange={this.onChangemajor} />
                        {/* <Button type="primary"  onClick={this.getteachingschedule} >ค้นหา</Button> */}
                       </div>
                     
                        <br/>
          </> }

            </MDBCol>
            <MDBCol sm="2">
            { this.state.term !== "" && this.state.major !== "" ?<> 
            <div className="text-left">
            <Button type="primary"  onClick={this.getteachingschedule} >ค้นหา</Button>
            </div></>:<>  
                      { this.state.term === "" ?<> </>:<>  
                      <div className="text-left">
                      <Button type="primary" disabled  >ค้นหา</Button>
                      </div>  </> }    
                    
             </> }
            
            </MDBCol>
  </MDBRow>

                {/* { this.state.empcouncilcheck.length === 0 ? <> </> : 
                <>
                <MDBAlert color="danger">
                    <a href="#!" className="alert-link"> ! อาจารย์มีรายชื่อในการประชุมกรรมการบริหารมหาวิทยาลัย</a> ทุกวันอังคาร ของทุกเดือน
                </MDBAlert>
                </>}


                { this.state.empcouncilcheck2.length === 0 ? <> </> : 
                <>
                <MDBAlert color="warning ">
                    <a href="#!" className="alert-link"> ! อาจารย์มีรายชื่อในการประชุมกรรมการบริหารมหาวิทยาลัย</a> ทุกวันพฤหัสบดี ของทุกเดือน
                </MDBAlert>
                </>} */}

          { this.state.term === '' ? <></> :<> 
          <br/>
          <hr/>
          <h5 className="text-center">ตารางสอนภาคปกติกำแพงเพชร</h5> 
           <h5 className="text-center">เทอม {this.state.term}</h5> 
              {/* <h5>{this.majorname}</h5> */}
           {/* {this.state.teacher === ''?<></>:<>  <h5 className="text-center">อาจารย์{this.state.teacher} </h5> </> } */}
          
          {this.state.empcouncil === '' ? <>
          <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            </>: <>
            <MDBTable bordered small responsive>
                <MDBTableHead>
                <tr>
                    <th  className="text-center">ลำดับ</th>
                    <th  className="text-center">หมู่เรียน</th>
                    <th  className="text-center">โปรแกรม</th>
                    <th  className="text-center">รหัสวิชา</th>
                    <th  className="text-center">ชื่อวิชา</th>
                    <th  className="text-center">ผู้สอน</th>
                    <th  className="text-center">จำนวน</th>
                    {/* <th  className="text-center">กลุ่ม</th> */}
                    <th  className="text-center">วัน</th>
                    <th  className="text-center">เวลา</th>
                    <th  className="text-center">ห้องเรียน</th>
                </tr>
              
                </MDBTableHead>
                <MDBTableBody>
                {this.state.showteachingschedule.map((showteachingschedule,index) => {
                
                   //กศบป
                    const  time2 = Number (showteachingschedule.hour1*55)
                    const  ddf = time2-5
                    const  now = new Date("December 25, 1995 "+showteachingschedule.times1+" ");
                    const totalsss = now.setMinutes(now.getMinutes()+ddf);
                    const wanwela = new Date(totalsss)
                    const wanwela1 =  wanwela.toLocaleTimeString()

                    //ปกติ
                    const  timenormal = Number (showteachingschedule.hour1*60)
                    const  now2 = new Date("December 25, 1995 "+showteachingschedule.times1+" ");
                    const totalsss2 = now2.setMinutes(now2.getMinutes()+timenormal);
                    const wanwela2 = new Date(totalsss2)
                    const normal =  wanwela2.toLocaleTimeString()
                       
                     return   <tr>
                          <td>{index+1}</td>
                          <td>{showteachingschedule.id_major}</td>
                          <td>{showteachingschedule.major}</td>
                          <td>{showteachingschedule.SUBJCODE}</td>
                          <td>{showteachingschedule.SUBJNAME}</td>
                          <td>{showteachingschedule.poname}</td>
                          <td>{showteachingschedule.numregister}</td>
                          {/* <td> <p><Modalcolor idcolor={teachingschedule.group_id} /></p></td> */}
                          {/* <td>อาจารย์{teachingschedule.poname}</td> */}

                    {/* ช่วงที่1 */}
                          <td> {showteachingschedule.ss === '1' ?<> จันทร์</>:<></> }
                               {showteachingschedule.ss === '2' ?<> อังคาร</>:<></> }
                               {showteachingschedule.ss === '3' ?<> พุธ</>:<></> }
                               {showteachingschedule.ss === '4' ?<> พฤหัสบดี</>:<></> }
                               {showteachingschedule.ss === '5' ?<> ศุกร์</>:<></> }
                               {showteachingschedule.ss === '6' ?<> เสาร์</>:<></> }
                               {showteachingschedule.ss === '7' ?<> อาทิตย์</>:<></> }
                          </td>
                          <td>
                              {showteachingschedule.times1 === null || showteachingschedule.times1 === ''  ? <></> :<> {showteachingschedule.times1} - {showteachingschedule.timesend1}
                              {/* { showteachingschedule.id_major.substring(2, 3) === '1' ?<>{normal.substring(0, 5)} </> :<>{wanwela1.substring(0, 5)}  </>} */}
                                </> }
                          </td>
                          <td >{showteachingschedule.classroom1}</td>
                          </tr>
                  
                })}   
                </MDBTableBody>
            </MDBTable>
            </> }
            {/* <p>หมายเหตุ : ข้อมูลใช้ประกอบการจัดตารางสอน ไม่ให้ชนกับการประชุม</p> */}
        </>}

        </Card>
            </MDBContainer>
        )
    }
}
