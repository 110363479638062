import React, { Component } from 'react'
import {
    MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBDropdown,
    MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem, MDBIcon, MDBFormInline, Link, MDBBtn ,MDBHamburgerToggler 
} from "mdbreact";
import {  MDBContainer, MDBMask, MDBView } from 'mdbreact';
import { Form, Input, Button, Select, Icon, message, Tag, Badge, Tooltip } from 'antd';
import { URL } from '../api';
import logo from '../Pages/img/as.png'


export default class Header3 extends Component {


  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      nameuser: '',
      admin_type: '',
      Username: '',
      collapse: false,
      isWideEnough: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }
  
    

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }



    componentDidMount() {
      //  const token = JSON.parse(localStorage.getItem('nameuser'));
        if (localStorage.getItem('name') !== "") {
          this.setState({ nameuser: localStorage.getItem('name') })
          this.setState({ admin_type: localStorage.getItem('admin_type') })
        } else {
    
            this.setState({ admin_type: 'public' })

        }

         // this.setState({ nameuser: localStorage.getItem('nameuser') })
         // this.setState({ admin_type: localStorage.getItem('admin_type') })
      }


      logout() {
      //   localStorage.removeItem(('admin_type'))
       //  localStorage.removeItem(('nameuser'))
        localStorage.clear();
    
        setTimeout(() => {
          window.location.href = '/'
        //  window.location.href = 'https://e-student.kpru.ac.th/vaccinekpru'
        }, 1000);
       // this.setState({ admin_type: 'public' }
        message.success("ออกจากระบบแล้ว")
      }



    render() {

    // console.log(this.state.admin_type) ;

        let logout
        logout = (
          <>
            <MDBNavItem>
              <MDBNavLink to="/main"> {this.state.nameuser}</MDBNavLink>
    
            </MDBNavItem>
           
            <MDBNavItem onClick={this.logout} >
              <MDBNavLink to="/">  ออกจากระบบ </MDBNavLink>
            </MDBNavItem>
          </>
        )
    
        let superadmin
        superadmin = (
          <>
            {/* <MDBNavItem >
                 <MDBNavLink to="/Check"><MDBIcon icon="file-alt" /> หน้าหลัก</MDBNavLink>
            </MDBNavItem> */}
            <MDBNavItem >
                 <MDBNavLink to="/Stuall"><MDBIcon far icon="user-circle" /> นักศึกษา</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem >
                 <MDBNavLink to="/Personnelpage"><MDBIcon icon="user-circle" /> บุคลากร</MDBNavLink>
            </MDBNavItem>
            <MDBNavItem >
                 <MDBNavLink to="/Report"><MDBIcon far icon="address-card" /> รายงานข้อมูลสถานะ</MDBNavLink>
            </MDBNavItem>
            {/* <MDBNavItem >
                 <MDBNavLink to="/Learn"><MDBIcon far icon="chart-bar" />ตารางเรียน</MDBNavLink>
            </MDBNavItem> */}
            {/* <MDBNavItem >
                 <MDBNavLink to="/Classroom"><MDBIcon far icon="chart-bar" />จัดการห้องเรียน</MDBNavLink>
            </MDBNavItem> */}
           


             <MDBNavItem onClick={this.logout} >
              <MDBNavLink to="/"><MDBIcon icon="sign-out-alt" />  ออกจากระบบ </MDBNavLink>
            </MDBNavItem>
          </>
        )
        

        // let adminfaculty
        // adminfaculty = (
        //   <>
        //     <MDBNavItem >
        //          <MDBNavLink to="/adminfaculty"><MDBIcon icon="home" /> เมนู1</MDBNavLink>
        //     </MDBNavItem>
        //     <MDBNavItem >
        //          <MDBNavLink to="/adminfacultyproportion"><MDBIcon icon="home" /> เมนู2</MDBNavLink>
        //     </MDBNavItem>
        //      <MDBNavItem onClick={this.logout} >
        //       <MDBNavLink to="/">  ออกจากระบบ </MDBNavLink>
        //     </MDBNavItem>
        //   </>
        // )


        // let expert
        // expert = (
        //   <>
        //     <MDBNavItem >
        //          <MDBNavLink to="/expert"><MDBIcon icon="home" /> หน้าหลัก</MDBNavLink>
        //     </MDBNavItem>
        //     {/* <MDBNavItem >
        //          <MDBNavLink to="/expert"><MDBIcon icon="home" /> สัดส่วนบทความvvvvvv</MDBNavLink>
        //     </MDBNavItem> */}
        //      <MDBNavItem onClick={this.logout} >
        //       <MDBNavLink to="/">  ออกจากระบบ </MDBNavLink>
        //     </MDBNavItem>
        //   </>
        // )
    


        let publics
        publics = (
          <>
            <MDBNavItem  className="pink-text">
              <MDBNavLink to="/" className="pink-text font-weight-bold">   หน้าหลัก </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="pink-text" >
              <MDBNavLink to="/" className="pink-text font-weight-bold">   อาชีพFA </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="pink-text" >
              <MDBNavLink to="/" className="pink-text font-weight-bold">   เซ็คแบบประกัน </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem className="pink-text" >
              <MDBNavLink to="/" className="pink-text font-weight-bold">   คำถามที่พบบ่อย </MDBNavLink>
            </MDBNavItem>
            <MDBNavItem  className="pink-text">
              <MDBNavLink to="/" className="pink-text font-weight-bold">   ติดต่อเรา </MDBNavLink>
            </MDBNavItem>
          
           
            {/* <MDBNavItem  >
              <MDBNavLink to="/main/LoginPages">  เข้าสู่ระบบ </MDBNavLink>
            </MDBNavItem> */}
             <MDBNavItem onClick={this.logout} >
              <MDBNavLink to="/" className="pink-text font-weight-bold">  ออกจากระบบ </MDBNavLink>
            </MDBNavItem>
          
          </>
        )

        const name = this.state.nameuser;

        return (
            <>

<MDBNavbar color="amber lighten-4" style={{ marginTop: '20px' }} light>
          <MDBContainer>
            <MDBNavbarBrand>
              MDBNavbar
            </MDBNavbarBrand>
            <MDBHamburgerToggler color="#d3531a" id="hamburger1" onClick={()=> this.toggleSingleCollapse('collapse1')} />
              <MDBCollapse isOpen={this.state.collapse1} navbar>
                <MDBNavbarNav left>
                  <MDBNavItem active>
                    <MDBNavLink to="#!">Home</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#!">Link</MDBNavLink>
                  </MDBNavItem>
                  <MDBNavItem>
                    <MDBNavLink to="#!">Profile</MDBNavLink>
                  </MDBNavItem>
                </MDBNavbarNav>
              </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>



                

            </>
        )
    }
}

