import React, { Component } from 'react'
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBCarousel,
  MDBCarouselInner,
  MDBCarouselItem } from 'mdbreact';
  import { MDBTable, MDBTableBody, MDBTableHead,   MDBTabPane,
    MDBTabContent,MDBNav } from 'mdbreact';
import { MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavbarToggler, MDBCollapse, MDBNavItem, MDBNavLink, MDBMask, MDBView, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardHeader } from 'mdbreact';
import { Form, Input,  Checkbox, message } from 'antd';
import Login from './Login';
import Axios from 'axios'
import { URL } from '../api';
import logo from './img/logokpru.png'

import Swal from 'sweetalert2'
import Modalregisterauthorities from './register/Modalregisterauthorities';
import Modallogin from './register/Modallogin';
import banner1 from './img/banner1.jpg'
import ReactJsTyping from 'reactjs-typing-effect';

import Iframe from 'react-iframe'
import fas1 from './img/01fa-standard.jpg'
import fas2 from './img/02fa-standard.png'
import fas3 from './img/03fa-standard.png'
import fas4 from './img/04fa-standard.png'
import fas5 from './img/05fa-standard.jpg'
import fas6 from './img/06fa-standard.png'
import fas7 from './img/07standard.png'
import fas8 from './img/08fa-standard.jpg'



export default class Lastandard extends Component {
    

        constructor(props) {
            super(props);
            this.state = {
                value: '',
                Psaaword:'',
                modal: false,
                collapse: false,
                isWideEnough: false,
                activeItem: "1",
            };
            this.handleChange = this.handleChange.bind(this);
            this.handleChange2 = this.handleChange2.bind(this);
         
            this.toggle = this.toggle.bind(this);
            this.toggle1 = this.toggle1.bind(this);
            this.onClick = this.onClick.bind(this);
            this.register = this.register.bind(this);
            this.registerauthor = this.registerauthor.bind(this);
            this.linktolas = this.linktolas.bind(this);
          }

          onClick() {
            this.setState({
              collapse: !this.state.collapse,
            });
          }

          toggle = () => {
            this.setState({
              modal: !this.state.modal
            });
          }

          toggle1 = tab => e => {
            if (this.state.activeItem !== tab) {
              this.setState({
                activeItem: tab
              });
            }
          };

          register() {
            // window.location.href = '/Register'
             window.location.href = '/kprurun2022/Registerstudent'
             // this.setState({Register
             //   collapse: !this.state.collapse,
             // });
           }

           registerauthor() {
            // window.location.href = '/Register'
             window.location.href = '/kprurun2022/Registerauthorities'
             // this.setState({Register
             //   collapse: !this.state.collapse,
             // });
           }
        
          handleChange(event) {    this.setState({value: event.target.value});  }
          handleChange2(event) {    this.setState({Psaaword: event.target.value});  }

       

    update = () => {
        this.setState({
            name: "",
            lname: ""
        })
    }

    linktolas() {
      // window.location.href = '/Register'
       window.location.href = '/Addregister'
       // this.setState({Register
       //   collapse: !this.state.collapse,
       // });
     }

    render() {

      const listOfString=['THENEXT WELCOME','ยินดีต้อนรับทุกท่าน']

      const listOfString2=['ได้มากกว่า']
      
        return (
       
                <>
           <br/>

         
                 <MDBContainer fluid>
                 <img
                 // className="d-block w-40"
                  src={fas1}
                   width="100%"
                  alt="Second slide"
                />
          </MDBContainer>
          <br/>
          <MDBContainer >
          <img
                 // className="d-block w-40"
                  src={fas2}
                   width="100%"
                  alt="Second slide"
                />
 <br/>
 <div className='text-center'>
 <MDBBtn color="danger" onClick={this.linktolas}>สนใจร่วมงานกับเรา</MDBBtn>
 </div>
 <br/>
<MDBRow>
          <MDBCol lg="6" className='text-center'>
          <img
                  className="d-block w-100"
                  src={fas3}
                  alt="First slide"
                />
               
          </MDBCol>
          <MDBCol lg="6" className='text-center'>
          <img
                  className="d-block w-100"
                  src={fas4}
                  alt="First slide"
                />
                 
          </MDBCol>
        {/* Faprime */}
      </MDBRow>
      <br/>
                 <img
                 // className="d-block w-40"
                  src={fas5}
                   width="100%"
                  alt="Second slide"
                />
               <br/>
                <img
                 // className="d-block w-40"
                  src={fas6}
                   width="100%"
                  alt="Second slide"
                />
                 <br/>
                  <img
                 // className="d-block w-40"
                  src={fas7}
                   width="100%"
                  alt="Second slide"
                />
                 <br/>
                <img
                 // className="d-block w-40"
                  src={fas8}
                   width="100%"
                  alt="Second slide"
                />
                 <br/>
          </MDBContainer>
          <br/>
       
                    </>
                   
                   
        )
    }
}
